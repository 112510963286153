import { Client, UpdateClientData } from "../../../utils/interfaces/clients/clients-list-interface";
import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from '../build-base-url';

export const putUpdatedClient = async (updatedClient: UpdateClientData, id:string) => {
    const url = buildBaseUrl()
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};

    const response = await fetch(`${url}clients/${id}`, {
      method: 'PUT',
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedClient),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};
