import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getDeviceSearch = async (criterion: any, query: any, pageKey?: string) => {
  const url = buildBaseUrl();
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}device/search?criterion=${criterion}&query=${query}${pageKey ? `&page=${pageKey}` : ''}`, {
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 400) {
      throw new Error('Invalid request. Both criterion and query are required.');
    } else {
      throw new Error('Failed to retrieve user search results.');
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
