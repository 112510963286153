import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Box,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AppContext } from "../../../utils/themes/theme-context";
import { DevicesDetails } from "../../../utils/interfaces/vehicles/devices-details-interface";
import DeleteDevice from "./device-action-elements/delete-device";
import RegisterClientAction from "./device-action-elements/register-client";
import UnregisterClientAction from "./device-action-elements/unregister-client";
import { PermScanWifi } from "@mui/icons-material";

export default function DeviceAction(props: { device: DevicesDetails; onRegisteredClientName: (clientName: string) => void; onUnRegisteredClientName: (clientName: string) => void; }) {
  const { perms, theme } = useContext(AppContext);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isUpdateAssignDialogOpen, setIsUpdateAssignDialogOpen] = useState(false);
  const [isUnregisterDialogOpen, setIsUnregisterDialogOpen] = useState(false);

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const openUpdateAssignDialog = () => {
    setIsUpdateAssignDialogOpen(true);
  };

  const closeUpdateAssignDialog = () => {
    setIsUpdateAssignDialogOpen(false);
  };

  const openUnregisterDialog = () => {
    setIsUnregisterDialogOpen(true);
  };

  const closeUnregisterDialog = () => {
    setIsUnregisterDialogOpen(false);
  };

  const updateClientName = (newClientName: string) => {
    props.onRegisteredClientName(newClientName);
  };

  const deleteClientName = (DeletedClientName: string) => {
    props.onUnRegisteredClientName(DeletedClientName);
  };


  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Device Action"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Box paddingTop={2}>
            <Stack direction={"column"} spacing={2}>
              <Tooltip
                title="No permission to delete device"
                arrow
                disableInteractive
                disableHoverListener={perms.aw_permissions.device_delete} // Disable tooltip when button is enabled
              >
                <div style={{ display: 'inline-block' }}> {/* Use a div with inline-block to preserve layout */}
                  <Button
                    variant="contained"
                    color={theme.backbuttoncolor}
                    fullWidth
                    onClick={openDeleteDialog}
                    disabled={!perms.aw_permissions.device_delete}
                    sx={{
                      height: 'auto',       // Allow the height to adjust based on content
                      minWidth: '200px',    // Set a minimum width, but allow it to be responsive
                      width: '100%',        // Make the button take up full width of its container
                      maxWidth: '1200px',    // Set a max width for larger screens
                      padding: '12px 24px', // Add padding to ensure button content is spaced well
                    }}
                  >
                    Delete Device
                  </Button>
                </div>
              </Tooltip>
              <Button
                variant="contained"
                color={theme.backbuttoncolor}
                fullWidth
                onClick={openUpdateAssignDialog}
                sx={{
                  height: 'auto',       // Allow the height to adjust based on content
                  minWidth: '200px',    // Set a minimum width, but allow it to be responsive
                  width: '100%',        // Make the button take up full width of its container
                  maxWidth: '1200px',    // Set a max width for larger screens
                  padding: '12px 24px', // Add padding to ensure button content is spaced well
                }}
              >
                Update/Assign Client Registration
              </Button>
              <Button
                variant="contained"
                color={theme.backbuttoncolor}
                fullWidth
                onClick={openUnregisterDialog}
                sx={{
                  height: 'auto',       // Allow the height to adjust based on content
                  minWidth: '200px',    // Set a minimum width, but allow it to be responsive
                  width: '100%',        // Make the button take up full width of its container
                  maxWidth: '1200px',    // Set a max width for larger screens
                  padding: '12px 24px', // Add padding to ensure button content is spaced well
                }}
              >
                Delete Client Registration
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>
      <DeleteDevice open={isDeleteDialogOpen} onClose={closeDeleteDialog} device={props.device} />
      <RegisterClientAction open={isUpdateAssignDialogOpen} onClose={closeUpdateAssignDialog} device={props.device} onUpdateClientName={updateClientName} />
      <UnregisterClientAction open={isUnregisterDialogOpen} onClose={closeUnregisterDialog} device={props.device} onDeleteClientName={deleteClientName} />
    </>
  );
}
