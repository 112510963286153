//get /products from backend

import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { PostProductPreCalsRequest } from "../../interfaces/products/products-precals-interface";

export const postProductsPreCals = async (
  productId: string,
  requestBody: PostProductPreCalsRequest
): Promise<Response> => {
  const url = buildBaseUrl();
  const { accessToken } = (await fetchAuthSession()).tokens ?? {};
  const response = await fetch(`${url}products/${productId}/precal`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken?.toString()}`,
    },
    body: JSON.stringify(requestBody),
  });
  return response;
};
