import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { PostNewDeviceData } from "../../interfaces/devices/devices-interface";

export const postNewDevice = async (
  requestBody: PostNewDeviceData
): Promise<Response> => {
  const url = buildBaseUrl();
  const { accessToken } = (await fetchAuthSession()).tokens ?? {};
  const response = await fetch(`${url}device`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken?.toString()}`,
    },
    body: JSON.stringify(requestBody),
  });
  return response;
};
