import {
  Alert,
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { AppContext } from "../../../utils/themes/theme-context";
import { useContext, useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getDeviceRegistration } from "../../../utils/requests/devices/get-device-registration";
import { DevicesDetails } from "../../../utils/interfaces/vehicles/devices-details-interface";
import { RegistrationDeviceInterface } from "../../../utils/interfaces/devices/registration-device-interface";
import { getDevices } from "../../../utils/requests/devices/get-devices-details";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import { getDeviceFirmware } from "../../../utils/requests/devices/get-device-firmware";
import { DeviceFirmware } from "../../../utils/interfaces/devices/device-firmware-interface";
import { getDeviceFirmwareVersion } from "../../../utils/requests/devices/get-firmware-version";
import { fetchSingleProductData } from "../../../utils/requests/products/get-single-product";

export default function FirmwareDevice(props: { device: DevicesDetails }) {
  var { perms, theme } = useContext(AppContext);
  const [serialNumber, setSerialNumber] = useState<string>();
  const [firmwareUUID, setFirmwareUUID] = useState<string>();
  const [deviceFirmwareData, setDeviceFirmwareData] =
    useState<DeviceFirmware | null>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [firmwareVersion, setFirmwareVerson] = useState("");
  const [productID, setProductID] = useState("");
  const [firmwareUpdateSupport, setFirmwareUpdateSupport] = useState(false);
  const [hasFirmwarePermission, setHasFirmwarePermission] = useState<boolean>(true);

  const deviceidget = props.device.id;

  useEffect(() => {
    // Check permissions when the dialog opens
    if (perms.aw_permissions.firmware_read) {
      setHasFirmwarePermission(true);
    } else {
      setHasFirmwarePermission(false);
      return; // Stop further execution if no permission
    }

    // Fetch the serial number
    const fetchSerialNumber = async () => {
      try {
        const response = await getDevices(deviceidget);
        setSerialNumber(response.serial);
        setFirmwareUUID(response.firmware_id);
        setProductID(response.product_id);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSerialNumber(); // Fetch the serial number when the component mounts or when deviceidget changes
  }, [deviceidget]);

  useEffect(() => {
    const fetchProductFirmwareUpdateSupport = async () => {
      const respdata = await fetchSingleProductData(productID);
      setFirmwareUpdateSupport(respdata.firmware_update_support);
    };

    fetchProductFirmwareUpdateSupport();

    const fetchDeviceFirmwareVersion = async () => {
      const resp = await getDeviceFirmwareVersion(firmwareUUID);

      setFirmwareVerson(resp.firmware_version);
      setErrorMessage(null);
    };
    fetchDeviceFirmwareVersion();

    // Fetch device firmware data whenever the serial number changes
    const fetchDeviceFirmwareData = async () => {
      try {
        if (serialNumber && firmwareVersion) {
          const response = await getDeviceFirmware(
            serialNumber,
            firmwareVersion
          );
          setDeviceFirmwareData(response);
          setErrorMessage(null); // Clear any previous error message
        }
      } catch (err) {
        setDeviceFirmwareData(null);
      }
    };

    fetchDeviceFirmwareData(); // Fetch device registration data when the serial number changes
  }, [productID, firmwareVersion, firmwareUUID, serialNumber]);

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Firmware"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          {hasFirmwarePermission ? (
            <>
              {firmwareUpdateSupport ? (
                <Stack direction={"row"} spacing={3} marginTop={2}>
                  <Stack direction={"column"} spacing={2} alignItems={"center"}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Up to Date
                    </Typography>
                    <Divider flexItem orientation="horizontal" />
                    {/* Conditionally render CircularProgress while waiting for the response */}
                    {!deviceFirmwareData ? (
                      <CircularProgress size={20} color="primary" />
                    ) : deviceFirmwareData.update_available ? ( // Conditionally render the icon based on registration_status
                      <ErrorIcon fontSize="large" style={{ color: "red" }} /> // Red error icon for true
                    ) : (
                      <CheckCircleIcon
                        fontSize="large"
                        style={{ color: "green" }}
                      /> // Green tick icon for false
                    )}
                  </Stack>
                  <Divider flexItem orientation="vertical" />
                  <Stack direction={"column"} spacing={2} alignItems={"center"}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Firmware Updated Version
                    </Typography>
                    <Divider flexItem orientation="horizontal" />
                    <Box
                      sx={{
                        // backgroundColor: theme.tertiary,
                        borderRadius: "10%",
                        padding: "10px",
                      }}
                    >
                      <Typography>{firmwareVersion}</Typography>
                    </Box>
                  </Stack>
                </Stack>
              ) : (
                <Box>
                  <Backdrop
                    open={true}
                    sx={{
                      position: "relative",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Alert variant="filled" severity="warning">
                      <Typography>Unsupported Product Feature.</Typography>
                    </Alert>
                  </Backdrop>
                </Box>
              )}
            </>
          ) : (<>
            <Box>
              <Backdrop
                open={true}
                sx={{
                  position: "relative",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <Alert
                  variant="filled"
                  severity="warning"
                  style={{ width: "100%" }}
                >
                  No permission to view firmware data.
                </Alert>
              </Backdrop>
            </Box>
          </>)}
        </CardContent>
      </Card>
    </>
  );
}
