import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { GenericGetLogsResponse, GenericLogQueryResponse } from '../../interfaces/action-logs/generic';
import { start } from 'repl';

export const getActionLogsForDevice = async (
	device_id: string,
	types?: string[] | undefined,
    start_date?: string | undefined,
    end_date?: string | undefined,
    sort?: string | undefined
) => {
	const url = buildBaseUrl()
    let baseUrl = `logs/devices/${device_id}?`;
	let queryStr = "";

	if (start_date && end_date) 
		queryStr = `start_time=${start_date}&end_time=${end_date}`;

	if (sort) {
		if (queryStr.length > 0) 
			queryStr += `&`
		queryStr += `sort=${sort}`;
	}

	if (types) {
		if (queryStr.length > 0)
			queryStr += `&`
		queryStr += `types=${types.join(',')}`;
	}

	baseUrl += queryStr;

	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(`${url}${baseUrl}`, {
			headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
			},
		});
		const data = await response.json() as GenericLogQueryResponse;

		let logTypes = new Set<string>();

		data.data.documents.forEach((log) => {
			logTypes.add(log.type);
		});

		let output: GenericGetLogsResponse = {
			data: data,
			types: Array.from(logTypes).sort(),
		};

		return output;
	} catch (err) {
		console.log(err);
	}
};