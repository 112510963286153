import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { ProductListData } from '../../interfaces/products/products-list-interface';

export async function fetchSingleProductData(productId: string): Promise<ProductListData> {
  const url = buildBaseUrl()
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}products/${productId}`, {
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
