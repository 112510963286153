import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from '../build-base-url';

export async function getAxleGroup(devicetype: string) {
  const url = buildBaseUrl()
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}iweigh/axlegroups`, {
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Axel Groups');
    }

    const data = await response.json();
    // Set axle groups based on the selected device type
    return data[devicetype];
  } catch (err) {
    console.log(err);
    throw err;
  }
}