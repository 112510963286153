//For SignUp Page No Authentication necessasry
import { PostNewUserRegistrationViaCSV } from "../../interfaces/new-user-registration/new-user-registration-post-interface";
import { buildBaseUrl } from "../build-base-url";
import { fetchAuthSession } from 'aws-amplify/auth';

export const postNewUserRegistrationViaCSV = async (newUserRegistration: PostNewUserRegistrationViaCSV) => {
 try {
    const url = buildBaseUrl();
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}users/new/bulk`, {
      method: "POST",
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
    },
      body: JSON.stringify(newUserRegistration),
    });

    if (!response.ok) {
      throw new Error("Failed to register users.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred during user registration.");
  }
};