import {
  Alert,
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { getVehicleWeights } from "../../../utils/requests/vehicles-routes/get-vehicle-weights";
import MyLineChart from "../../../utils/generic-components/graphs/line-chart";
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import {
  AxleWeightExternal,
  DefaultWeightsReturnType,
  FormattedWeightswithData,
  GraphSingleWeightDatapoint,
  GraphVehicleData,
} from "../../../utils/interfaces/vehicles/vehicles-interfaces";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";

export default function AlertsAndFaults() {
  var { theme } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<string[]>();
  const [data, setData] = useState<GraphSingleWeightDatapoint[] | undefined>();

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Alerts/Faults"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Box>
            <Backdrop
              open={true}
              sx={{
                position: "relative",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <Alert
                variant="filled"
                severity="warning"
                style={{ width: "100%" }}
              >
                No Data Available
              </Alert>
            </Backdrop>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
