import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { TestPassedLog } from '../../interfaces/devices/put-test-passed-logs-interface';

export const putTestPassed= async (device_id: string, logId: string, data: TestPassedLog) => {
	const url = buildBaseUrl()
	const { accessToken } = (await fetchAuthSession()).tokens ?? {};
	const response = await fetch(`${url}device/${device_id}/manufacturelogs/${logId}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken?.toString()}`,
		},
		body: JSON.stringify(data),
	});

	return response;
};
