import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from '../build-base-url';
import { UpdateProductListData } from "../../interfaces/products/products-list-interface";

export const putUpdatedProductDetails = async (updatedProductDetails: UpdateProductListData, id:string) => {
    const url = buildBaseUrl()
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};

    const response = await fetch(`${url}products/${id}`, {
      method: 'PUT',
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedProductDetails),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return false;
  }
};
