import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from '../build-base-url';
import { PutEditsClientsServicePlans } from '../../interfaces/products/put-clients-serviceplan-edits-interface';

export const putUpdatedFirmwareEdits = async (productId: string, firmwareVersion: string, body: PutEditsClientsServicePlans) => {
    const url = buildBaseUrl()
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};

    const response = await fetch(`${url}products/${productId}/firmware/${firmwareVersion}`, {
      method: 'PUT',
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return false;
  }
};
