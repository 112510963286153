//***************************************************************************************/
//  lists in javascript are pointers and not copies so we 
// need to make a copy or clone of the original data to preserve it and avoid any alterations to it
//under no circumstances modify the original objects or.... use lodash
//***************************************************************************************/

import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
} from "@mui/material";
import { ReactElement, useContext, useEffect, useState } from "react";
import { AppContext } from "../../themes/theme-context";
import { RowsHelper } from "./table-row";
import { ColumnCell } from "./table-column";
import {
  ColumnObject,
  Columns,
  Data,
  Row,
} from "../../interfaces/table/table-interfaces";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { formatDateForTimeSelector } from "../../custom-functions/rfc3339-conversions";
import { DateTimePicker } from "@mui/x-date-pickers";
import React from "react";

export default function GeneralTable(props: {
  data: Data;
  columns: Columns;
  toolbar?: boolean;
  toolbarActions?: Array<ReactElement>;
  loading?: boolean;
  noDataMessage?: string;
  additionalPageResultsCallback?: Function;
  rowClickCallback?: Function;
  rowNewTabClickCallback?: Function;
  rows?: number; // 'default' rowsperpage for pagination (drop down pagination default)
  rowsoptions?: Array<number>; // ex. [10, 25], pagination dropdown options
  timeSelector?: boolean; //true = time selector included in toolbar
  timeCallback?: Function;
  multiselect?: boolean; // New prop for enabling multi-select
  multiselectCallback?: (selectedRows: string[]) => void; // Add multiselectCallback prop
  selectedRows?: string[]; // Add selectedRows prop
  customTableStyle?: React.CSSProperties;
}) {
  var { theme } = useContext(AppContext);
  const [page, setPage] = useState<number>(0); // Current page for pagination
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    props.rows ? props.rows : 10 // Default rows per page
  );
  const [data, setData] = useState<Row[]>([]); // State for table data
  const [originalData, setOriginalData] = useState<Row[]>([]); // Original data to reset table order
  const [currentSortedColumn, setCurrentSortedColumn] = useState<string>(""); // Currently sorted column
  const [sortOrder, setSortOrder] = useState<number>(0); // 0: no sort, 1: ascending, 2: descending
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [savedstartDate, setSavedStartDate] = useState<string>("");
  const [savedendDate, setSavedEndDate] = useState<string>("");
  const { multiselect /* ... */ } = props;
  // const [selectedRows, setSelectedRows] = useState<string[]>(
  //   props.selectedRows || []
  // );

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [sortedData, setSortedData] = useState<Row[]>([]); // State for sorted data

  useEffect(() => {
    // Update selectedRows when props.selectedRows changes
    if (props.selectedRows && props.selectedRows.length > 0) {
      setSelectedRows(props.selectedRows);
    }
  }, [props.selectedRows]);

  // Normalize data: Convert object data to an array format 
  const normalizeData = (data: Data): Row[] => {
    // Convert object data to array format
    return Array.isArray(data) ? data : Object.values(data);
  };

  //***************************************************************************************/
  //  lists in javascript are pointers and not copies so we 
  // need to make a copy or clone of the original data to preserve it and avoid any alterations to it
  //under no circumstances modify the original objects or.... use lodash
  //***************************************************************************************/

  // Initialize data when props.data changes
  useEffect(() => {
    if (!props.loading && Object.keys(props.data).length > 0) {
      const dataArray = normalizeData(props.data);
      //... -> shallow copy
      setOriginalData([...dataArray]); // Set original data only once
      setData([...dataArray]); // Initialize data
    }
  }, [props.data, props.loading]);

  // Reset table order when sorting is cleared and data is available
  useEffect(() => {
    if (currentSortedColumn === "" && !props.loading && props.data.length > 0) {
      resetTableOrder();
    }
  }, [currentSortedColumn, props.loading, props.data, originalData]);

  const handleSelectAll = () => {
    if (selectedRows.length === props.data.length) {
      // check if all rows are already selected by comparing the length of selectedRows (array of selected row IDs) with the total number of rows in props.data.
      setSelectedRows([]); //If all rows are already selected, it clears the selection by setting selectedRows to an empty array
      // Call the multiselectCallback with empty array of selected IDs
      if (props.multiselectCallback) {
        props.multiselectCallback([]);
      }
    } else {
      //If not all rows are selected, it creates an array allItemIds containing the IDs of all rows.
      const allItemIds = props.data.map((item: { id: any }) => item.id);
      setSelectedRows(allItemIds); //It sets selectedRows to allItemIds

      // Call the multiselectCallback with the updated array of all selected IDs
      if (props.multiselectCallback) {
        props.multiselectCallback(allItemIds);
      }
    }
  };

  const handleRowSelect = (itemId: string) => {
    const newSelectedRows = [...selectedRows]; //create copy of selectedRows using the spread operator to ensure immutability.

    if (newSelectedRows.includes(itemId)) {
      // If the itemId is already selected, remove it from the array
      const index = newSelectedRows.indexOf(itemId);
      newSelectedRows.splice(index, 1);
    } else {
      // If the itemId is not selected, add it to the array
      newSelectedRows.push(itemId);
    }

    setSelectedRows(newSelectedRows); //update the selectedRows state with the new array of selected row IDs.
    // Update the sortedData based on selectedRows and newSelectedRows
    updateSortedData(newSelectedRows);

    // Call the multiselectCallback with the updated selected rows
    if (props.multiselectCallback) {
      props.multiselectCallback(newSelectedRows);
    }
  };

  // Update sorted data with selected rows' information
  const updateSortedData = (selectedRowIds: string[]) => {
    const updatedSortedData = [...sortedData];
    for (const itemId of selectedRowIds) {
      const row = updatedSortedData.find(item => item.id === itemId);
      if (row) {
        row.isSelected = true; // Mark row as selected
      }
    }
    setSortedData(updatedSortedData);
  };

  // Sort data based on selected column and direction
  const sortData = (column: string | ColumnObject, ascending: boolean) => {
    if (!data || !data.length) return;

    const dataArray = [...data]; // Clone the data original array to avoid mutating it directly.

    // Determine the key to sort by
    let valueToSortBy: string;
    if (typeof column === 'string') {
      const key = Object.keys(props.columns).find(
        (value) => props.columns[value] === column
      );
      valueToSortBy = key || '';
    } else {
      valueToSortBy = column.sort;
    }

    //Converts values to a comparable format. For time values, it converts them to timestamps.
    // Sort the data array based on the column and direction
    const compareValues = (a: any, b: any) => {
      let valueA = a[valueToSortBy];
      let valueB = b[valueToSortBy];

      // Check if the column is of type custom
      if (typeof column !== 'string' && column.type === 'custom') {
        const customValueA = getValueToCompare(valueA);
        const customValueB = getValueToCompare(valueB);

        // Handle null values for "Client not Registered"
        if (customValueA === null && customValueB === null) return 0; // Both null, consider equal
        if (customValueA === null) return 1; // Push null to the end
        if (customValueB === null) return -1; // Push null to the end

        return ascending ? (customValueA > customValueB ? 1 : -1) : (customValueA < customValueB ? 1 : -1);
      }

      // Handle 'time' type differently if it's a ColumnObject
      if (typeof column !== 'string' && column.type === 'time') {
        valueA = new Date(valueA).getTime();
        valueB = new Date(valueB).getTime();
      } else {
        valueA = getValueToCompare(valueA);
        valueB = getValueToCompare(valueB);
      }

      if (valueA < valueB) return ascending ? -1 : 1;
      if (valueA > valueB) return ascending ? 1 : -1;
      return 0;
    };

    // Perform sorting
    dataArray.sort(compareValues);

    // Update state with sorted data
    setData(dataArray);
    setSortedData(dataArray);
  };

  const CustomButton = (props: { children: React.ReactNode }) => {
    return <button>{props.children}</button>;
  };
  CustomButton.displayName = 'CustomButton';

  // Normalize values for comparison
  const getValueToCompare = (value: any) => {
    if (React.isValidElement(value)) { //check value is a valid React element
      const element = value as React.ReactElement;
      
      // Directly check for children
      if (element.props.children) {
        const children = React.Children.map(element.props.children, child => {
          // If the children are a string or number, extract and join them.
          if (typeof child === 'string' || typeof child === 'number') {
            return child;
          }
          return null; // Handle other cases as needed
        }).join(' '); // Join them into a single string

        // Check if the children is 'Client not Registered'
        if (children === 'Client not Registered') {
          return null; // Move this to the end during sorting
        }

        return children; // Return other button texts
      }
    }

    if (!isNaN(value)) {
      return parseFloat(value);
    }
    if (typeof value === "string") {
      return value.toUpperCase();
    }
    return value;
  };

  // Reset table order to original
  const resetTableOrder = () => {
    setData([...originalData]); // Reset to the original data
    setSortedData([...originalData]);
  };

  // Handle column sorting callback
  const columnSortCallback = (sort: number, column: string | ColumnObject) => {
    const columnValue = typeof column === 'string' ? column : column.display;
    const ascending = sort === 1; //  1: ascending, 2: descending
    setCurrentSortedColumn(sort === 0 ? '' : columnValue);
    setSortOrder(sort);
    if (sort === 0) {
      resetTableOrder();
    } else {
      sortData(column, ascending);
    }
  };

  /* if toolbar is passed and is true, a toolbar is displayed. optional toolbar actions pushed to right side */
  function boolbarDisplay(
    boolbar: boolean | undefined,
    toolbarActions: Array<ReactElement> | undefined
  ) {
    if (boolbar && !props.loading) {
      return (
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {/* TODO: search component would go here */}
              {props.timeSelector ? toolbarTimeSelector() : null}
            </Grid>
            <Grid item xs={6}>
              <Box
                display={"flex"}
                justifyContent={"right"}
                alignContent={"right"}
              >
                <Stack direction="row" spacing={2}>
                  {toolbarActions
                    ? toolbarActions.map((action: ReactElement) => {
                      return action;
                    })
                    : null}

                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      );
    }
  }

  const toolbarTimeSelector = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} key={1}>
        <Box marginTop={2} display="flex" alignItems="center">
          <div style={{ marginRight: "8px" }}>
            <DateTimePicker
              label="Start"
              onAccept={(response: any) => setStartDate(response.$d)}
              onError={(response: string | null) => errorStartDate(response)}
              value={savedstartDate ? dayjs(savedstartDate) : null}
            />
          </div>
          <div style={{ marginRight: "8px" }}>
            <DateTimePicker
              label="End"
              onAccept={(response: any) => setEndDate(response.$d)}
              onError={(response: string | null) => errorEndDate(response)}
              value={savedendDate ? dayjs(savedendDate) : null}
            />
          </div>
          <Button
            key={3}
            variant="contained"
            disabled={startDate !== "" && endDate !== "" ? false : true}
            onClick={handleSubmit}
            style={{
              height: "100%",
              backgroundColor:
                startDate !== "" && endDate !== ""
                  ? theme.nonactivebuttoncolor
                  : "gray",
              color: "white",
            }}
          >
            Submit
          </Button>
        </Box>
      </LocalizationProvider>
    );
  };

  const handleSubmit = () => {
    if (props.timeCallback) {
      props.timeCallback(startDate, endDate);
    }
    setSavedStartDate(formatDateForTimeSelector(startDate));
    setSavedEndDate(formatDateForTimeSelector(endDate));
  };

  const errorStartDate = (response: string | null) => {
    if (response !== null) {
      setStartDate("");
    }
  };

  const errorEndDate = (response: string | null) => {
    if (response !== null) {
      setEndDate("");
    }
  };

  /* loading bar if loading exists and is true */
  function loadingDisplay(loading: boolean | undefined) {
    if (loading) {
      return (
        <>
          <LinearProgress />
          <Stack spacing={2}>
            <Skeleton variant="rectangular" height={50} animation="wave" />
            <Skeleton variant="rectangular" height={50} animation="wave" />
            <Skeleton variant="rectangular" height={50} animation="wave" />
          </Stack>
        </>
      );
    }
  }

  /* if loading is false and there is no data to display this function will overlay a backdrop and optional alert message onto the table */
  function noDataAlert(noDataMessage: string | undefined) {
    return (
      <Box>
        <Alert variant="filled" severity="warning">
          {noDataMessage ? noDataMessage : null}
        </Alert>
      </Box>
    );
  }

  /* if loading is false and no data return false, checking if nodataalert will be displayed */
  function checkIfWarning() {
    if (!props.data.length && props.loading === false) {
      return false;
    }
    return true;
  }

  /* changes page when pagination clicked, conditionally calls additionalpageresultscallback */
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  /* handles pagination dropdown click */
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /* displays pagination element */
  function paginationDisplay() {
    return (
      <TablePagination
        component="div"
        count={props.data.length ? props.data.length : 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={props.rowsoptions}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );
  }

  return (
    <>
      {loadingDisplay(props.loading)}
      {boolbarDisplay(props.toolbar, props.toolbarActions)}
      {checkIfWarning() === true ? (
        <>
          {/* {boolbarDisplay(props.toolbar, props.toolbarActions)} */}
          <Paper elevation={3}>
            <Box flexGrow={1} marginTop={3} style={{ overflowX: 'auto' }}> {/* horizontal scrolling added*/}
              <Table sx={{ ...props.customTableStyle, tableLayout: 'auto' }}>
                <TableHead sx={{ backgroundColor: theme.tertiary }}>
                  <TableRow>
                    {!props.loading &&
                      Object.values(props.columns).map(
                        (column: string | ColumnObject, i: number) => {
                          return (
                            <ColumnCell
                              key={
                                typeof column === "string"
                                  ? column
                                  : column.display
                              }
                              column={column}
                              sortOrder={sortOrder}
                              columnSortCallback={columnSortCallback}
                              currentSortedColumn={currentSortedColumn}
                            />
                          );
                        }
                      )}

                    {!props.loading &&
                      multiselect && ( // Render a checkbox column for multi-select
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedRows.length === props.data.length} //checks if the length of selectedRows (an array of selected row IDs) is equal to the length of the entire props.data array (all rows in the table).
                            onChange={handleSelectAll} //event handler is triggered when the checkbox is clicked.
                          />
                        </TableCell>
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!props.loading && data && data.length > 0
                    ? (currentSortedColumn ? sortedData : data) // Use sortedData if currentSortedColumn is not empty, otherwise use data
                      .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      .map((row: any, i: number) => {
                        // I'm pretty sure theres no way to fix this typing..
                        //   because of hte dynamic nature of table it will always be different and cant pass in interface im pretty sure..
                        return (
                          <RowsHelper
                            key={i}
                            rowelement={row}
                            columns={props.columns}
                            i={i}
                            rowClickCallback={props.rowClickCallback}
                            data={data}
                            rowNewTabClickCallback={
                              props.rowNewTabClickCallback
                            }
                            multiselect={props.multiselect}
                            handleRowSelect={handleRowSelect}
                            isSelected={selectedRows.includes(row.id)} // Check if the item ID is in the selectedRows array
                          />
                        );
                      })
                    : null}
                </TableBody>
              </Table>
              {!props.loading ? paginationDisplay() : null}
            </Box>
          </Paper>
        </>
      ) : (
        noDataAlert(props.noDataMessage)
      )}
    </>
  );
}
