
// //DOCUMENTATION: https://airweigh.atlassian.net/l/cp/q1RVuwmF

import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import {
	Backdrop,
	Box,
	Button,
	Card,
	Paper,
	Typography,
} from "@mui/material";
import { LngLatBoundsLike, NavigationControl, Map, GeoJSONSource, Popup, MapMouseEvent, LngLat, MapGeoJSONFeature, Feature, Marker } from "maplibre-gl";
import "./map.css"
import 'maplibre-gl/dist/maplibre-gl.css';
import { getIdentityPoolId, getMapStyles } from "./geo-config";
import SwitchMapStyleControl from "./map-control-style";
import { withIdentityPoolId } from "@aws/amazon-location-utilities-auth-helper";
import { FullscreenControl } from "./map-control-fullscreen";
import GeoSearchControl from "./map-control-search";
import { Address } from "./search-box";
import maplibregl from "maplibre-gl";
import { renderToString } from "react-dom/server";
import { MapPoint } from "../../interfaces/map/generic-interface";
import { isVehicleWeightPoint, VEHICLE_WEIGHT_POINT } from "../../interfaces/map/vehicle-map-interface";
import { FormattedWeight, WeightsCallbackResponse } from "../../interfaces/vehicles/vehicles-interfaces";
import { AppContext } from "../../themes/theme-context";
import { rfcToDateAndTime } from "../../custom-functions/rfc3339-conversions";
// import Spiderfy from '@nazka/map-gl-js-spiderfy';

const HOT_AREA_COUNT = 750; // The number of points at which a cluster is considered a "hot area"
const CLUSTER_MAX_ZOOM = 18; // The maximum zoom level at which clusters are displayed
const CLUSTER_RADIUS = 35; // The radius of the cluster

type PopupEvent = MapMouseEvent | WeightsCallbackResponse;

export const MyMap = (props: {
	title: string;
	longlat: [number, number];
	allowFullscreen: boolean;
	dataPoints: MapPoint[];
	searchBar?: boolean;
	mapSearchCallback: Function;
	count: number; //accounts for naming if someone wanted to change the program to recurse more than one layer deep
	zoom: number;
	fitBounds: LngLatBoundsLike | undefined;
	popupClickCallback: Function;
	currentSnapshot: WeightsCallbackResponse | undefined;
	closeModalParentCallback?: Function;
}) => {
	var { theme } = useContext(AppContext);
	var mapRef = useRef<Map | null>(null);
	var searchMarker = useRef<Marker | null>(null);
	var data: GeoJSON.Feature[] = [];
	const [containersInitialized, setContainersInitialized] = useState<boolean>(false);
	const [fullScreen, setFullScreen] = useState<boolean>(false);
	var isSpiderfied = useRef<boolean>(false);
	const navControl = new NavigationControl({ showZoom: true });
	const mapStyleControl = new SwitchMapStyleControl();
	const fullScreenControl = new FullscreenControl({ startFullScreen: fullScreen, onFullscreenToggle: (value: boolean) => { toggleFullScreen(value); } });
	const searchControl = new GeoSearchControl({ disabled: !fullScreen, onResultClickCallback: (result: Address) => { handleSearchResultClick(result); } });

	var mapContainer: HTMLDivElement = document.createElement("div");

	useEffect(() => {
		if (containersInitialized) {
			initializeMap();
		} else {
			if (document.getElementById("map-container-small") && document.getElementById("map-container-large")) {
				setContainersInitialized(true);
			}
		}
	}, [containersInitialized]);

	//flys to selected longlat and opens up a popup at the snapshot location
	useEffect(() => {
		if (mapRef.current) {
			mapRef.current.flyTo({ center: props.longlat, essential: true, zoom: 20 });
			if (props.currentSnapshot) {
				addPopup(props.currentSnapshot);
			}
		}
	}, [props.longlat]);

	//once map and markers loaded, fitbounds are passed to fit all markers in the visible window
	useEffect(() => {
		if (mapRef.current && props.fitBounds) {
			mapRef.current.fitBounds(props.fitBounds, {
				padding: { top: 45, bottom: 25, left: 15, right: 15 },
			});
		}
	}, [props.fitBounds]);

	async function initializeMap() {
		if (!containersInitialized) return;
		if (mapRef.current) return;

		mapContainer.id = props.title;
		mapContainer.style.width = "100%";
		mapContainer.style.height = "100%";

		if (!containersInitialized) return;

		// Add the map container to the DOM based on the current state of the fullScreen variable
		if (props.allowFullscreen && fullScreen) {
			document.getElementById("map-container-large")?.appendChild(mapContainer);
		} else {
			document.getElementById("map-container-small")?.appendChild(mapContainer);
		}

		const authHelper = await withIdentityPoolId(getIdentityPoolId());

		// Render the map
		var map = new maplibregl.Map({
			container: props.title,
			center: [-123.115898, 49.295868],
			zoom: 3,
			style: getMapStyles().road,
			maxZoom: 19,
			...authHelper.getMapAuthenticationOptions(),
		});

		mapRef.current = map;

		// Wait for the style to load
		mapRef.current.on('styledata', async () => {
			if (!mapRef.current) return

			// Modify the style to add the glyphs property
			const currentStyle = map.getStyle();

			if (currentStyle.glyphs !== "http://fonts.openmaptiles.org/{fontstack}/{range}.pbf") {
				currentStyle.glyphs = "http://fonts.openmaptiles.org/{fontstack}/{range}.pbf"; // Glyphs URL


				// Override all text layers to use only "Open Sans Bold"
				currentStyle.layers.forEach(layer => {
					if (layer.type === 'symbol' && layer.layout && layer.layout['text-font']) {
						// Replace the font stack with just Open Sans Bold
						layer.layout['text-font'] = ['Open Sans Regular'];
					}
				});

				// Re-apply the modified style to the map
				mapRef.current.setStyle(currentStyle);
			}

			addControls();
			await loadAssets();
			loadSources();
			loadLayers();
		});

		mapRef.current.on('load', async () => {
			if (!mapRef.current) return

			addControls();
			await loadAssets();
			await loadSources();
			await loadLayers();
		})

		mapRef.current.on("click", "clusters", handleClusterClick);
		mapRef.current.on("click", "unclustered-point", addPopup);
		mapRef.current.on("click", "unclustered-point-spiderfy", addPopup);
		mapRef.current.on("mouseenter", "clusters", mouseEnterLeave);
		mapRef.current.on("mouseenter", "unclustered-point", mouseEnterLeave);
		mapRef.current.on("mouseenter", "unclustered-point-spiderfy", mouseEnterLeave);
		mapRef.current.on("mouseleave", "clusters", mouseEnterLeave);
		mapRef.current.on("mouseleave", "unclustered-point", mouseEnterLeave);
		mapRef.current.on("mouseleave", "unclustered-point-spiderfy", mouseEnterLeave);
		mapRef.current.on('zoomstart', deletePointsSpiderfy)
		mapRef.current.on('dragstart', deletePointsSpiderfy)
	}

	const addControls = () => {
		if (!mapRef.current) return
		if (!mapRef.current.hasControl(navControl)) {
			mapRef.current.addControl(navControl, 'bottom-right');
		}

		if (!mapRef.current.hasControl(mapStyleControl)) {
			mapRef.current.addControl(mapStyleControl, 'top-right');
		}

		if (!mapRef.current.hasControl(fullScreenControl) && props.allowFullscreen) {
			mapRef.current.addControl(fullScreenControl, 'top-right');
		}

		if (!mapRef.current.hasControl(searchControl) && props.searchBar) {
			mapRef.current.addControl(searchControl, 'top-left');
		}
	}

	const loadAssets = async () => {
		if (!mapRef.current) return
		if (mapRef.current.hasImage("marker")) return

		// Load map marker svg
		const marker = await mapRef.current.loadImage("/images/awcloud_map_marker.png");
		if (marker) {
			await mapRef.current.addImage("marker", marker.data);
		}
	}

	const loadSources = async () => {
		if (!mapRef.current) return
		if (mapRef.current.getSource("points")) return


		reloadData();

		// Add the data to the map
		if (!mapRef.current.getSource("points")) {
			await mapRef.current.addSource("points", {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: data,
				},
				cluster: true,
				clusterMaxZoom: CLUSTER_MAX_ZOOM,
				clusterRadius: CLUSTER_RADIUS,
			});
		}

		if (!mapRef.current.getSource("spiderfy")) {
			await mapRef.current.addSource('spiderfy', {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [],
				},
				cluster: false,
			})
		}
	}

	const loadLayers = async () => {
		if (!mapRef.current) return

		if (!mapRef.current.getLayer("clusters")) {
			// Add cluster layer
			await mapRef.current.addLayer({
				id: "clusters",
				type: "circle",
				source: "points",
				filter: ["has", "point_count"],
				paint: {
					'circle-color': [
						'rgb',
						// Red value (calculated based on ratio)
						[
							'interpolate',
							['linear'],
							['min', ['max', ['/', ['-', ['get', 'point_count'], 1], ['-', HOT_AREA_COUNT, 1]], 0], 1], // Ratio calculation
							0, 0,     // Red starts at 0 when ratio is 0
							0.5, 255, // At ratio 0.5, red reaches 255
							1, 255    // At ratio 1, red stays 255
						],
						// Green value (calculated based on ratio)
						[
							'interpolate',
							['linear'],
							['min', ['max', ['/', ['-', ['get', 'point_count'], 1], ['-', HOT_AREA_COUNT, 1]], 0], 1], // Ratio calculation
							0, 255,   // Green starts at 255 when ratio is 0
							0.5, 255, // At ratio 0.5, green is still 255
							1, 0      // At ratio 1, green drops to 0
						],
						// Blue value (fixed to 0)
						0
					],
					"circle-blur": 0.8,
					"circle-radius": [
						"step",
						["get", "point_count"],
						25,   // Radius for low point counts
						100, 35,  // Larger radius at 100 points
						750, 45   // Maximum radius at 750 points
					],
				},
			});
		}

		// Add cluster count layer
		if (!mapRef.current.getLayer("cluster-count") && mapRef.current.getStyle().glyphs !== "") {
			await mapRef.current.addLayer({
				id: "cluster-count",
				type: "symbol",
				source: "points",
				filter: ["has", "point_count"],
				layout: {
					"text-field": "{point_count_abbreviated}",
					"text-font": ["Open Sans Regular"],
					"text-size": 12,
				},
				paint: {
					"text-color": "black",
				}
			});
		}

		if (!mapRef.current.getLayer("unclustered-point")) {
			// Add unclustered point layer
			await mapRef.current.addLayer({
				id: "unclustered-point",
				type: "symbol",
				source: "points",
				filter: ["!", ["has", "point_count"]],
				layout: {
					"icon-image": "marker",
					"icon-size": 0.5,
					"icon-allow-overlap": true,
				},
			});
		}

		if (!mapRef.current.getLayer('unclustered-point-spiderfy')) {
			mapRef.current.addLayer({
				id: 'unclustered-point-spiderfy',
				type: 'symbol',
				source: 'spiderfy',
				filter: ['!has', 'point_count'],
				layout: {
					'icon-image': 'marker',
					'icon-size': 0.5,
					'icon-allow-overlap': true,
					'icon-ignore-placement': true,
					'icon-offset': ['get', 'iconOffset'],
				},
			})
		}
	}

	const mouseEnterLeave = (e: MapMouseEvent) => {
		if (!mapRef.current) return
		if (e.type === 'mouseenter') {
			mapRef.current.getCanvas().style.cursor = 'pointer'
		} else {
			mapRef.current.getCanvas().style.cursor = ''
		}
	}

	const handleClusterClick = (e: MapMouseEvent) => {
		if (!mapRef.current) return
		const features = mapRef.current.queryRenderedFeatures(e.point, {
			layers: ['clusters']
		});

		console.log("Features: ", features);
		console.log("Zoom: ", mapRef.current.getZoom());

		if (mapRef.current.getZoom() < CLUSTER_MAX_ZOOM) {
			if (features[0].geometry.type === "Point") {
				const point = features[0] as GeoJSON.Feature<GeoJSON.Point>;

				mapRef.current.flyTo({
					center: point.geometry.coordinates as [number, number],
					zoom: mapRef.current.getZoom() + 2,
				});
			}
		} else {
			// Spider the cluster
			const clusterId = features[0].properties.cluster_id;
			const source = mapRef.current.getSource('points') as GeoJSONSource;
			const lngLat = e.lngLat;

			spiderfyCluster(source, clusterId, lngLat);
		}





		// const clusterId = features[0].properties.cluster_id;

		// var geoSource = mapRef.current.getSource("points") as GeoJSONSource;


	}

	const calculateSpiderfiedPositionsCircle = (count: number) => {
		const leavesSeparation = 80; // Separation between points
		const leavesOffset = [0, 0]; // Base offset
		const points = []; // Array to store positions
		const theta = (2 * Math.PI) / count; // Angle between each point
		let angle = theta;

		for (let i = 0; i < count; i += 1) {
			angle = theta * i; // Current angle for the point
			const x = leavesSeparation * Math.cos(angle) + leavesOffset[0]; // X-coordinate of the point
			const y = leavesSeparation * Math.sin(angle) + leavesOffset[1]; // Y-coordinate of the point
			points.push([x, y]); // Add the point to the array
		}
		return points;
	}

	const calculateSpiderfiedPositions = (count: number) => {
		const legLengthStart = 30; // Initial leg length of the spiral
		const legLengthFactor = 7; // Factor for increasing the leg length
		const leavesSeparation = 55; // Separation between points
		const leavesOffset = [0, 0]; // Base offset
		const points = []; // Array to store positions
		let legLength = legLengthStart; // Current leg length
		let angle = 0; // Initial angle

		for (let i = 0; i < count; i += 1) {
			angle += leavesSeparation / legLength + i * 0.0005; // Increment the angle
			const x = legLength * Math.cos(angle) + leavesOffset[0]; // X-coordinate of the point
			const y = legLength * Math.sin(angle) + leavesOffset[1]; // Y-coordinate of the point
			points.push([x, y]); // Add the point to the array

			legLength += (Math.PI * 2 * legLengthFactor) / angle; // Increase the leg length
		}
		return points;
	}

	const spiderfyCluster = (
		source: GeoJSONSource,
		clusterId: number,
		lngLat: LngLat
	) => {
		if (!mapRef.current) return
		source.getClusterLeaves(clusterId, Infinity, 0).then((features) => {
			if (!features || !mapRef.current) return
			if (features.length > 0) {
				const spiderfiedPositions =
					features.length > 10
						? calculateSpiderfiedPositions(features.length)
						: calculateSpiderfiedPositionsCircle(features.length)

				const geoJson: GeoJSON.GeoJSON = {
					type: 'FeatureCollection',
					features: features.map((feature, index) => ({
						...feature,
						properties: {
							...feature.properties,
							iconOffset: spiderfiedPositions[index],
						},
						geometry: {
							...feature.geometry,
							type: 'Point',
							coordinates: [lngLat.lng, lngLat.lat],
						},
					})),
				}

				const spiderfySource = mapRef.current.getSource('spiderfy') as GeoJSONSource
				spiderfySource.setData(geoJson)

				mapRef.current.setPaintProperty('clusters', 'circle-opacity', 0.6)
				mapRef.current.setPaintProperty('unclustered-point', 'icon-opacity', 0.5)
			}
		});
	}

	const deletePointsSpiderfy = () => {
		if (!mapRef.current) return
		const spiderfySource = mapRef.current.getSource('spiderfy') as GeoJSONSource
		if (!spiderfySource) return
		spiderfySource.setData({
			type: 'FeatureCollection',
			features: [],
		})
		mapRef.current.setPaintProperty('clusters', 'circle-opacity', 1)
		mapRef.current.setPaintProperty('unclustered-point', 'icon-opacity', 1)
	}

	const reloadData = () => {
		// Set data to map over the data points
		data = props.dataPoints.map((point, index) => {
			var properties = {}

			if (isVehicleWeightPoint(point)) {
				var weightData = point as FormattedWeight;
				properties = {
					...weightData,
				}
			}

			return {
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [point.longitude, point.latitude],
				},
				properties: properties,
			};
		});
	}

	const handleSearchResultClick = (result: Address) => {
		if (!mapRef.current) return
		mapRef.current.flyTo({
			center: result.coordinates,
			zoom: 18
		});

		var popup = new Popup()
			.setLngLat(result.coordinates)
			.setText(result.title)
			.setHTML(renderToString(
				<Box
					display={"flex"}
					flexDirection={"column"}
				>
					<Typography
						variant="h2"
						align="center"
						sx={{ fontWeight: 700 }}
						flexGrow={1}
					>
						{result.title}
					</Typography>
					<Typography>{result.address}</Typography>
				</Box>
			))

		if (!searchMarker.current) {
			searchMarker.current = new Marker()
				.setLngLat(result.coordinates)
				.addTo(mapRef.current);
		}

		searchMarker.current.setLngLat(result.coordinates);
		searchMarker.current.setPopup(popup);
		searchMarker.current.togglePopup();

		// Add popup



		props.mapSearchCallback(result);
	}

	const toggleFullScreen = (value: boolean) => {
		if (!mapRef.current) return
		var smallContainer = document.getElementById("map-container-small");
		var largeContainer = document.getElementById("map-container-large");

		if (!smallContainer || (props.allowFullscreen && !largeContainer)) return;
		if (!largeContainer) return

		setFullScreen(value);

		if (value) {
			searchControl.enableControl()
		} else {
			searchControl.disableControl()
		}

		// Check if the map container is already a child of the appropriate parent element
		if (!value && smallContainer.contains(mapContainer)) return
		else if (value && largeContainer.contains(mapContainer)) return

		// Remove the map container from its current parent element
		if (smallContainer.contains(mapContainer)) {
			smallContainer.removeChild(mapContainer);
		} else if (largeContainer.contains(mapContainer)) {
			largeContainer?.removeChild(mapContainer);
		}

		// Move the map container to the appropriate parent element
		if (value) {
			largeContainer.appendChild(mapContainer);
		} else {
			smallContainer.appendChild(mapContainer);
		}

		mapRef.current.redraw();
		mapRef.current.resize();
	}

	// When a geojson point is clicked, add a popup to the map
	const addPopup = (e: PopupEvent) => {
		// Get the feature that was clicked
		if (!mapRef.current) return
		var popupNode = document.createElement("div")
		const kgToPounds = 2.20462;
		var pointType = "";
		var weightData: FormattedWeight
		var cords: [number, number]

		if (e instanceof MapMouseEvent) {
			const features = mapRef.current.queryRenderedFeatures(e.point, {
				layers: ['unclustered-point', 'unclustered-point-spiderfy']
			});

			console.log("Features: ", features);
			console.log("Zoom: ", mapRef.current.getZoom())


			if (features.length === 0 || features[0].geometry.type !== "Point") return

			const point = features[0] as GeoJSON.Feature<GeoJSON.Point>;
			pointType = point.properties?.objectType || "";
			point.geometry.coordinates as [number, number]
			cords = point.geometry.coordinates as [number, number];
			weightData = point.properties as FormattedWeight;
		} else {
			weightData = {
				...e,
				objectType: VEHICLE_WEIGHT_POINT
			}
			pointType = VEHICLE_WEIGHT_POINT;
			cords = [weightData.longitude, weightData.latitude];
		}

		switch (pointType) {
			case VEHICLE_WEIGHT_POINT:
				// Convert the weight from grams to pounds
				// const weightInPounds = (datapoint.weight * gramsToPounds).toFixed(2);
				const weightInPounds = weightData.weight * kgToPounds;
				const roundedWeightInPounds = Math.round(weightInPounds / 10) * 10;
				//first divides the value pounds by 10, rounds it to the nearest integer, and then multiplies it by 10 to get the rounded value to the nearest 10 without the decimal part.

				const mainHTMLContent = renderToString(
					<Box
						style={{
							marginTop: -15,
							marginLeft: -10,
							marginRight: -10,
						}}
					>
						<Box
							style={{
								color: theme.secondary,
								background: theme.tertiary,
								padding: 10,
								fontWeight: 700,
								textAlign: "center",
							}}
						>
							<Typography>{rfcToDateAndTime(weightData.date_time)}</Typography>
						</Box>
						<Box
							style={{
								color: theme.primary,
								// padding: 10,
								marginTop: 5,
								// paddingBottom: 0,
								// margin: -10,
								fontWeight: 700,
								textAlign: "center",
							}}
						>
							{/* <Typography>Weight: {datapoint.weight}</Typography> */}
							{/* Display the converted weight with the unit "lbs" */}
							<Typography>Weight: {roundedWeightInPounds} lbs</Typography>

						</Box>
					</Box>
				)
				const popupActions = renderToString(
					<div
						id="more-details"
						style={{
							color: theme.primary,
							padding: 1,
							marginTop: 5,
							marginBottom: -10,
							fontWeight: 700,
							textAlign: "center",
							background: theme.secondary,
							borderRadius: 5,
						}}
					>
						More Details
					</div>
				)

				const assignBtn = document.createElement("div")
				assignBtn.innerHTML = popupActions;
				const domContent = document.createElement("div")
				domContent.innerHTML = mainHTMLContent;
				domContent.appendChild(assignBtn);
				assignBtn.addEventListener("click", (e) => {
					props.popupClickCallback(weightData);
				});
				popupNode = domContent;
				break;
			default:
				popupNode.innerHTML = "No Data Available";
				break;
		}

		// Create a popup
		new Popup()
			.setLngLat(cords)
			.setDOMContent(popupNode)
			.setOffset([0, -20])
			.addTo(mapRef.current);
	};

	return (
		<Box>
			<Paper>
				<Box width={"100%"} height={400} id="map-container-small" />
			</Paper>

			{props.allowFullscreen && <Backdrop open={fullScreen} sx={{ zIndex: 500 }}>
				<Box width={239}></Box>
				<Box
					display={"flex"}
					height={"80%"}
					width={"80%"}
					// margin={"auto"}
					// paddingLeft={300}
				>
					<Card sx={{ flexGrow: 1 }}>
						<Box width={"100%"} height={"100%"} id="map-container-large"></Box>
					</Card>
				</Box>
			</Backdrop>}
		</Box>
	);
};