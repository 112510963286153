import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TestLogData } from "../../../../utils/interfaces/devices/test-logs-interface";

export default function TestLogsDeviceSettings(props: { testlogdata: TestLogData | null }) {

    // Extract device settings from the props
    const { device_settings } = props.testlogdata || {};
    
    // Derive the software version
    const softwareVersion = device_settings 
        ? `v${device_settings.firmware_major_version}.${String(device_settings.firmware_minor_version).padStart(2, '0')}`
        : 'N/A';

    return (
        <>
            <Card elevation={8} sx={{ borderRadius: 3, flex: 1 }}>
                <CardHeader title="Device Settings"
                    action={
                        <IconButton aria-label="info">
                            <InfoOutlinedIcon />
                        </IconButton>
                    } />
                <Divider />
                <CardContent>
                    <Stack direction={"row"} spacing={2} margin={2}>
                        <Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
                            <Typography variant="body1"><b>Serial Number</b> </Typography>
                            <Typography>{device_settings?.serial_number}</Typography>
                        </Stack>
                        <Divider flexItem orientation="vertical" />
                        <Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
                            <Typography variant="body1"><b>Software Version</b></Typography>
                            <Typography> {softwareVersion}</Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
}
