import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AppContext } from "../utils/themes/theme-context";
import { getUsers } from "../utils/requests/users/get-users";
import { getSingleClient } from "../utils/requests/clients/get-single-client";
import CloseIcon from "@mui/icons-material/Close";

function Header({ signOut }: any) {
  const { perms, theme } = React.useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isProfileDialogOpen, setProfileDialogOpen] = useState(false);
  const [clientName, setClientName] = useState("");

  // Check if user details are available and user type is "aw" before rendering
  if (!perms || (perms.user_type === "aw" && !perms.user_name)) {
    return null;
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewProfile = async () => {
    // Use handleMenuClose to close the menu
    handleMenuClose();
    // Call getUsers to fetch data
    try {
      const response = await getSingleClient(perms.client_id);
      setClientName(response.data.client);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
    setProfileDialogOpen(true);
  };

  const handleDialogClose = async () => {
    setProfileDialogOpen(false);
  };

  return (
    <AppBar style={{ backgroundColor: theme.primary }}>
      <Toolbar>
        <Box width={"200px"} height={"50px"}>
          <img
            src="/images/white-Air-Weigh-_sans_subtext.svg"
            alt="Logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        {/* Larger Profile Icon */}
        <IconButton color="inherit" onClick={handleMenuOpen}>
          <AccountCircleIcon sx={{ fontSize: 32 }} />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleViewProfile}>
            <Typography variant="inherit">View Profile</Typography>
          </MenuItem>
          <MenuItem onClick={signOut}>
            <Typography variant="inherit">Sign Out</Typography>
          </MenuItem>
        </Menu>

        {/* Profile Dialog */}
        <Dialog open={isProfileDialogOpen} onClose={handleDialogClose}>
          <DialogTitle variant="h4">Profile Details</DialogTitle>
          <Divider />
          <DialogContent>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleDialogClose}
              style={{
                position: "absolute",
                top: "5px",
                right: "15px",
                color: "black",
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <DialogContentText variant="h6">
              <img
                src="/images/User_profile-default_picture.jpg"
                alt="Profile"
                style={{ width: "100%", height: "85%", borderRadius: "8px" }}
              />
              <br />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        Attribute
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>{perms.user_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>User Name</TableCell>
                      <TableCell>{perms.username}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>{perms.user_email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Client Name</TableCell>
                      <TableCell>{clientName}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
