import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from '../build-base-url';
import { FirmwareUpdatePOST } from "../../interfaces/products/products-list-interface";

export const updateFirmware = async (id: string, data: FirmwareUpdatePOST): Promise<Response> => {
    const url = buildBaseUrl()
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}products/${id}/firmware`, {
      method: 'POST',
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  };