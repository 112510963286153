import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Slide,
  Stack,
  Tooltip,
} from "@mui/material";
import Details from "./user-details-elements/details";
import Actions from "./user-details-elements/actions";
import Permissions from "./user-details-elements/permissions";
import Status from "./user-details-elements/status";
import { useNavigate, useParams } from "react-router-dom";
import { getUserFromUsername } from "../../utils/requests/user-details/get-user-from-username";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/themes/theme-context";
import { getUser } from "../../utils/interfaces/users/user-details-interfaces";
import SuspendDelete from "./user-details-elements/suspend-delete";
import { getActionLogsForUsers } from "../../utils/requests/users/get-action-logs-users";
import UserLogsTab from "./user-tab-logs/user-tab-table-logs";

export default function UserDetails() {
  var { perms, theme } = useContext(AppContext);
  let { id } = useParams();
  const [user, setUser] = useState<getUser>();
  const [transition, setTransition] = useState<boolean>(true);
  let userNavigate = useNavigate();
  const [updatedat, setUpdatedAt] = useState<string>("");
  const [invalidUser, setInvalidUser] = useState<boolean>(false);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [fetchUpdatedUser, setFetchUpdatedUser] = useState<boolean>(false);
  const [suspending, setSuspending] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string>("Profile");
  const [displayProfile, setDisplayProfile] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayLogs, setDisplayLogs] = useState<boolean>(false);
  const [isLoadingLogs, setIsLoadingLogs] = useState<boolean>(false);
  const [canViewLogs, setCanViewLogs] = useState<boolean>(false); // New state to manage button state

  useEffect(() => {
    fetchUser()
      .then((response: getUser) => {
        setUser(response);
        setUpdatedAt(response.data.updated_at);
      })
      .catch(() => {
        setInvalidUser(true);
      });

    // Set permissions based on user type
    if (perms?.user_type === "aw" && perms.aw_permissions?.user_edit) {
      setCanViewLogs(true);
    } else if (perms?.user_type === "corp" && perms.corporate_permissions?.users_edit) {
      setCanViewLogs(true);
    } else {
      setCanViewLogs(false); // Disable logs if no permission
    }
  }, [fetchUpdatedUser, perms]);

  const fetchUser = async () => {
    try {
      const response = await getUserFromUsername(id);
      return response;
    } catch (err) {
      console.log(err);
      setInvalidUser(true);
    }
  };

  const fetchLogs = async (types: string[] | undefined, start_date: string | undefined, end_date: string | undefined) => {
    try {
      const response = await getActionLogsForUsers(user?.data.username || "", types, start_date, end_date);
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  const loadingDisplay = () => {
    return (
      <>
        <LinearProgress />
        <Stack direction={"row"} spacing={3} marginTop={5}>
          <Stack direction={"column"} spacing={3}>
            <Skeleton variant="rectangular" width={275} height={275} />
            <Skeleton variant="rectangular" width={275} height={150} />
            <Skeleton variant="rectangular" width={275} height={100} />
          </Stack>
          <Divider flexItem orientation="vertical" />
          <Skeleton variant="rectangular" width={700} height={500} />
        </Stack>
      </>
    );
  };

  const handleBack = () => {
    setTransition(false);
    setTimeout(() => userNavigate(`/users`), 400);
  };

  const handleButtonClick = async (buttonName: string) => {
    setActiveButton(buttonName);
    setDisplayProfile(buttonName === "Profile");
    setDisplayLogs(buttonName === "Logs");
  };

  return (
    <>
      <Slide direction="left" in={transition} timeout={400}>
        <Box flexGrow={1} margin={"5%"} marginTop={10}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h2>User Profile</h2>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  color={theme.backbuttoncolor}
                  onClick={handleBack}
                  variant={"contained"}
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Grid>
            <Box
              display="flex"
              justifyContent="flex-start"
              marginTop={1}
              marginBottom={1}
            >
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Profile"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Profile")}
              >
                Profile
              </Button>
              <Tooltip
                title={canViewLogs ? "" : "No permission to edit user"}
                arrow
              >
                <span>
                  <Button
                    variant="contained"
                    style={{
                      marginRight: "10px",
                      background:
                        activeButton === "Logs"
                          ? theme.selectedbuttoncolor
                          : theme.nonactivebuttoncolor,
                    }}
                    onClick={() => handleButtonClick("Logs")}
                    disabled={!canViewLogs}  // Disable the button based on the permission
                  >
                    Logs{" "}
                    {isLoadingLogs ? (
                      <CircularProgress
                        size={20}
                        color="inherit"
                        style={{ marginLeft: "5px" }}
                      />
                    ) : null}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Grid>
          <Divider />
          {invalidUser ? (
            <Box padding={2}>
              <Alert severity="error">This User Does Not Exist</Alert>
            </Box>
          ) : user && displayProfile ? (
            <>
              <Stack direction={"row"} spacing={3} marginTop={5}>
                <Stack direction={"column"} spacing={3} width="30%">
                  <Details user={user} updatedat={updatedat} />
                  <Actions
                    user={user}
                    setDisplayModal={setDisplayModal}
                    setSuspending={setSuspending}
                    setDeleting={setDeleting}
                  />
                  <Status user={user} />
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack width="70%">
                  <Permissions user={user} updatedat={updatedat} />
                </Stack>
              </Stack>
            </>
          ) : !isLoadingLogs && displayLogs ? (
            <Stack direction={"column"} spacing={3} marginTop={5}>
              <UserLogsTab fetchLogs={fetchLogs} />
            </Stack>
          ) : (
            loadingDisplay()
          )}
        </Box >
      </Slide >

      {
        user ? (
          <SuspendDelete
            user={user}
            suspending={suspending}
            deleting={deleting}
            setDisplayModal={setDisplayModal}
            setFetchUpdatedUser={setFetchUpdatedUser}
            fetchUpdatedUser={fetchUpdatedUser}
            displayModal={displayModal}
            setSuspending={setSuspending}
            setDeleting={setDeleting}
          />
        ) : null
      }
    </>
  );
}
