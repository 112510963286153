import React from 'react';
import {
    MenuItem,
    Checkbox,
    ListItemText,
    Select,
    ListItemIcon,
    FormControl,
    InputLabel,
    Box,
    SelectChangeEvent,
} from '@mui/material';

export interface Option {
    label: string;
    value: string;
}

interface MultiSelectInputProps {
    label: string;
    options: Option[];
    selectedValues: string[];
    allSelected: boolean;
    onChange: (selected: string[], allSelected: boolean) => void;
    error?: string;
    sx?: object;
}

const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
    label,
    options,
    selectedValues,
    allSelected,
    onChange,
    error,
    sx,
}) => {
    const optionKeys = options.map(option => option.value);

    const handleSelect = (event: SelectChangeEvent<string[]>) => {
        const values = event.target.value as string[];
        var newValue: string[] = []

        if (values.includes('all')) {
            if (values.length === 1) {
                newValue = optionKeys
            } else if (values.length === 2) {
                newValue = optionKeys.filter(option => !values.includes(option))
            }
        } else {
            newValue = values
        }
        
        onChange(newValue, newValue.length === options.length);
    };

    return (
        <FormControl fullWidth sx={{ marginTop: 2, ...sx }}>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                value={allSelected ? ['all'] : selectedValues.map((option) => option)}
                onChange={handleSelect}
                renderValue={(selected) =>
                    allSelected
                        ? 'All'
                        : `${(selected as string[]).length} Selected`
                }
                error={error !== undefined}
            >
                <MenuItem value="all">
                    <ListItemIcon>
                        <Checkbox
                            checked={allSelected}
                            indeterminate={selectedValues.length > 0 && !allSelected}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                </MenuItem>

                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        <Checkbox checked={selectedValues.indexOf(option.value) > -1} />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Select>

            {error && <Box color="red">{error}</Box>}
        </FormControl>
    );
};

export default MultiSelectInput;