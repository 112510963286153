import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    Skeleton,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DevicesDetails } from "../../../utils/interfaces/vehicles/devices-details-interface";
import TestLogsDetails from "./test-log-elements/test-logs-details";
import TestLogsActions from "./test-log-elements/test-logs-action";
import TestLogsDeviceSettings from "./test-log-elements/test-logs-device-settings";
import TestLogsTestResults from "./test-log-elements/test-logs-test-results";
import TestLogsErrroMessages from "./test-log-elements/test-logs-error-messages";
import { getTestLogs } from "../../../utils/requests/devices/get-test-logs";
import { ResponseData, TestLogData } from "../../../utils/interfaces/devices/test-logs-interface";
import CloseIcon from "@mui/icons-material/Close";

let columns: Columns = {
    DateTime: {
        type: "time",
        display: "Date Time",
        data: "test_end_epoch_utc_time",
        sort: "DateTime",
    },
    PassedFailed: {
        type: "text",
        display: "Passed/Failed",
        sort: "PassedFailed",
    },
    MACaddress: {
        type: "text",
        display: "MAC Address",
        data: "mac_address",
        sort: "MACaddress",
    },
    Details: {
        type: "link",
        display: "Details",
        sort: "Details",
    }
};

export default function TestLogsTab(props: {
    device: DevicesDetails;
}) {
    var { theme } = useContext(AppContext);
    const [currentPage, setCurrentPage] = useState<'logs' | 'details'>('logs');
    const [testPassed, setTestPassed] = useState<boolean>(false); // State for test passed
    const [data, setData] = useState<TestLogData[]>([]);
    const [selectedData, setSelectedData] = useState<TestLogData | null>(null);
    const [loading, setLoading] = useState(true);
    const [openViewTagDialog, setOpenViewTagDialog] = useState(false); // State for View Tag dialog
    const [tagDetails, setTagDetails] = useState({
        tagNumber: "",
        lotNumber: "",
        pcbManufacturerNumber: ""
    });
    const [activeStep, setActiveStep] = useState(0);
    const [savedTagDetails, setSavedTagDetails] = useState({
        tagNumber: "",
        lotNumber: "",
        pcbManufacturerNumber: ""
    });
    const [openAddTagDialog, setOpenAddTagDialog] = useState(false);

    const device_id = props.device.id;

    useEffect(() => {
        // Fetch the test logs
        const fetchTestLogs = async () => {
            setLoading(true);
            try {
                const response = await getTestLogs(device_id) as ResponseData;

                if (response && Array.isArray(response.data)) {
                    setData(response.data);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchTestLogs(); // Fetch when the component mounts or when device_id changes
    }, [device_id]);

    const handleRowClick = (id: string) => {
        // Find the clicked row by id
        const clickedRow = data.find(d => d.id === id);
        if (clickedRow) {
            setSelectedData(clickedRow); // Set the selected data to the full row
            setCurrentPage('details');  // Navigate to details page
        }
    };

    const goBackToLogs = () => {
        setCurrentPage('logs');
    };

    const handleTestPassed = (passed: boolean) => {
        // Update the selected data first
        if (selectedData) {
            const updatedSelectedData = {
                ...selectedData,
                test_result: {
                    ...selectedData.test_result,
                    test_passed: passed,
                },
            };

            setSelectedData(updatedSelectedData);  // Update the selected data state

            // Update the data array
            const updatedData = data.map((item) =>
                item.id === selectedData.id ? updatedSelectedData : item
            );

            setData(updatedData);  // Update the data state with the modified entry
        }
    };


    const loadingDisplay = () => {
        return (
            <>
                <LinearProgress />
                <Stack spacing={2}>
                    <Skeleton variant="rectangular" height={50} animation="wave" />
                    <Skeleton variant="rectangular" height={50} animation="wave" />
                    <Skeleton variant="rectangular" height={50} animation="wave" />
                </Stack>
            </>
        );
        return null;
    };

    const handleOpenAddTag = () => {
        setOpenAddTagDialog(true);
    };

    const handleCloseAddTag = () => {
        setOpenAddTagDialog(false);
        setTagDetails({
            tagNumber: "",
            lotNumber: "",
            pcbManufacturerNumber: ""
        });
        setActiveStep(0);
    };

    const handleNextStep = () => {
        setActiveStep(1);
    };

    const handleBackStep = () => {
        setActiveStep(0);
    };

    const handleSave = () => {
        setSavedTagDetails(tagDetails);
        handleCloseAddTag(); // Close the dialog
    };

    const handleOpenViewTag = () => {
        setOpenViewTagDialog(true);
    };

    const handleCloseViewTag = () => {
        setOpenViewTagDialog(false);
    };

    const steps = ["Enter Details", "Review Details"];

    return (
        <>

            {loading ? (
                loadingDisplay()
            ) : (

                <>
                    {/* Logs or Details Card */}
                    {
                        currentPage === 'logs' ? (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h5">Test Logs</Typography>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={handleOpenAddTag}
                                        >
                                            Add Tag No./Lot No./PCB production No.
                                        </Button>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={handleOpenViewTag}
                                            disabled={!savedTagDetails.tagNumber && !savedTagDetails.lotNumber && !savedTagDetails.pcbManufacturerNumber}
                                        >
                                            View Tag No./Lot No./PCB production No.
                                        </Button>
                                    </Stack>
                                </div>
                                <GeneralTable
                                    data={data.map((datalog) => ({
                                        id: datalog.id,
                                        DateTime: datalog.test_result?.test_end_epoch_utc_time !== undefined
                                            ? new Date(datalog.test_result.test_end_epoch_utc_time * 1000).toISOString()
                                            : "N/A",
                                        PassedFailed: (
                                            <>
                                                {datalog.test_result?.test_passed ? (
                                                    <CheckCircleIcon fontSize="large" style={{ color: "green" }} />
                                                ) : (
                                                    <CancelIcon fontSize="large" style={{ color: "red" }} />
                                                )}
                                            </>
                                        ),
                                        MACaddress: datalog.device_settings?.mac_address,
                                        Details: "Details",
                                    }))}
                                    customTableStyle={{ width: '100%' }}
                                    columns={columns}
                                    loading={false}
                                    rowClickCallback={(rowData: any) => {
                                        handleRowClick(rowData.id);
                                    }}
                                    noDataMessage="No Test Log Data" />

                                {/* Dialog for Adding Tag No./Lot No./PCB production No. */}
                                <Dialog open={openAddTagDialog} onClose={handleCloseAddTag} PaperProps={{
                                    style: {
                                        minWidth: '40%',
                                        minHeight: '40%',
                                    },
                                }}>
                                    <DialogTitle variant="h5" align="center"><b>Add Tag No./Lot No./PCB production No.</b>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={handleCloseAddTag}
                                            aria-label="close"
                                            sx={{ position: 'absolute', right: 20, top: 5 }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </DialogTitle>
                                    <Divider style={{ marginBottom: "10px" }} />
                                    <Stepper activeStep={activeStep} alternativeLabel>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <DialogContent>
                                        {activeStep === 0 && (
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                <TextField
                                                    label="Tag Number"
                                                    variant="outlined"
                                                    value={tagDetails.tagNumber}
                                                    onChange={(e) => setTagDetails({ ...tagDetails, tagNumber: e.target.value })}
                                                />
                                                <TextField
                                                    label="Lot Number"
                                                    variant="outlined"
                                                    value={tagDetails.lotNumber}
                                                    onChange={(e) => setTagDetails({ ...tagDetails, lotNumber: e.target.value })}
                                                />
                                                <TextField
                                                    label="PCB Manufacturer's Production Number"
                                                    variant="outlined"
                                                    value={tagDetails.pcbManufacturerNumber}
                                                    onChange={(e) => setTagDetails({ ...tagDetails, pcbManufacturerNumber: e.target.value })}
                                                />
                                            </Box>
                                        )}
                                        {activeStep === 1 && (
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }}>
                                                                    Details
                                                                </TableCell>
                                                                <TableCell sx={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }}>
                                                                    Values
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Tag Number</TableCell>
                                                                <TableCell>{tagDetails.tagNumber}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Lot Number</TableCell>
                                                                <TableCell>{tagDetails.lotNumber}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>PCB Manufacturer's Production Number</TableCell>
                                                                <TableCell>{tagDetails.pcbManufacturerNumber}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        )}
                                    </DialogContent>
                                    <DialogActions>
                                        {activeStep === 0 && (
                                            <Box display="flex" justifyContent="space-between" width="100%" paddingBottom={3} paddingLeft={2} paddingRight={2}>
                                                <Button onClick={handleCloseAddTag} variant="contained" color={theme.backbuttoncolor}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={handleNextStep} variant="contained" color={theme.nextbuttoncolor}>
                                                    Next
                                                </Button>
                                            </Box>
                                        )}
                                        {activeStep === 1 && (
                                            <Box display="flex" justifyContent="space-between" width="100%" paddingBottom={3} paddingLeft={2} paddingRight={2}>
                                                <Button onClick={handleBackStep} variant="contained" color={theme.backbuttoncolor}>
                                                    Back
                                                </Button>
                                                <Button onClick={handleSave} variant="contained" color={theme.nextbuttoncolor}>
                                                    Save
                                                </Button>
                                            </Box>
                                        )}
                                    </DialogActions>
                                </Dialog >

                                {/* Dialog for Viewing Tag Details */}
                                <Dialog open={openViewTagDialog} onClose={handleCloseViewTag} PaperProps={{
                                    style: { minWidth: '40%', minHeight: '40%' },
                                }}>
                                    <DialogTitle variant="h5" align="center"><b>View Tag Details</b>
                                        <IconButton edge="end" color="inherit" onClick={handleCloseViewTag} aria-label="close" sx={{ position: 'absolute', right: 20, top: 5 }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        {savedTagDetails ? (
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }}>Details</TableCell>
                                                            <TableCell sx={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }}>Values</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>Tag Number</TableCell>
                                                            <TableCell>{savedTagDetails.tagNumber}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Lot Number</TableCell>
                                                            <TableCell>{savedTagDetails.lotNumber}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>PCB Manufacturer's Production Number</TableCell>
                                                            <TableCell>{savedTagDetails.pcbManufacturerNumber}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <Typography>No details available</Typography>
                                        )}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseViewTag} variant="contained" color={theme.backbuttoncolor}>
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                            </>
                        ) : (
                            <Box>

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h5">Log Details</Typography>
                                    <Button onClick={goBackToLogs} variant="contained" color={theme.backbuttoncolor} style={{ marginBottom: '1rem' }}>
                                        Back to Logs
                                    </Button>
                                </div>

                                {/* Layout for Cards */}
                                <Stack
                                    direction="row"
                                    spacing={4}
                                    marginTop={5}
                                    marginBottom={10}
                                >
                                    <Stack direction="column" spacing={3} width="30%">
                                        <Stack direction="column" spacing={2}>
                                            {/* Details Card */}
                                            <TestLogsDetails testlogdata={selectedData} />
                                            {/* Actions Card*/}
                                            <TestLogsActions testlogdata={selectedData} onTestPassed={handleTestPassed} />
                                        </Stack>
                                    </Stack>
                                    <Divider flexItem orientation="vertical" />
                                    <Stack direction="column" spacing={2} width="70%">
                                        <Stack direction="row" spacing={2} width="100%">
                                            {/* Device Settings Card */}
                                            <TestLogsDeviceSettings testlogdata={selectedData} />
                                            {/* Test Results Card */}
                                            <TestLogsTestResults testlogdata={selectedData} testPassed={testPassed} />
                                        </Stack>
                                        {/* Error Message Table */}
                                        <TestLogsErrroMessages testlogdata={selectedData} />
                                    </Stack>
                                </Stack>
                            </Box>
                        )
                    }
                </>
            )
            }
        </>
    );
}
