import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getDashboardStats = async () => {
	const url = buildBaseUrl()
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(`${url}dashboard/stats`, {
			headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
			},
		});
		const data = await response.json();
		return data; 
	} catch (err) {
		console.log(err);
	}
};