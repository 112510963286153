import { Alert, Box, Divider, Slide } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getVehicles } from "../../utils/requests/vehicles-routes/get-vehicles";
import { Columns } from "../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../utils/generic-components/table/table";
import { useNavigate } from "react-router-dom";
import { SingleVehicle, VehicleData } from "../../utils/interfaces/vehicles/vehicles-interfaces";
import SearchBar from "./vehicle-search/vehicle-search";
import { AppContext } from "../../utils/themes/theme-context";

export default function VehiclesList() {
	var { perms, theme } = useContext(AppContext);
	const [data, setData] = useState<VehicleData[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [transitionout, setTransitionOut] = useState<boolean>(true);
	let userNavigate = useNavigate();
	const [originalVehicles, setOriginalVehicles] = useState<VehicleData[]>([]);
	const [errorMessage, setErrorMessage] = useState<string>("");

	let columns: Columns = {
		Name: {
			type: "link",
			display: "Vehicle Name",
			data: "",
			sort: "Name",
		},
		Vin: "Vehicle Vin",
		Client: {
			type: "button",
			display: "Client",
			sort: "Client",
			onClick: (rowData: { ClientId: any }) => {
				if (perms?.aw_permissions.clients_read) {
					const clientId = rowData.ClientId;
					userNavigate(`/clients/${clientId}`);
				}
			},
		},
		Firmware: "Firmware",
		LastActivity: {
			type: "time",
			display: "Last Activity",
			data: "",
			sort: "LastActivity",
		},
		RegistrationDate: {
			type: "time",
			display: "Registration Date",
			data: "",
			sort: "RegistrationDate",
		},
		// Tickets future use
		// Tickets: "Tickets",
		// OpenTickets: "Open Tickets",
	};

	useEffect(() => {
		getVehicles()
			.then((response) => {
				setData(response.Vehicles);
				setOriginalVehicles(response.Vehicles);
				setLoading(false);
			})
			.catch((err: string[]) => {
				console.log(err);
			});
	}, []);

	function handleRowClick(selectedvehicle: SingleVehicle) {
		setTransitionOut(false);
		setTimeout(
			() =>
				userNavigate(
					`/vehicles/${selectedvehicle.VehicleId}`
				),
			400
		);
	}

	function handleNewTabRowClick(selectedvehicle: SingleVehicle) {
		// setTransitionOut(false);
		window.open(`/vehicles/${selectedvehicle.VehicleId}`, '_blank');
	}

	// Search
	const handleUpdateData = (updatedData: VehicleData[]) => {
		setData(updatedData);
	};

	const handleError = (error: any) => {
		setErrorMessage(error);
	};

	return (
		<>
			<Slide direction="right" in={transitionout} timeout={400}>
				<Box
					flexGrow={1}
					marginLeft={"5%"}
					marginRight={"5%"}
					marginTop={10}
					marginBottom={10}
				>
					<h2>Vehicle Dashboard</h2>
					<Divider />
					<SearchBar onUpdateData={handleUpdateData} onError={handleError} originalVehicles={originalVehicles} setLoading={setLoading} />

					{errorMessage && (
						<Box paddingTop={2} color="red">
							<Alert severity="error">Invalid Device</Alert>
						</Box>
					)}

					<GeneralTable
						data={data.map((vehicle) => ({
							Name: vehicle.vehicle_name,
							Vin: vehicle.vehicle_vin ? vehicle.vehicle_vin : "Vin Not Specified",
							Client: perms?.aw_permissions.clients_read ? vehicle.client :
								<Alert severity="error">
									No permission to view client data
								</Alert>,
							Firmware: vehicle.firmware_version,
							LastActivity: vehicle.vehicle_updated_at,
							RegistrationDate: vehicle.vehicle_registration_date,
							VehicleId: vehicle.vehicle_id, //not for display just for vehicle details call
							ClientId: vehicle.client_id, //not for display just for vehicle details call
						}))}
						columns={columns}
						loading={loading}
						noDataMessage={"Unable To Fetch Vehicles"}
						rowClickCallback={handleRowClick}
						rows={10}
						rowsoptions={[10, 25, 50, 100]}
						rowNewTabClickCallback={handleNewTabRowClick}
					/>
				</Box>
			</Slide>
		</>
	);
}
