import DetailsTable from "../../../utils/generic-components/Details Table/details-table";
import { DetailTableData } from "../../../utils/interfaces/details-table/details-table-interfaces";
import { rfcToDate, rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import { Device } from "../../../utils/interfaces/devices/devices-interface";
import { useContext, useEffect, useState } from "react";
import { fetchLatestClientData } from "../../../utils/requests/clients/get-single-client";
import { fetchSingleProductData } from "../../../utils/requests/products/get-single-product";
import { getOneVehicle } from "../../../utils/requests/vehicles-routes/get-one-vehicle";
import { getDeviceFirmwareVersion } from "../../../utils/requests/devices/get-firmware-version";
import { Link } from "react-router-dom";
import { AppContext } from "../../../utils/themes/theme-context";
import { Alert } from "@mui/material";

export interface weirdfix {
	data: Device;
}

export default function Details(props: { device: Device, registeredclientName: string, unregisteredclientName: string }) {
	var { perms, theme } = useContext(AppContext);
	const [clientName, setClientName] = useState("");
	const [productName, setProductName] = useState("");
	const [vehicleName, setVehicleName] = useState("");
	const [firmwareVersion, setFirmwareVerson] = useState("");

	useEffect(() => {
		// Call fetchLatestClientData when the component mounts
		fetchLatestClientData(props.device.client_id)
			.then((name) => {
				setClientName(name.client);
			})
			.catch((error) => {
				console.error("Error fetching client name: ", error);
			});
		// when we unregister, the client id is null so we jsut set the name to empty string
		if (!props.device.client_id) {
			setClientName("");
		}

		fetchSingleProductData(props.device.product_id)
			.then((product) => {
				setProductName(product.name);
			})
			.catch((error) => {
				console.error("Error fetching product name: ", error);
			});

		getOneVehicle(props.device.vehicle_id)
			.then((vehicle) => {
				setVehicleName(vehicle.data.vehicle_name);
			})
			.catch((error) => {

				console.error("Error fetching vehicle name: ", error);
			});

		getDeviceFirmwareVersion(props.device.firmware_id)
			.then((firmware_version) => {
				setFirmwareVerson(firmware_version.firmware_version);
			})
			.catch((error) => {
				console.error("Error fetching Firmware Version: ", error);
			});

	}, [props.device.client_id, props.device.product_id, props.device.vehicle_id, props.device.firmware_id, props.registeredclientName, props.unregisteredclientName]); // Re-run the effect whenever the id's change

	const clientPageUrl = `/clients/${props.device.client_id}`;
	const productPageUrl = `/products/${props.device.product_id}`;
	const vehiclePageUrl = `/vehicles/${props.device.vehicle_id}`;

	let DetailTableData: DetailTableData = {
		data: [
			// {
			// 	label: "Device Id",
			// 	content: props.device.id,
			// 	editable: false,
			// 	error: "",
			// },
			{
				label: "Serial Number",
				content: props.device.serial,
				editable: false,
				error: "",
			},
			{
				label: "Client Name",
				// content: clientName,
				content: perms.aw_permissions.clients_read ? (
					// Wrap the client name in a Link component to create a clickable link
					<Link to={clientPageUrl}>
						{clientName}
					</Link>
				) : (
					<Alert severity="error" style={{ margin: '0 auto', textAlign: 'center' }}>
						No permission to view client data.
					</Alert>
				),
				editable: false,
				error: "",
			},
			{
				label: "Product Name",
				// content: productName,
				content: (
					// Wrap the product name in a Link component to create a clickable link
					<Link to={productPageUrl}>
						{productName}
					</Link>
				),
				editable: false,
				error: "",
			},
			{
				label: "Vehicle Name",
				// content: vehicleName,
				content: perms.aw_permissions.vehicle_read ? (
					// Wrap the vehicle name in a Link component to create a clickable link
					<Link to={vehiclePageUrl}>
						{vehicleName}
					</Link>
				) : (
					<Alert severity="error" style={{ margin: '0 auto', textAlign: 'center' }}>
						No permission to view vehicle data.
					</Alert>
				),
				editable: false,
				error: "",
			},
			{
				label: "Firmware Version",
				content: perms.aw_permissions.firmware_read ? (firmwareVersion) : (
				<Alert severity="error" style={{ margin: '0 auto', textAlign: 'center' }}>
					No permission to view firmware data.
				</Alert>),
				editable: false,
				error: "",
			},
			{
				label: "Installation Date",
				content: rfcToDateAndTime(props.device.installation_date),
				editable: false,
				error: "",
			},
			{
				label: "Registration Date",
				content: rfcToDateAndTime(props.device.registration_date),
				editable: false,
				error: "",
			},
			{
				label: "Updated At",
				content: rfcToDateAndTime(props.device.updated_at),
				editable: false,
				error: "",
			},
		],
	};

	//extend functionality in the future
	const putCallback = (
		data: DetailTableData,
		setAlert: Function,
		setEditingMode: Function,
		setLoadingButton: Function
	) => {
		console.log("putcallback");
	};

	return (
		<>
			<DetailsTable
				data={DetailTableData}
				putCallback={putCallback}
				editing={false}
				close={false}
			/>
		</>
	);
}
