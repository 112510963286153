import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const checkUserAvailability = async (
	email: string,
	username: string
) => {
	const url = buildBaseUrl()
	if (!email && !username) {
		console.log("no inputs")
		return
	}
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(
			`${url}users/available?email=${email}&username=${username}`,
			{
				headers: {
					Authorization: `Bearer ${accessToken?.toString()}`,
				},
			}
		);
		return response.json();
	} catch (err) {
		console.log(err);
	}
};
