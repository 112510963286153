import { Box, Button, Card, CardContent, CardHeader, Checkbox, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import React, { useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import MultiSelectInput, { Option } from "./multiselect";

interface FilterBoxProps {
    options: Option[];
    fetchLogs: (types: string[], startTime: string, endTime: string) => void;
}

const FilterBox: React.FC<FilterBoxProps> = ({ options, fetchLogs }) => {
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [allSelected, setAllSelected] = useState(false);

    const onTypeChange = (selected: string[], allSelected: boolean) => {
        setSelectedTypes(selected);
        setAllSelected(allSelected);
    };

    const resetFilters = () => {
        setSelectedTypes([]);
        setAllSelected(false);
        setStartDate(null);
        setEndDate(null);

        fetchLogs([], '', '');
    }

    return (
        <Card variant="outlined" sx={{ marginTop: 2 }}>
            <CardHeader title="Filters" />
            <CardContent>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                    flexWrap="wrap"
                    sx={{
                        "& > *": {
                            height: "56px", // Uniform height for all inputs and buttons
                        },
                    }}
                >
                    <MultiSelectInput
                        label="Log Type"
                        options={options}
                        selectedValues={selectedTypes}
                        allSelected={allSelected}
                        onChange={onTypeChange}
                        sx={{ flex: 1, minWidth: 200 }}
                    />
                    <DateTimePicker
                        label="Start DateTime"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        slotProps={{ textField: { fullWidth: true } }}
                        sx={{ flex: 1, minWidth: 200 }}
                    />
                    <DateTimePicker
                        label="End DateTime"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        slotProps={{ textField: { fullWidth: true } }}
                        sx={{ flex: 1, minWidth: 200 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => fetchLogs(selectedTypes, startDate?.format() ?? "", endDate?.format() ?? "")}
                        sx={{
                            minWidth: "auto",
                            flex: 1,
                            maxWidth: 250,
                            textTransform: "none",
                        }}
                    >
                        Fetch Logs
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => resetFilters()}
                        sx={{
                            minWidth: "auto",
                            flex: 1,
                            maxWidth: 250,
                            textTransform: "none",
                        }}
                    >
                        Reset Filters
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
}

export default FilterBox;