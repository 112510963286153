import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useContext, useEffect, useState } from "react";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import { getProductStats } from "../../../utils/requests/products/get-product-stats";
import { ProductPageStatsInterface } from "../../../utils/interfaces/products/product-page-stats-interface";
import { AppContext } from "../../../utils/themes/theme-context";

export default function ProductPageStats(props: { product_id: string }) {
  var { perms, theme } = useContext(AppContext);
  const [statsData, setStatsData] = useState<ProductPageStatsInterface | null>(null); // State to hold fetched data
  const [error, setError] = useState<string | null>(null);

  // Function to fetch client stats when component mounts
  const fetchStatsData = async () => {
    try {
      const productId = props.product_id;
      const data = await getProductStats(productId);
      setStatsData(data); // Set the fetched data into state
      setError(null); // Clear any previous errors
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response; // Destructure 'status' and 'data' from the 'response' property of the 'error' object.
        if (status === 400) {
          setError(`Bad Request: ${data.errors ? data.errors[0] : 'Invalid request'}`);
        } else if (status === 401) {
          setError(`Unauthorized: ${data.errors ? data.errors[0] : 'Unauthorized access'}`);
        } else if (status === 403) {
          setError(`Forbidden: ${data.errors ? data.errors[0] : 'Access denied'}`);
        } else if (status === 500) {
          setError(`Server Error: ${data.errors ? data.errors[0] : 'Internal server error'}`);
        } else {
          setError('An error occurred');
        }
      } else {
        setStatsData(null); // Reset statsData to null on error
        setError('Failed to fetch product stats'); // Generic error message
      }
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    fetchStatsData();
  }, [props.product_id]);

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader title="Stats" action={
          <IconButton aria-label="info">
            <InfoOutlinedIcon />
          </IconButton>
        }
        />
        <CardContent>
          {error ? (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          ) : (
            <><Divider />
              <Stack direction={"row"} spacing={12} marginTop={2} marginLeft={8} marginRight={8}>
                <Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
                  <Typography>Total Devices</Typography>
                  <Divider flexItem orientation="horizontal" />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        borderRadius: "20%",
                        padding: "10px",
                      }}
                    >
                      <Typography>{statsData?.total_devices.total}</Typography>
                    </Box>
                    <Tooltip title={statsData?.total_devices.date_time !== undefined
                      ? rfcToDateAndTime(statsData?.total_devices.date_time)
                      : ''} placement="right">
                      <CalendarTodayIcon color="inherit" fontSize="small" sx={{ marginLeft: 1 }} />
                    </Tooltip>
                  </Box>
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
                  <Typography>Total Firmware Versions</Typography>
                  <Divider flexItem orientation="horizontal" />
                  {perms.aw_permissions.firmware_read ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          borderRadius: "20%",
                          padding: "10px",
                        }}
                      >
                        <Typography>{statsData?.total_firmware_versions.total}</Typography>
                      </Box>
                      <Tooltip title={statsData?.total_firmware_versions.date_time !== undefined
                        ? rfcToDateAndTime(statsData?.total_firmware_versions.date_time)
                        : ''} placement="right">
                        <CalendarTodayIcon color="inherit" fontSize="small" sx={{ marginLeft: 1 }} />
                      </Tooltip>
                    </Box>) : (<>
                      <Box>
                        <Backdrop
                          open={true}
                          sx={{
                            position: "relative",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Alert
                            variant="filled"
                            severity="warning"
                            style={{ width: "100%" }}
                          >
                            No permission to view firmware data.
                          </Alert>
                        </Backdrop>
                      </Box>
                    </>)}
                </Stack>
              </Stack></>
          )}
        </CardContent>
      </Card>
    </>
  );
}