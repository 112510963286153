//get /products from backend

import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getSingleServicePlans = async (servicePlanId: string) => {
	const url = buildBaseUrl()
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(`${url}service_plans/${servicePlanId}`, {
			headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
			},
		});
		const data  = await response.json(); //Extracting data array from response
		return data;
	} catch (err) {
		console.log(err);
	}
};