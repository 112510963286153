//get /products from backend

import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getRecentDevices = async (productId: any, clientId: any) => {
	const url = buildBaseUrl()
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		// Construct the base URL
        let apiUrl = `${url}device`;

		 // Add product_id to the URL if it's not an empty string
        if (productId !== '') {
            apiUrl += `?product_id=${productId}`;
        }

        // Add client_id to the URL if it's not an empty string
        if (clientId !== '') {
            apiUrl += productId !== '' ? `&client_id=${clientId}` : `?client_id=${clientId}`;
        }

        const response = await fetch(apiUrl, {
			headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
			},
		});
		const data  = await response.json(); //Extracting data array from response
        const firstFiveDevices = data.data.slice(0, 5);
        return firstFiveDevices;
	} catch (err) {
		console.log(err);
	}
};