import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { ReportsData } from "../../interfaces/reports/reports-interface";

export const postReports = async (data: ReportsData) => {
	const url = buildBaseUrl()
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(`${url}reports/new`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
			},
			body: JSON.stringify(data),
		});

		if (!response.ok) {
			// Handle HTTP errors (e.g., 4xx or 5xx responses)
			const errorText = await response.text();
			throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
		}

		return response;
	} catch (error: any) {
		// Handle fetch or JSON parsing errors
		console.error('Error posting report data:', error);
		throw error; // Re-throw or handle as needed
	}
};
