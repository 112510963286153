import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const putSuspendUser = async (username: string) => {
	const url = buildBaseUrl()
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(
			`${url}users/${username}/suspend`,
			{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${accessToken?.toString()}`,
				},
			}
		);
		if (response.status === 200) {
			return "success";
		} else {
			let data = await response.json();
			return data.errors;
		}
	} catch (err) {
		console.log(err);
	}
};

export const putEnableUser = async (username: string) => {
	const url = buildBaseUrl()
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(
			`${url}users/${username}/enable`,
			{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${accessToken?.toString()}`,
				},
			}
		);
		if (response.status === 200) { //success
			return "success";
		} else { //returning error message to display
			let data = await response.json();
			return data;
		}
	} catch (err) {
		console.log(err);
	}
};
