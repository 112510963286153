import { fetchAuthSession } from 'aws-amplify/auth';
import { newuser } from "../../interfaces/new-user/new-user-interface";
import { buildBaseUrl } from "../build-base-url";
import { Preconfiguration } from "../../interfaces/clients/client-preconfig-interface";
import { MyInterface } from "../products/preconfigPOSTinterface";

export const putClientPreconfiguration = async (clientId: string | undefined, data: Preconfiguration, preconfigurationId: string | undefined) => {
	const url = buildBaseUrl()
	const { accessToken } = (await fetchAuthSession()).tokens ?? {};
	const response = await fetch(`${url}clients/${clientId}/preconfiguration/${preconfigurationId}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken?.toString()}`,
		},
		body: JSON.stringify(data),
	});

	return response;
};
