import { useContext, useEffect, useState } from "react";
import { MyMap } from "../../../utils/generic-components/map/map";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
} from "@mui/material";
import GeneralTable from "../../../utils/generic-components/table/table";
import {
  ColumnObject,
  Columns,
} from "../../../utils/interfaces/table/table-interfaces";
import { AppContext } from "../../../utils/themes/theme-context";
import {
  AxleWeightExternal,
  DefaultWeightsReturnType,
  FormattedWeight,
  FormattedWeightswithData,
  WeightsCallbackResponse,
} from "../../../utils/interfaces/vehicles/vehicles-interfaces";
import { getBounds, getCenterOfBounds } from "geolib";
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import DetailsTable from "../../../utils/generic-components/Details Table/details-table";
import { LngLatBoundsLike } from "maplibre-gl";
import {
  DetailTableData,
  DetailTableDataPoint,
} from "../../../utils/interfaces/details-table/details-table-interfaces";
import { MapResponse } from "../../../utils/interfaces/map/generic-interface";
import { VEHICLE_WEIGHT_POINT } from "../../../utils/interfaces/map/vehicle-map-interface";

let columns: Columns = {
  number: "#",
  date_time: {
    type: "time",
    display: "Date & Time",
    data: "",
    sort: "date_time",
  },
  weight2: {
    type: "text",
    display: "Weight",
    sort: "weight2",
  },
  snapshot: {
    type: "link",
    display: "Snapshot",
    data: "",
    sort: "snapshot",
  },
  more_details: {
    type: "link",
    display: "More Details",
    data: "",
    sort: "more_details",
  },
};

export default function WeightsAndLocations(props: { fetchWeights: Function }) {
  var { theme } = useContext(AppContext);
  const [formattedWeights, setFormattedWeights] = useState<FormattedWeightswithData | null>();
  const [unformattedData, setUnformattedData] = useState<DefaultWeightsReturnType | undefined>();
  const [longlat, setLongLat] = useState<[number, number]>([-123, 44]); //default, air weighs coordinates haha
  const [zoom, setZoom] = useState<number>(6); //default, functionality could be added down the line
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [modalData, setModalData] = useState<
    DetailTableData | null | undefined
  >(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fitBounds, setFitBounds] = useState<LngLatBoundsLike | undefined>();
  const [currentSnapshot, setCurrentSnapshot] =
    useState<WeightsCallbackResponse>();

  //all weights for vehicle, pass in time ranges.. also calculates center of map to pass to inital map render
  useEffect(() => {
    setLoading(true);
    props
      .fetchWeights()
      .then(
        (response: [FormattedWeightswithData, DefaultWeightsReturnType]) => {
          if (!response) {
            setFormattedWeights(null);
            setLoading(false);
          } else {
            findCenter(response[0]);
            setFormattedWeights(response[0]);
            setUnformattedData(response[1]);
            setLoading(false);
          }
        }
      )
      .catch((err: string[]) => {
        console.log(err);
      });
  }, [startDate, endDate]);

  //zoom after formatted weights have been set
  useEffect(() => {
    if (formattedWeights && !loading) {
      const longlatInputs = formattedWeights.data.map(
        (weightObject: FormattedWeight) => ({
          latitude: weightObject.latitude,
          longitude: weightObject.longitude,
        })
      );
      
      if (longlatInputs.length === 0) {
        return;
      }

      const bounds = getBounds(longlatInputs);
      setFitBounds([
        [bounds.minLng, bounds.minLat],
        [bounds.maxLng, bounds.maxLat],
      ]);
    }
  }, [formattedWeights]);

  //centers camera before "zoom animation"
  const findCenter = (response: { data: FormattedWeight[] }) => {
    if (response.data.length === 0)
      return;

    // set center to newest weight
    const center = getCenterOfBounds([
      {
        latitude: response.data[0].latitude,
        longitude: response.data[0].longitude,
      },
    ]);
    setLongLat([center.longitude, center.latitude]);
  };

  //callback for searching a location
  const mapSearchCallback = (response: MapResponse) => {
  };

  //clicking the table, snapshot or more details
  const rowClickCallback = (
    response: WeightsCallbackResponse,
    column: ColumnObject
  ) => {
    if (column.display === "Snapshot") {
      setCurrentSnapshot(response);
      setLongLat([response.longitude, response.latitude]);
    } else if (column.display === "More Details") {
      const temp = formatResponseForDetailsTable(response);
      setModalData(temp);
      setModalOpen(true);
    }
  };

  //more details popup click (mapclick)
  const popupClickCallback = (datapoint: WeightsCallbackResponse) => {
    setModalData(formatResponseForDetailsTable(datapoint));
    setModalOpen(true);
  };

  //adjusting dates on toolbar of table
  const timeCallback = (start_time: string, end_time: string) => {
    setStartDate(start_time);
    setEndDate(end_time);
  };

  // Function to convert kilograms to pounds and append the unit
  function kgToPounds(kilograms: number) {
    const pounds = kilograms * 2.20462; //1 kilogram is approximately equal to 2.20462 pounds.
    const roundedPounds = Math.round(pounds / 10) * 10;
    return `${roundedPounds} lbs`;
  }

  //details modal formatting
  const formatResponseForDetailsTable = (response: WeightsCallbackResponse) => {
    if (unformattedData) {
      const weightDataForDetailTableData = unformattedData.data[
        response.number - 1
      ].axle_weights.map((weightdata: AxleWeightExternal) => {
        // Check if the name is not "net" and "gross" before including it in the table
        if (
          weightdata.name.toLowerCase() !== "net" &&
          weightdata.name.toLowerCase() !== "gross"
        ) {
          return {
            label: `${weightdata.name} Weight`,
            content:
              weightdata.weight.received === true
                ? kgToPounds(weightdata.weight.value) // Convert kgrams to pounds
                : null,
            editable: false,
            error: "",
          };
        }
        return null; // Return null for "net" Weight, which will be filtered out
      });

      const filteredWeightData = weightDataForDetailTableData.filter(Boolean);

      const detailTableData: DetailTableData = {
        data: [
          {
            label: "Date & Time",
            content: rfcToDateAndTime(
              unformattedData.data[response.number - 1].date_time
            ),
            editable: false,
            error: "",
          },
          {
            label: "Latitude & Longitude",
            content: `${
              unformattedData.data[response.number - 1].latitude
                .received === true
                ? unformattedData.data[response.number - 1].latitude
                    .value
                : null
            }, ${
              unformattedData.data[response.number - 1].longitude
                .received === true
                ? unformattedData.data[response.number - 1].longitude
                    .value
                : null
            }`,
            editable: false,
            error: "",
          },
          {
            label: "Updated At",
            content: rfcToDateAndTime(
              unformattedData.data[response.number - 1].updated_at
            ),
            editable: false,
            error: "",
          },
          {
            label: "Vehicle ID",
            content:
              unformattedData.data[response.number - 1].vehicle_id,
            editable: false,
            error: "",
          },
          {
            label: "Gross Weight",
            content:
              unformattedData.data[response.number - 1].weight.value
                .received === true
                ? // ? unformattedData.data[
                  // 		response.number - 1
                  //   ].weight.value.value.toString()
                  kgToPounds(
                    unformattedData.data[response.number - 1].weight
                      .value.value
                  ) // Convert kgrams to pounds
                : null,
            editable: false,
            error: "",
          },
        ],
      };

      detailTableData.data = [
        ...detailTableData.data,
        ...filteredWeightData,
      ] as DetailTableDataPoint[];
      // detailTableData.data = detailTableData.data.concat(
      // 	weightDataForDetailTableData
      // );
      return detailTableData;
    }
  };

  //reset modal data on close
  const detailsTableCloseCallback = () => {
    setModalOpen(false);
    setModalData(null);
  };

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader title={"Weights & Locations"} />
        <CardContent>
          <Divider />
          <Box marginTop={2}>
            {loading ? (
              <Skeleton variant="rectangular" height={350} />
            ) : (
              <MyMap
                title={"Weights & Locations"}
                longlat={longlat}
                allowFullscreen={true}
                dataPoints={
                  // Map the formatted weights to the dataPoints. Use VehicleWeightPoint
                  formattedWeights
                    ? formattedWeights.data.map((data) => ({
                      ...data,
                      objectType: VEHICLE_WEIGHT_POINT,
                    }))
                    : []
                }
                searchBar={true}
                mapSearchCallback={mapSearchCallback}
                count={0}
                zoom={zoom}
                fitBounds={fitBounds}
                popupClickCallback={popupClickCallback}
                currentSnapshot={currentSnapshot}
              />
            )}
            <GeneralTable
              data={
                unformattedData ?
                unformattedData?.data.map((data, number) => ({
                  number: number + 1,
                  latitude: data.latitude.received ? data.latitude.value : 44,
                  longitude: data.longitude.received ? data.longitude.value : -123,
                  date_time: data.date_time,
                  weight: data.weight.value.received ? data.weight.value.value : -1,
                  weight2: data.weight.value.received ? kgToPounds(data.weight.value.value) : "Not Specified",
                  snapshot: "go",
                  more_details: "open",
                })) : {}
              }
              columns={columns}
              rowClickCallback={rowClickCallback}
              toolbar={true}
              timeSelector={true}
              timeCallback={timeCallback}
              noDataMessage="No Data To Display"
              loading={loading}
            />
            {!loading && formattedWeights && unformattedData ? (
              unformattedData.next_page ? (
                <Box margin={2}>
                  <Alert
                    severity="error"
                    action={<Button variant="outlined">Next Page</Button>}
                  >
                    <AlertTitle>
                      Not All Data Retrived, Click To Retrieve Next Page.
                    </AlertTitle>
                  </Alert>
                </Box>
              ) : null
            ) : null}
          </Box>
        </CardContent>
      </Card>
      {modalData ? (
        <Backdrop open={modalOpen} sx={{ zIndex: 500 }}>
          <Card>
            <Box
              marginLeft={{ xs: 2, md: 5 }} // Adjust margins for different screen sizes
              marginTop={{ xs: 2, md: 5 }} // Adjust margins for different screen sizes
              marginBottom={{ xs: 2, md: 5 }} // Adjust margins for different screen sizes
              marginRight={{ xs: 2, md: 2 }} // Adjust margins for different screen sizes
            >
              <div
                style={{
                  maxHeight: "80vh",
                  maxWidth: "30vw",
                  overflowY: "auto",
                }}
              >
                <DetailsTable
                  data={modalData}
                  putCallback={detailsTableCloseCallback}
                  editing={false}
                  close={true}
                />
              </div>
            </Box>
          </Card>
        </Backdrop>
      ) : null}
    </>
  );
}
