import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TestLogData } from "../../../../utils/interfaces/devices/test-logs-interface";
import { rfcToDateAndTime } from "../../../../utils/custom-functions/rfc3339-conversions";
import { useEffect, useState } from "react";
import { ConstructionOutlined } from "@mui/icons-material";

interface TestLogsTestResultsProps {
    testlogdata: TestLogData | null;
    testPassed: boolean; // New prop to determine the test passed status
}
export default function TestLogsTestResults(props: TestLogsTestResultsProps) {
    const testEndEpochUtcTime = props.testlogdata?.test_result?.test_end_epoch_utc_time;
    const [testPassed, setTestPassed] = useState<boolean>(props.testlogdata?.test_result?.test_passed!); // State for test passed

    // Convert the Unix timestamp to a string
    const formattedDateAndTime = testEndEpochUtcTime !== undefined
        ? rfcToDateAndTime(new Date(testEndEpochUtcTime * 1000).toISOString())
        : "N/A";

    useEffect(() => {
        if (props.testlogdata) {
            setTestPassed(props.testlogdata.test_result?.test_passed!);
        }
    }, [props.testlogdata, props.testPassed]);

    return (
        <>
            <Card elevation={8} sx={{ borderRadius: 3, flex: 1 }}>
                <CardHeader title="Test Results"
                    action={
                        <IconButton aria-label="info">
                            <InfoOutlinedIcon />
                        </IconButton>
                    } />
                <Divider />
                <CardContent>
                    <Stack direction={"row"} spacing={2} margin={2}>
                        <Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
                            <Typography variant="body1"><b>Passed/Failed</b></Typography>
                            {testPassed ? (
                                <CheckCircleIcon fontSize="large" style={{ color: "green" }} />
                            ) : (
                                <CancelIcon fontSize="large" style={{ color: "red" }} />
                            )}
                        </Stack>
                        <Divider flexItem orientation="vertical" />
                        <Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
                            <Typography variant="body1"><b>Date:</b></Typography>
                            <Typography>{formattedDateAndTime}</Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
}
