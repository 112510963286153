import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getDeviceRegistration = async (serialnumber: string | undefined) => {
  const url = buildBaseUrl();
  if (!serialnumber) {
    return;
  }
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}device/${serialnumber}/register`, {
        // const response = await fetch(`${url}device/0100000001/register `, {
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
      throw new Error("Unauthorized: Invalid token or no token provided");
    } else if (response.status === 403) {
      throw new Error("Forbidden: You lack permission to access the device");
    } else if (response.status === 500) {
      throw new Error("Server Error: Unable to process the request");
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  } catch (err) {
    console.error("Error from getDeviceRegistration:", err);
    throw err; // Rethrow the error for further handling if needed
  }
};
