import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleClient } from "../../utils/requests/clients/get-single-client";
import { getClient } from "../../utils/interfaces/clients/clients-list-interface";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import Details from "../../pages/client-page/client-details-elements/details";
import { AppContext } from "../../utils/themes/theme-context";
import Actions from "./client-details-elements/actions";
import AlertsAndFaults from "./client-details-elements/alerts-faults";
import { getVehicleWeights } from "../../utils/requests/vehicles-routes/get-vehicle-weights";
//furure use
// import Tickets from "./client-details-elements/tickets";
import { getClientVehicles } from "../../utils/requests/clients/get-client-vehicles";
import { ClientVehicleData } from "../../utils/interfaces/clients/clientvehicles-interface";
import { Columns } from "../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../utils/generic-components/table/table";
import { SingleVehicle } from "../../utils/interfaces/vehicles/vehicles-interfaces";
import WeightsLocationsVehicleTab from "./vehicle-table-tab/weights-locations-vehicles-tab";
import "../../utils/styles/clientpagestyle.css";
import { getClientVehiclesMap } from "../../utils/requests/clients/getclientvehicles-map";
import { getUsersForClients } from "../../utils/requests/clients/get-users-for-client";
import { UserData } from "../../utils/interfaces/clients/client-user-interface";
import ClientDevicesTablList from "./device-table-tab-clients/client-device-table-tab";
import ActiveDevicesClients from "./client-details-elements/active-devices";
import PreconfigurationsClients from "./client-details-elements/preconfiguration";
import Stats from "./client-details-elements/stats";
import ClientProductStats from "./client-details-elements/clientproductstats";

let columns: Columns = {
  FirmwareVersion: "Firmware Version",
  //future use
  // OpenTickets: "Open Tickets",
  Product: {
    type: "text",
    display: "Product",
    data: "",
    sort: "Product",
  },
  UpdatedAt: {
    type: "time",
    display: "Updated At",
    data: "",
    sort: "UpdatedAt",
  },
  VehicleName: {
    type: "link",
    display: "Vehicle Name",
    data: "",
    sort: "VehicleName",
  },
  VehicleRegistrationDate: {
    type: "time",
    display: "Vehicle Registration Date",
    data: "",
    sort: "VehicleRegistrationDate",
  },
  VehicleUpdatedAt: {
    type: "time",
    display: "Vehicle Updated At",
    data: "",
    sort: "VehicleUpdatedAt",
  },
  LatestWeight: "Latest Weight",
  //future use
  // YearTotalTickets: "Year Total Tickets",
};

export function SingleClientDetailsPage() {
  var { perms, theme } = useContext(AppContext);
  const { id } = useParams<{ id?: string }>();
  const [clientDetails, setClientDetails] = useState<getClient | null>(null);
  const [userDetails, setUserDetails] = useState<UserData[]>([]);
  let userNavigate = useNavigate();
  const [transition, setTransition] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [updatedat, setUpdatedAt] = useState<string>("");
  const [activeButton, setActiveButton] = useState<string>("Home");
  const [displayDetailsTable, setDisplayDetailsTable] = useState<boolean>(true);
  const [displayVehicles, setDisplayVehicles] = useState<boolean>(false);
  const [displayDevices, setDisplayDevices] = useState<boolean>(false);
  const [displayUsers, setDisplayUsers] = useState<boolean>(false);
  const [displayAlerts, setDisplayAlerts] = useState<boolean>(false);
  // const [displayTickets, setDisplayTickets] = useState<boolean>(false); //future use
  const [displayLogs, setDisplayLogs] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<ClientVehicleData[]>([]); // Initialize as an empty array
  const [isLoadingVehicles, setIsLoadingVehicles] = useState<boolean>(false);
  let { client_id, vehicle_id } = useParams<{
    client_id?: string | undefined;
    vehicle_id?: string;
  }>(); //change for alerts and faults its just a placeholder used from vehicles page
  const [loading, setLoading] = useState<boolean>(true);

  let columns3: Columns = {
    UserName: {
      type: "button",
      display: "User Name",
      data: "username",
      sort: "UserName",
      onClick: (rowData: { UserName: any }) => {
        const username = rowData.UserName;
        userNavigate(`/users/${username}`);
      },
    },
    Email: {
      type: "text",
      display: "Email",
      data: "user_email",
      sort: "Email",
    },
    Name: {
      type: "text",
      display: "Name",
      data: "user_name",
      sort: "Name",
    },
    UserType: {
      type: "text",
      display: "User Type",
      data: "user_type",
      sort: "UserType",
    },
  };

  useEffect(() => {
    setLoading(true);
    if (id) {
      //check if id is defined
      getSingleClient(id)
        .then((data: getClient) => {
          setClientDetails(data);
          setUpdatedAt(data.data.updated_at); //updated time
        })
        .catch((error) => {
          setErrorMessage(true);
        });

      // Fetch client vehicles
      setIsLoadingVehicles(true);

      getClientVehicles(id)
        .then((data: ClientVehicleData[]) => {
          setVehicles(data);
          setIsLoadingVehicles(false);
        })
        .catch((error) => {
        });

      getUsersForClients()
        .then((data: UserData[]) => {
          // Filter user data based on the matching client_id
          const filteredUsers = data.filter((user) => user.client_id === id);

          setUserDetails(filteredUsers);
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage(true);
          setLoading(false);
        });
    }
  }, [id]);

  const fetchClientVehiclesWeights = async () => {
    if (id) {
      try {
        const response = await getClientVehiclesMap(id);
        return response;
      } catch (err) { }
    }
  };

  function handleBack(): void {
    setTransition(false);
    setTimeout(() => userNavigate(`/clients`), 400);
  }

  const loadingDisplay = () => {
    if (!clientDetails) {
      return (
        <>
          <LinearProgress />
          <Stack direction="row" spacing={3} marginTop={5}>
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={275} height={500} />
              <Skeleton variant="rectangular" width={275} height={200} />
            </Stack>
            <Divider flexItem orientation="vertical" />
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={700} height={200} />
              <Skeleton variant="rectangular" width={700} height={400} />
            </Stack>
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={275} height={150} />
              <Skeleton variant="rectangular" width={275} height={100} />
            </Stack>
          </Stack>
        </>
      );
    }
    return null;
  };

  if (!clientDetails) {
    return (
      <>
        <LinearProgress />
        <Box marginTop={10} marginLeft={10}>
          <Skeleton variant="text" width={200} height={50} />
          <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
          <Box>
            <Stack direction="row" spacing={3}>
              <Skeleton variant="text" width={150} height={50} />
              <Skeleton variant="text" width={150} height={50} />
              <Skeleton variant="text" width={150} height={50} />
              <Skeleton variant="text" width={150} height={50} />
            </Stack>
          </Box>
          <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
          <Box marginTop={2}>
            <Stack direction="row" spacing={3}>
              <Stack direction="column" spacing={3} marginRight={2}>
                <Skeleton variant="rectangular" width={275} height={500} />
                <Skeleton variant="rectangular" width={275} height={200} />
                <Skeleton variant="rectangular" width={275} height={200} />
              </Stack>
              <Divider flexItem orientation="vertical" />
              <Stack direction="column" spacing={3} marginRight={2}>
                <Skeleton variant="rectangular" width={700} height={200} />
                <Skeleton variant="rectangular" width={700} height={400} />
                <Skeleton variant="rectangular" width={700} height={400} />
              </Stack>
            </Stack>
          </Box>
        </Box>
      </>
    );
  }

  const handleButtonClick = async (buttonName: string) => {
    setActiveButton(buttonName);
    setDisplayDetailsTable(buttonName === "Home");
    setDisplayVehicles(buttonName === "Vehicles");
    setDisplayDevices(buttonName === "Devices");
    setDisplayUsers(buttonName === "Users");
  };

  const fetchWeightsAlert = async () => {
    //change for alerts and faults its just a placeholder used from vehicles page
    // getVehicleWeights interfaces have changed to accomodate weight map
    try {
      const response = await getVehicleWeights(client_id, vehicle_id);
      return response;
    } catch (err) { }
  };

  function handleRowClick(selectedvehicle: SingleVehicle) {
    setTransition(false);
    setTimeout(
      () =>
        userNavigate(
          `/vehicles/${selectedvehicle.VehicleId}`
        ),
      400
    );
  }

  // Function to convert kilograms to pounds and append the unit
  function kgToPounds(kilograms: number) {
    const pounds = kilograms * 2.20462; //1 kilogram is approximately equal to 2.20462 pounds.
    const roundedPounds = Math.round(pounds / 10) * 10;
    return `${roundedPounds} lbs`;
  }

  return (
    <>
      <Slide direction="left" in={transition} timeout={400}>
        <Box
          flexGrow={1}
          marginLeft={"2%"}
          marginRight={"2%"}
          marginTop={8}
          marginBottom={10}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h2>Client Details</h2>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="flex-end"
                marginTop={3}
                marginRight={2}
              >
                <Button
                  color={theme.backbuttoncolor}
                  onClick={handleBack}
                  variant={"contained"}
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Grid>
            <Box
              display="flex"
              justifyContent="flex-start"
              marginTop={1}
              marginBottom={1}
            >
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Home"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Home")}
              >
                Home
              </Button>
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Vehicles"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Vehicles")}
              >
                Vehicles{" "}
                {isLoadingVehicles ? (
                  <CircularProgress
                    size={20}
                    color="inherit"
                    style={{ marginLeft: "5px" }}
                  />
                ) : null}
              </Button>
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Devices"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Devices")}
              >
                Devices
              </Button>
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Users"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Users")}
              >
                Users
              </Button>
            </Box>
          </Grid>
          <Divider />

          <>
            {!isLoadingVehicles && displayVehicles ? (
              perms?.aw_permissions.vehicle_read ? (
                vehicles.length > 0 ? (
                  <Box padding={2}>
                    <Typography variant="h5">
                      {vehicles[0].client} Vehicles Details
                    </Typography>
                    <Stack direction={"row"} spacing={3}>
                      <Box flex={2}>
                        <GeneralTable
                          data={vehicles.map((vehicle) => ({
                            Client: vehicle.client,
                            ClientId: vehicle.client_id,
                            DeviceId: vehicle.device_id,
                            FirmwareId: vehicle.firmware_id,
                            FirmwareVersion: vehicle.firmware_version,
                            //future use
                            // OpenTickets: vehicle.open_tickets,
                            Product: vehicle.product,
                            ProductId: vehicle.product_id,
                            UpdatedAt: vehicle.updated_at,
                            VehicleId: vehicle.vehicle_id,
                            VehicleName: vehicle.vehicle_name,
                            VehicleRegistrationDate:
                              vehicle.vehicle_registration_date,
                            VehicleUpdatedAt: vehicle.vehicle_updated_at,
                            // LatestWeight: kgToPounds(
                            //   vehicle.latest_weights[0]?.weight?.value?.value
                            // ), // Convert grams to pounds

                            LatestWeight: vehicle.latest_weights && vehicle.latest_weights.length > 0
                              ? kgToPounds(vehicle.latest_weights[0]?.weight?.value?.value)
                              : 'N/A', // Fallback value if latest_weights is empty or null

                            //future use
                            // YearTotalTickets: vehicle.year_total_tickets,
                          }))}
                          columns={columns}
                          toolbar={false}
                          timeSelector={true}
                          noDataMessage={"Unable To Fetch Vehicles"}
                          rowClickCallback={handleRowClick}
                          rows={10}
                          rowsoptions={[10, 25, 50, 100]}
                        />
                      </Box>
                      <Box flex={1}>
                        <WeightsLocationsVehicleTab
                          fetchWeights={fetchClientVehiclesWeights}
                        />
                      </Box>
                    </Stack>
                  </Box>
                ) : (
                  <Box paddingTop={2} color="red">
                    <Alert severity="error">No vehicles found</Alert>
                  </Box>
                )
              ) : (
                <Alert
                  variant="filled"
                  severity="warning"
                  style={{ width: "100%" }}
                >
                  No permission to view vehicle data.
                </Alert>
              )
            ) : displayDevices ? (
              <Box padding={2}>
                <ClientDevicesTablList />
              </Box>
            ) : displayUsers ? (
              perms?.aw_permissions.users_read ? (
                userDetails.length > 0 ? (
                  <Box padding={2}>
                    <Typography variant="h5">Users</Typography>
                    <Stack direction={"row"} spacing={3}>
                      <Box flex={2}>
                        <GeneralTable
                          data={userDetails.map((user) => ({
                            Name: user.user_name,
                            Email: user.user_email,
                            UserName: user.username,
                            UserType: user.user_type,
                          }))}
                          columns={columns3}
                          toolbar={false}
                          timeSelector={true}
                          noDataMessage={"Unable To Fetch Users"}
                          rows={10}
                          rowsoptions={[10, 25, 50, 100]}
                        />
                      </Box>
                    </Stack>
                  </Box>
                ) : (
                  <Box paddingTop={2} color="red">
                    <Alert severity="error">No Users found</Alert>
                  </Box>
                )
              ) : (
                <Alert
                  variant="filled"
                  severity="warning"
                  style={{ width: "100%" }}
                >
                  No permission to view user data.
                </Alert>
              )
            ) : displayAlerts ? (
              <Box padding={2}>
                <Typography variant="h4">Alerts/Faults Data Display</Typography>
              </Box>
            ) : //future use
              // : displayTickets ? (
              //   <Box padding={2}>
              //     <Typography variant="h4">Tickets Data Display</Typography>
              //   </Box>
              // )
              displayLogs ? (
                <Box padding={2}>
                  <Typography variant="h4">Logs Data Display</Typography>
                </Box>
              ) : errorMessage ? (
                <Box paddingTop={2} color="red">
                  <Alert severity="error">Invalid Client</Alert>
                </Box>
              ) : displayDetailsTable || clientDetails ? (
                <>
                  <Stack
                    direction="row"
                    spacing={3}
                    marginTop={5}
                    marginBottom={10}
                  >
                    <Stack direction="column" spacing={3} width="30%">
                      <Details client={clientDetails} updatedat={updatedat} />
                      <Actions client={clientDetails} />
                      <ClientProductStats clientID={clientDetails.data.id} />
                      {/* <AlertsAndFaults fetchWeights={fetchWeightsAlert} /> */}
                      {/* future use */}
                      {/* <Tickets fetchWeights={fetchWeightsAlert} /> */}
                    </Stack>
                    <Divider flexItem orientation="vertical" />
                    <Stack direction="column" spacing={3} width="70%">
                      <Stack direction="row" spacing={3}>
                        <Stats clientID={clientDetails.data.id} />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <ActiveDevicesClients />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <PreconfigurationsClients />
                      </Stack>
                      {/* <Stack direction="row" spacing={2}>
                      <AlertsAndFaults fetchWeights={fetchWeightsAlert} />
                      <Tickets fetchWeights={fetchWeightsAlert} />
                    </Stack> */}

                      {/* Other components */}
                    </Stack>
                  </Stack>
                </>
              ) : (
                loadingDisplay()
              )}
          </>
        </Box>
      </Slide>
    </>
  );
}
