import ErrorIcon from "@mui/icons-material/Error";
import {
  Alert,
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Vehicle } from "../../../utils/interfaces/vehicles/vehicles-interfaces";
import { useContext, useEffect, useState } from "react";
import { fetchSingleProductData } from "../../../utils/requests/products/get-single-product";
import { DeviceFirmware } from "../../../utils/interfaces/devices/device-firmware-interface";
import { getDeviceFirmware } from "../../../utils/requests/devices/get-device-firmware";
import { getDevices } from "../../../utils/requests/devices/get-devices-details";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { auto } from "@popperjs/core";
import { AppContext } from "../../../utils/themes/theme-context";

export default function Status(props: { vehicle: Vehicle }) {
  var { perms, theme } = useContext(AppContext);
  const [firmwareUpdateSupport, setFirmwareUpdateSupport] = useState(false);
  const [deviceFirmwareData, setDeviceFirmwareData] =
    useState<DeviceFirmware | null>();
  const [serialNumber, setSerialNumber] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const productidget = props.vehicle.data.product_id;
  const deviceidget = props.vehicle.data.device_id;
  const firmwareversionget = props.vehicle.data.firmware_version;

  useEffect(() => {
    const fetchSerialNumber = async () => {
      try {
        const response = await getDevices(deviceidget);
        setSerialNumber(response.serial);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSerialNumber(); // Fetch the serial number when the component mounts or when deviceidget changes

    const fetchProductFirmwareUpdateSupport = async () => {
      const respdata = await fetchSingleProductData(productidget);
      setFirmwareUpdateSupport(respdata.firmware_update_support);
    };

    fetchProductFirmwareUpdateSupport();

    // Fetch device firmware data whenever the serial number changes
    const fetchDeviceFirmwareData = async () => {
      try {
        if (serialNumber && firmwareversionget) {
          const response = await getDeviceFirmware(
            serialNumber,
            firmwareversionget
          );
          setDeviceFirmwareData(response);
          setErrorMessage(null); // Clear any previous error message
        }
      } catch (err) {
        setDeviceFirmwareData(null);
      }
    };

    fetchDeviceFirmwareData(); // Fetch device registration data when the serial number changes
  }, [productidget, firmwareversionget, serialNumber]);

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Status"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Stack direction={"row"} spacing={auto} margin={2}>
            <Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
              <Typography>Alerts</Typography>
              <ErrorIcon
                fontSize="large"
                style={{ color: "red" }}
                color={"primary"}
              />
            </Stack>
            <Divider flexItem orientation="vertical" />
            {perms?.aw_permissions.firmware_read ?
              <>
                {firmwareUpdateSupport ? (
                  <>
                    <Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
                      <Typography>Firmware Version</Typography>
                      <Typography>{props.vehicle.data.firmware_version}</Typography>
                    </Stack>
                    <Divider flexItem orientation="vertical" />
                    <Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
                      <Typography>Firmware Up-To-Date</Typography>

                      {!deviceFirmwareData ? (
                        <CircularProgress size={20} color="primary" />
                      ) : deviceFirmwareData.update_available ? ( // Conditionally render the icon based on registration_status
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CheckCircleIcon
                            fontSize="large"
                            style={{ color: "green" }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ErrorIcon fontSize="large" style={{ color: "red" }} /> {/* Red error icon for false */}
                        </div>
                      )}
                    </Stack>
                  </>
                ) : (
                  <Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
                    <Typography>Firmware Version</Typography>
                    <Box>
                      <Backdrop
                        open={true}
                        sx={{
                          position: "relative",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Alert variant="filled" severity="warning">
                          <Typography>Unsupported Product Feature.</Typography>
                        </Alert>
                      </Backdrop>
                    </Box>
                  </Stack>
                )}
              </> : (
                <Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
                  <Typography>Firmware Version</Typography>
                  <Box>
                    <Backdrop
                      open={true}
                      sx={{
                        position: "relative",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Alert variant="filled" severity="warning">
                        <Typography>No permission to view firmware data</Typography>
                      </Alert>
                    </Backdrop>
                  </Box>
                </Stack>
              )}
            {/* <Divider flexItem orientation="vertical" />
            <Stack direction={"column"} spacing={2} alignItems={"center"}>
              <Typography>Open Tickets</Typography>
              <Typography>{props.vehicle.data.open_tickets}</Typography>
            </Stack> */}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
