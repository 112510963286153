import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from '../build-base-url';
import { ServicePlansInterface } from "../../interfaces/service/service-list-interface";

export const PostNewServicePlan = async (data: ServicePlansInterface): Promise<Response> => {
    const url = buildBaseUrl()
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}service_plans`, {
      method: 'POST',
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  };