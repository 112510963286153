//A successful response indicates a list of clients the user is authorized to see has been returned.

import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { DeviceData } from "../../interfaces/devices/devices-interface";

export const getDevicesDetails = async (pageKey?: string): Promise<DeviceData> => {
	const url = buildBaseUrl()
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(`${url}device${pageKey ? `?page=${pageKey}` : ''}`, {
			headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
			},
		});
		const data: DeviceData = await response.json(); //Extracting data array from response
		return data;
	} catch (err) {
		console.log(err);
		throw err;
	}
};