import { Alert, Box, Divider, Grid, Typography } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import Confetti from "react-confetti";
import { AppContext } from "../../utils/themes/theme-context";

function WelcomePageCorp() {
  var { perms, theme } = useContext(AppContext);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    // Check if the user has logged in for the first time (you can use local storage for this)
    const hasLoggedInBefore = localStorage.getItem("hasLoggedInBefore");

    if (!hasLoggedInBefore) {
      // Set a flag in local storage to indicate that the user has logged in
      localStorage.setItem("hasLoggedInBefore", "true");

      // Show confetti for 15 seconds
      setShowConfetti(true);

      setTimeout(() => {
        setShowConfetti(false);
      }, 15000); // 15 seconds in milliseconds
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      marginX="auto" // Center horizontally
    >
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={200}
        />
      )}
      <Box
        maxWidth="600px" // Optional: set maximum width to prevent content from stretching too much on larger screens
        paddingX={2}
        paddingBottom={10}
        textAlign="center"
      >
        <img
          src="/images/loadinglogo.png"
          alt="Logo"
          style={{ width: "150px", marginBottom: "1rem" }}
        />
        <Typography variant="h4" gutterBottom>
          👋 Welcome to Air Weigh Cloud Portal
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Manage and analyze your userbase, devices and vehicles data with ease.
        </Typography>
        {perms?.user_type === "aw" && (
          <Box paddingTop={2} color="red">
            <Alert severity="error">You do not have permission to view the Dashboard Stats</Alert>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default WelcomePageCorp;
