import { fetchAuthSession } from 'aws-amplify/auth';
import { newuser } from "../../interfaces/new-user/new-user-interface";
import { buildBaseUrl } from "../build-base-url";

export const postNewUser = async (newUser: newuser) => {
	const url = buildBaseUrl()
	const { accessToken } = (await fetchAuthSession()).tokens ?? {};
	const response = await fetch(`${url}users/new`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken?.toString()}`,
		},
		body: JSON.stringify(newUser),
	});

	let data = await response.json();
	return data;
};
