import {
  Box,
  Slide,
  Divider,
  Alert,
  AlertTitle,
  LinearProgress,
  Skeleton,
  Stack,
  Backdrop,
} from "@mui/material";
import { AppContext } from "../../../utils/themes/theme-context";
import { useContext, useEffect, useState } from "react";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { Client } from "../../../utils/interfaces/clients/clients-list-interface";
import { fetchClientsServicePlan } from "../../../utils/requests/serviceplans/get-clients-serviceplan";
import { useNavigate, useParams } from "react-router-dom";

export default function ClientsTabServicePlan() {
  var { perms, theme } = useContext(AppContext);
  const { id } = useParams<{ id?: string }>();
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [transitionout, setTransitionOut] = useState<boolean>(true);
  let userNavigate = useNavigate();

  let columns: Columns = {
    Client: {
      type: "link",
      display: "Client",
      data: "client",
      sort: "Client",
    },
  };

  useEffect(() => {
    if (id) {
      //Fetch Client list data and update the state
      fetchClientsServicePlan(id)
        .then((response) => {
          setLoading(false);
          setClients(response.clients);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              setErrorMessage("Parameter mismatch");
            } else if (error.response.status === 500) {
              setErrorMessage("Internal Server Error");
            } else {
              // other errors
              setErrorMessage(`Error: ${error.message}`);
            }
          } else if (error.request) {
            // The request was made but no response was received
            setErrorMessage("No response received");
          } else {
            // Something happened in setting up the request that triggered an error
            setErrorMessage(`Error: ${error.message}`);
          }
        });
    }
  }, [id]);

  function handleRowClick(selectedClient: Client) {
    setTransitionOut(false);
    setTimeout(() => userNavigate(`/clients/${selectedClient.id}`), 400);
  }

  const loadingDisplay = () => {
    return (
      <>
        <LinearProgress />
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
        </Stack>
      </>
    );
    return null;
  };

  return (
    <>
      <Slide direction="right" in={transitionout} timeout={400}>
        <Box>
          <h2>Clients</h2>
          <Divider />
          <Box paddingTop={2}>
            {perms.aw_permissions.clients_read ?
              (
                // If the user has 'clients_read' permission, show the clients table
                <GeneralTable
                  data={clients.map((item, index) => ({
                    id: item.id,
                    Client: item.client,
                  }))}
                  columns={columns}
                  loading={loading} //passing the loading state
                  noDataMessage={"No Clients to fetch for this Service Plan"}
                  rowClickCallback={handleRowClick}
                  rows={10}
                  rowsoptions={[10, 25, 50, 100]}
                />
              ) : (
                // If the user does not have 'clients_read' permission, show a warning
                <Box>
                  <Backdrop
                    open={true}
                    sx={{
                      position: "relative",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Alert variant="filled" severity="warning" style={{ width: "100%" }}>
                      No permission to view client data.
                    </Alert>
                  </Backdrop>
                </Box>
              )}
          </Box>
        </Box>
      </Slide>
    </>
  );
}
