import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from '../build-base-url';
import { RegisterClient } from "../../interfaces/vehicles/devices-details-interface";

export const RegisterClienttoDevice = async (id: string, data: RegisterClient): Promise<Response> => {
    const url = buildBaseUrl()
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}device/${id}/register`, {
      method: 'POST',
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  };