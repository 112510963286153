import {
	Alert,
	AlertTitle,
	Backdrop,
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useContext, useEffect, useState } from "react";
import { ProductStatsData } from "../../../utils/interfaces/clients/client-stats-interface";
import { getClientStats } from "../../../utils/requests/clients/get-client-stats";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import { AppContext } from "../../../utils/themes/theme-context";

export default function Stats(props: { clientID: string }) {
	var { perms, theme } = useContext(AppContext);
	const [statsData, setStatsData] = useState<ProductStatsData | null>(null); // State to hold fetched data
	const [error, setError] = useState<string | null>(null);

	// Function to fetch client stats when component mounts
	const fetchStatsData = async () => {
		try {
			const clientId = props.clientID;
			const data = await getClientStats(clientId);
			setStatsData(data); // Set the fetched data into state
			setError(null); // Clear any previous errors
		} catch (error: any) {
			if (error.response) {
				const { status, data } = error.response; // Destructure 'status' and 'data' from the 'response' property of the 'error' object.
				if (status === 400) {
					setError(`Bad Request: ${data.errors ? data.errors[0] : 'Invalid request'}`);
				} else if (status === 401) {
					setError(`Unauthorized: ${data.errors ? data.errors[0] : 'Unauthorized access'}`);
				} else if (status === 403) {
					setError(`Forbidden: ${data.errors ? data.errors[0] : 'Access denied'}`);
				} else if (status === 500) {
					setError(`Server Error: ${data.errors ? data.errors[0] : 'Internal server error'}`);
				} else {
					setError('An error occurred');
				}
			} else {
				setStatsData(null); // Reset statsData to null on error
				setError('Failed to fetch client stats'); // Generic error message
			}
		}
	};

	// Fetch data when component mounts
	useEffect(() => {
		fetchStatsData();
	}, [props.clientID]);

	return (
		<>
			<Card elevation={8} sx={{ borderRadius: 3, overflowX: 'auto' }}>
				<CardHeader title="Stats" action={
					<IconButton aria-label="info">
						<InfoOutlinedIcon />
					</IconButton>
				}
				/>
				<CardContent>
					{error ? (
						<Alert severity="error" sx={{ marginBottom: 2 }}>
							<AlertTitle>Error</AlertTitle>
							{error}
						</Alert>
					) : (
						<><Divider />
							<Stack direction={"row"} spacing={12} marginTop={2} marginLeft={8} marginRight={8}>
								<Stack direction={"column"} spacing={2} alignItems={"center"}>
									<Typography>Total Devices</Typography>
									<Divider flexItem orientation="horizontal" />
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Box
											sx={{
												borderRadius: "20%",
												padding: "10px",
											}}
										>
											<Typography>{statsData?.total_devices.total}</Typography>
										</Box>
										<Tooltip title={statsData?.total_devices.date_time !== undefined
											? rfcToDateAndTime(statsData?.total_devices.date_time)
											: ''} placement="right">
											<CalendarTodayIcon color="inherit" fontSize="small" sx={{ marginLeft: 1 }} />
										</Tooltip>
									</Box>
								</Stack>
								<Divider flexItem orientation="vertical" />
								<Stack direction={"column"} spacing={2} alignItems={"center"}>
									<Typography>Total Vehicles</Typography>
									<Divider flexItem orientation="horizontal" />
									{perms.aw_permissions.vehicle_read ? (
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box
												sx={{
													borderRadius: "20%",
													padding: "10px",
												}}
											>
												<Typography>{statsData?.total_vehicles.total}</Typography>
											</Box>
											<Tooltip title={statsData?.total_vehicles.date_time !== undefined
												? rfcToDateAndTime(statsData?.total_vehicles.date_time)
												: ''} placement="right">
												<CalendarTodayIcon color="inherit" fontSize="small" sx={{ marginLeft: 1 }} />
											</Tooltip>
										</Box>
									) : (<>
										<Box>
											<Backdrop
												open={true}
												sx={{
													position: "relative",
													top: 0,
													left: 0,
													width: "100%",
													height: "100%",
												}}
											>
												<Alert
													variant="filled"
													severity="warning"
													style={{ width: "100%" }}
												>
													No permission to view vehicle data.
												</Alert>
											</Backdrop>
										</Box>
									</>)}
								</Stack>
								<Divider flexItem orientation="vertical" />
								<Stack direction={"column"} spacing={2} alignItems={"center"}>
									<Typography>Outdated Firmware</Typography>
									<Divider flexItem orientation="horizontal" />
									{perms.aw_permissions.firmware_read ? (
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box
												sx={{
													borderRadius: "20%",
													padding: "10px",
												}}
											>
												<Typography>{statsData?.outdated_firmware.total}</Typography>
											</Box>
											<Tooltip title={statsData?.outdated_firmware.date_time !== undefined
												? rfcToDateAndTime(statsData?.outdated_firmware.date_time)
												: ''} placement="right">
												<CalendarTodayIcon color="inherit" fontSize="small" sx={{ marginLeft: 1 }} />
											</Tooltip>
										</Box>
									) : (<>
										<Box>
											<Backdrop
												open={true}
												sx={{
													position: "relative",
													top: 0,
													left: 0,
													width: "100%",
													height: "100%",
												}}
											>
												<Alert
													variant="filled"
													severity="warning"
													style={{ width: "100%" }}
												>
													No permission to view firmware data.
												</Alert>
											</Backdrop>
										</Box>
									</>)}
								</Stack>
								<Divider flexItem orientation="vertical" />
								<Stack direction={"column"} spacing={2} alignItems={"center"}>
									<Typography>Uncalibrated Devices</Typography>
									<Divider flexItem orientation="horizontal" />
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Box
											sx={{
												borderRadius: "20%",
												padding: "10px",
											}}
										>
											<Typography>{statsData?.uncalibrated_devices.total}</Typography>
										</Box>
										<Tooltip title={statsData?.uncalibrated_devices.date_time !== undefined
											? rfcToDateAndTime(statsData?.uncalibrated_devices.date_time)
											: ''} placement="right">
											<CalendarTodayIcon color="inherit" fontSize="small" sx={{ marginLeft: 1 }} />
										</Tooltip>
									</Box>
								</Stack>
								{/* Tickets future use */}
								{/* <Divider flexItem orientation="vertical" />
							<Stack direction={"column"} spacing={2} alignItems={"center"}> */}
								{/* <Typography>Tickets</Typography>
								<Divider flexItem orientation="horizontal" />
								<Box
									sx={{
										// backgroundColor: theme.tertiary,
										borderRadius: "20%",
										padding: "10px",
									}}>
									<Typography>12</Typography>
							</Box> */}
								{/* <Typography>{props.clientstatus.data.open_tickets}</Typography> */}
								{/* </Stack> */}
							</Stack></>
					)}
				</CardContent>
			</Card>
		</>
	);
}