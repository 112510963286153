import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Slide,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Columns } from "../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../utils/generic-components/table/table";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../utils/themes/theme-context";
import { getClientDetails } from "../../utils/requests/clients/get-clients-list";
import { Client } from "../../utils/interfaces/clients/clients-list-interface";
import { getClientSearch } from "../../utils/requests/clients/get-clients-search";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from '@mui/material/Autocomplete';
import { validateEmail, validateName, validatePhoneNumber } from "../../utils/regex/forms";
import { getAvailableClients } from "../../utils/requests/clients/get-available-clients";
import { postNewClient } from "../../utils/requests/clients/post-new-client";
import { PostNewClientData } from "../../utils/interfaces/clients/post-new-client-interface";

interface FormErrors {
  clientName?: string;
  contact?: string;
  contactEmail?: string;
  contactPhone?: string;
}

export default function ClientList() {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState<boolean>(true); //initial stage of loading is true
  var { perms, theme } = useContext(AppContext);
  const [transitionout, setTransitionOut] = useState<boolean>(true);
  let userNavigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [searchCriterion, setSearchCriterion] = useState("client");
  const [searchTerm, setSearchTerm] = useState<string | null>("");
  const [resetVisible, setResetVisible] = useState(false); // Define resetVisible state
  const [originalClients, setOriginalClients] = useState<Client[]>([]);
  const [newClientOpenDialog, setnewClientOpenDialog] = useState(false); // State for dialog visibility
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [AllClientNames, setAllClientNames] = useState<string[]>([]);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const [Clientloading, setClientLoading] = useState<boolean>(false);
  const [searchResponse, setSearchResponse] = useState<any[]>([]);
  const [selectSuggestions, setSelectSuggestions] = useState<boolean>(false);
  const [formData, setFormData] = useState<PostNewClientData>({
    client: "",
    contact: "",
    contact_email: "",
    contact_phone: "",
    comments: "",
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [activeStep, setActiveStep] = useState(0);
  const [reviewData, setReviewData] = useState<PostNewClientData>({
    client: "",
    contact: "",
    contact_email: "",
    contact_phone: "",
    comments: "",
  });
  const [submitColor, setSubmitColor] = useState("");
  const [submitResult, setSubmitResult] = useState("");

  const steps = ["Fill Details", "Review", "Result"];

  let columns: Columns = {
    Name: {
      type: "link",
      display: "Client Name",
      data: "client",
      sort: "Name",
    },
    ServicePlan: {
      type: "button",
      display: "Service Plan ID",
      data: "service_plan_id",
      sort: "ServicePlan",
      onClick: (rowData: { ServicePlan: any }) => {
        const serviceplanid = rowData.ServicePlan;
        userNavigate(`/service/${serviceplanid}`);
      },
    },
    RegistrationDate: {
      type: "time",
      display: "Registration Date",
      data: "registration_date",
      sort: "RegistrationDate",
    },
    Contact: {
      type: "text",
      display: "Contact",
      data: "contact",
      sort: "Contact",
    },
    ContactEmail: {
      type: "text",
      display: "Contact Email",
      data: "contact_email",
      sort: "ContactEmail",
    },
    ContactPhone: {
      type: "text",
      display: "Contact Phone",
      data: "contact_phone",
      sort: "ContactPhone",
    },
    Comments: {
      type: "text",
      display: "Comments",
      data: "comments",
      sort: "Comments",
    },
    UpdatedAt: {
      type: "time",
      display: "Updated At",
      data: "updated_at",
      sort: "UpdatedAt",
    },
  };


  useEffect(() => {
    //Fetch Client list data and update the state
    getClientDetails()
      .then((response: { data: Client[] }) => {
        //the response object data has a property type Client[]
        //Successful response
        const data = response.data; //data property is assigened to variable data
        setClients(data);
        setOriginalClients(data); // Store the original datas
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          if (error.response.status === 401) {
            // 401 Unauthorized error
            setErrorMessage("Parameter mismatch");
          } else if (error.response.status === 500) {
            // 500 Internal Server Error
            setErrorMessage("Internal Server Error");
          } else {
            // other errors
            setErrorMessage(`Error: ${error.message}`);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setErrorMessage("No response received");
        } else {
          // Something happened in setting up the request that triggered an error
          setErrorMessage(`Error: ${error.message}`);
        }
      });
  }, []);

  // Client name link clicked handle click to navigate to /clients/id page
  function handleRowClick(selectedClient: Client) {
    setTransitionOut(false);
    setTimeout(() => userNavigate(`/clients/${selectedClient.id}`), 400);
  }

  function handleNewTabRowClick(selectedClient: Client) {
    // setTransitionOut(false);
    window.open(`/clients/${selectedClient.id}`, "_blank");
  }

  function handleNewClient(): void {
    // Reset form data and errors
    setFormData({
      client: "",
      contact: "",
      contact_email: "",
      contact_phone: "",
      comments: "",
    });
    setFormErrors({});
    setnewClientOpenDialog(true);
  }

  const handleSearchCriterionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchCriterion(event.target.value);
    setSuggestions([]);
    setResetVisible(false);
    setClients(originalClients);
    setSearchTerm("");
  };

  const fetchSuggestions = async (searchCriterion: string, query: string) => {
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(async () => {
      try {
        setClientLoading(true);

        // If there's a query, fetch suggestions based on the query
        const searchResponse = await getClientSearch(searchCriterion, query);
        setSearchResponse(searchResponse.data);
        const clientNames = searchResponse.data.map((client: { client: string }) => client.client);
        setSuggestions(clientNames);
        setClientLoading(false);
      }
      catch (error) {
        setClientLoading(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (searchCriterion === "client") {
      if (!searchTerm || searchTerm.trim() === "") {

        const allClientNames = originalClients.map((client: { client: string }) => client.client);
        setAllClientNames(allClientNames);
        setSuggestions(allClientNames);

      } else {
        setSuggestions([]); // Clear suggestions when criterion is not `client_id` or search term is not empty
      }
    }
  }, [searchCriterion, searchTerm, originalClients]);

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;

    if (searchCriterion === "client") {
      if (newSearchTerm.trim() === "") {
        // it means the user is actively typing 

        setSearchTerm("");
        setSelectSuggestions(false);
        setResetVisible(false);
      } else {
        // it indicates that the user has deleted the search term or hasn't typed anything yet
        // we don't want to immediately trigger a search request because the user may continue typing shortly. 
        // Instead, we want to wait for a brief pause in typing before initiating the search.
        setSuggestions([])
        setSearchTerm(newSearchTerm);
        setResetVisible(true);
        fetchSuggestions(searchCriterion, newSearchTerm);
        if (selectSuggestions && suggestions.includes(newSearchTerm)) {
          setSelectSuggestions(true); // Set selectSuggestions to true if a suggestion is selected
          setResetVisible(true);
        } else {
          setSelectSuggestions(false); // Set selectSuggestions to false when search term changes
          setResetVisible(false);
        }
      }

      // Update the Autocomplete value to reflect the current search term
      setSearchTerm(newSearchTerm);
    } else {
      setSearchTerm(newSearchTerm);

      if (newSearchTerm.trim() === "") {
        // Reset button is hidden when the search term is empty
        setResetVisible(false);
      } else {
        // Show the Reset button when there is something typed
        setResetVisible(true);
      }
    }
  };

  const handleSuggestionSelect = async (selectedItem: string | null) => {
    setSearchTerm(selectedItem);
    setSelectSuggestions(true);
    setResetVisible(true);

    if (selectedItem) {
      try {
        setLoading(true);
        const searchResponse = await getClientSearch(searchCriterion, selectedItem);
        setClients(searchResponse.data)
        setLoading(false);
      } catch (error) {
        setClients(originalClients)
        setLoading(false);
      }
    }
  };

  const handleReset = () => {
    // Clear the search term and restore the original table data
    setSearchTerm("");
    setSuggestions(AllClientNames);
    setClients(originalClients)
    setResetVisible(false); // Hide the Reset button
  };

  //Form 
  const handleCloseDialog = () => {
    setnewClientOpenDialog(false);
    setFormData({
      client: "",
      contact: "",
      contact_email: "",
      contact_phone: "",
      comments: "",
    });
    setFormErrors({});
    setActiveStep(0);
  };

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: "",
    }));
  };

  const validateForm = async () => {
    const errors: FormErrors = {};
    // Validate client name
    errors.clientName = validateName(formData.client, "Invalid client name");
    const lowerCaseClientName = formData.client.toLowerCase();
    try {
      const isClientNameAvailable = await getAvailableClients(lowerCaseClientName);
      if (!isClientNameAvailable) {
        errors.clientName = "Client name already exists";
      }
    } catch (error) {
    }
    errors.contact = formData.contact ? "" : "Contact is required";
    errors.contactEmail = validateEmail(formData.contact_email, "Invalid email");

    // Special case for phone number validation
    if (formData.contact_phone === "") {
      errors.contactPhone = ""; // Allow empty phone number field
    } else {
      errors.contactPhone = validatePhoneNumber(formData.contact_phone, "Invalid phone number");
    }

    setFormErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      if (await validateForm()) {
        // Check if there are any form errors
        const clientNameError = formErrors.clientName;
        if (clientNameError) {
          // If there's an error in clientName, do not proceed to the next step
          return;
        }
        setReviewData({ ...formData });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    // POST new client 
    const response = await postNewClient(reviewData);
    if (response.status === 200) {
      setSubmitResult("New Client was successfully created");
      setSubmitColor("green");
    }
    else if (response.status === 401 || response.status === 500 || response.status === 400) {
      setSubmitResult("Failed to create new client");
      setSubmitColor("red");
    } else {
      setSubmitResult("Unknown error occurred. Failed to create new client");
      setSubmitColor("red");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <>
      <Slide direction="right" in={transitionout} timeout={400}>
        <Box
          flexGrow={1}
          marginLeft={"5%"}
          marginRight={"5%"}
          marginTop={10}
          marginBottom={10}
        >
          <h2>Client Dashboard</h2>
          <Divider />
          {/* Search box and button container*/}

          <Box display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
            marginTop={2}
            flexWrap="wrap" // Ensure items wrap on smaller screens
          >
            <FormControl
              style={{ minWidth: 120, flexGrow: 1, maxWidth: 250 }} // Adjust width as needed
              variant="outlined"
            >
              <Select
                value={searchCriterion}
                onChange={handleSearchCriterionChange}
              >
                <MenuItem value="client">Client</MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              options={suggestions}
              loading={Clientloading}
              value={searchTerm}
              onChange={(event, value) => handleSuggestionSelect(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  variant="outlined"
                  fullWidth
                  onChange={handleSearchTermChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        {Clientloading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          resetVisible && (
                            <IconButton onClick={handleReset} size="small">
                              <ClearIcon />
                            </IconButton>
                          )
                        )}
                      </InputAdornment>
                    ),
                  }}
                  style={{ maxWidth: "300px" }}
                />
              )}
              style={{ flexGrow: 2, minWidth: 200 }} // Adjust the minWidth and flexGrow to ensure proper spacing
            />
            <Tooltip
              title="No permission to create a new client"
              arrow
              disableInteractive
              disableHoverListener={perms.aw_permissions.clients_create} // Disable tooltip when button is enabled
            >
              <div style={{ display: 'inline-block' }}> {/* Make the span inline-block to avoid layout issues */}
                <Button
                  variant="contained"
                  color={theme.nextbuttoncolor}
                  onClick={() => handleNewClient()}
                  disabled={!perms.aw_permissions.clients_create}
                  style={{ height: 55, minWidth: 150 }} // Adjust minWidth to ensure button size consistency
                >
                  New Client
                </Button>
              </div>
            </Tooltip>
          </Box>

          <Dialog open={newClientOpenDialog} onClose={handleCloseDialog} PaperProps={{
            style: {
              minWidth: '40%',
              minHeight: '40%',
            },
          }}>
            <DialogTitle variant="h4" align={"center"}>New Client
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </DialogTitle>
            <Divider />
            {activeStep === 0 && (
              // Step 1: Form inputs
              <DialogContent>
                <Typography variant="h5">Enter the Client Details: </Typography>
                <Grid container spacing={2} paddingTop={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="client"
                      label="Client Name"
                      size="small"
                      value={formData.client}
                      onChange={handleChange}
                      error={!!formErrors.clientName}
                      helperText={formErrors.clientName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="contact"
                      label="Contact"
                      size="small"
                      value={formData.contact}
                      onChange={handleChange}
                      error={!!formErrors.contact}
                      helperText={formErrors.contact}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="contact_email"
                      label="Contact Email"
                      size="small"
                      value={formData.contact_email}
                      onChange={handleChange}
                      error={!!formErrors.contactEmail}
                      helperText={formErrors.contactEmail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="contact_phone"
                      variant="outlined"
                      label="Contact Phone"
                      size="small"
                      value={formData.contact_phone}
                      onChange={handleChange}
                      error={!!formErrors.contactPhone}
                      helperText={formErrors.contactPhone}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="comments"
                      variant="outlined"
                      label="Comments"
                      size="small"
                      value={formData.comments}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            )}

            {activeStep === 1 && (
              // Step 2: Review table
              <DialogContent>
                <Typography variant="h5">Review your entries: </Typography>
                <Grid container spacing={2} paddingTop={3}>
                  <Grid item xs={12}>
                    <Typography>Client Name: {reviewData.client}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Contact: {reviewData.contact}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Contact Email: {reviewData.contact_email}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Contact Phone: {reviewData.contact_phone}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Contact Phone: {reviewData.comments}</Typography>
                  </Grid>
                </Grid>
              </DialogContent>
            )}

            {activeStep === 2 && (
              // Step 3: Success or Failed message
              <DialogContent>
                <Typography variant="h5" justifyContent="center">Result: </Typography>
                <Typography padding={5} fontSize={24}>
                  <span style={{ color: submitColor }}>
                    {submitResult}
                  </span>
                </Typography>
              </DialogContent>
            )}
            <DialogActions>
              <Box display="flex" justifyContent="space-between" width="100%" paddingBottom={3} paddingLeft={2} paddingRight={2}>
                {activeStep === 0 && (
                  <><Button variant="contained" color={theme.backbuttoncolor} onClick={handleCloseDialog}>
                    Cancel
                  </Button><Button variant="contained" color={theme.nextbuttoncolor} onClick={handleNext}>
                      Next
                    </Button></>
                )}

                {activeStep === 1 && (
                  <><Button variant="contained" color={theme.backbuttoncolor} onClick={handleBack}>
                    Back
                  </Button><Button variant="contained" color={theme.nextbuttoncolor} onClick={handleSubmit}>
                      Submit
                    </Button></>
                )}

                {activeStep === 2 && (
                  <Box display="flex" justifyContent="center" width="100%" >
                    <Button variant="contained" color={theme.nextbuttoncolor} onClick={handleCloseDialog}>
                      Done
                    </Button>
                  </Box>
                )}
              </Box>
            </DialogActions>
          </Dialog>

          {errorMessage && (
            <Box paddingTop={2} color="red">
              <Alert severity="error">Invalid Client</Alert>
            </Box>
          )}

          <GeneralTable
            data={selectSuggestions ? clients.map((client) => ({
              id: client.id,
              Name: client.client,
              ServicePlan: client.service_plan_id,
              RegistrationDate: client.registration_date,
              Contact: client.contact,
              ContactEmail: client.contact_email,
              ContactPhone: client.contact_phone,
              Comments: client.comments,
              UpdatedAt: client.updated_at,
            })) : originalClients.map((cli) => ({
              id: cli.id,
              Name: cli.client,
              ServicePlan: cli.service_plan_id,
              RegistrationDate: cli.registration_date,
              Contact: cli.contact,
              ContactEmail: cli.contact_email,
              ContactPhone: cli.contact_phone,
              Comments: cli.comments,
              UpdatedAt: cli.updated_at,
            }))}
            columns={columns}
            loading={loading} //passing the loading state
            noDataMessage={"Unable To Fetch Clients List"}
            rowClickCallback={handleRowClick}
            rows={10}
            rowsoptions={[10, 25, 50, 100]}
            rowNewTabClickCallback={handleNewTabRowClick}
          />
        </Box >
      </Slide >
    </>
  );
}
