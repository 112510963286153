import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getUserFromUsername = async (username: string | undefined) => {
	const url = buildBaseUrl()
	if (!username) {
		return;
	}
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(`${url}users/${username}`, {
			headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
			},
		});
		return response.json();
	} catch (err) {
		console.log(err);
	}
};
