import { buildBaseUrl } from "../build-base-url";

export const fetchClientLogin = async (
  username: string,
  password: string
) => {
  const url = buildBaseUrl();

  try {
   
    const response = await fetch(`${url}login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });

    if (!response.ok) {
      // Handle bad request (status code 400)
      const errorMessage = await response;
      return errorMessage;
      
    }
    return response.json();
  } catch (err) {
    console.error("Error logging in:", err);
  }
};
