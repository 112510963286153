import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ServicePlansInterface } from "../../../utils/interfaces/service/service-list-interface";
import DeleteServicePlanDialog from "./delete-service-plan";
import { useNavigate } from "react-router-dom";

interface DeleteServicePlanDialogProps {
  deleteServicePlan: ServicePlansInterface | null;
}

const Actions: React.FC<DeleteServicePlanDialogProps> = ({
  deleteServicePlan,
}) => {
  var { perms, theme } = useContext(AppContext);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  let userNavigate = useNavigate();
  
  const handleDelete = () => {
    setDeleteDialogOpen(true); // Open the delete dialog
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setTimeout(() => userNavigate(`/service`), 400);
  };

  return (
    <>
      <>
        <Card elevation={8} sx={{ borderRadius: 3 }}>
          <CardHeader
            title="Actions"
            action={
              <IconButton aria-label="info">
                <InfoOutlinedIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Divider />
            <Box paddingTop={2}>
              <Stack direction={"column"} spacing={2}>
              <Tooltip
                title="No permission to delete service plan"
                arrow
                disableInteractive
                disableHoverListener={perms.aw_permissions.service_plan_delete} // Disable tooltip when button is enabled
              >
                 <div style={{ display: 'inline-block' }}> {/* Use a div with inline-block to preserve layout */}
                  <Button
                  color={theme.backbuttoncolor}
                  variant="contained"
                  onClick={() => handleDelete()}
                  disabled={!perms.aw_permissions.service_plan_delete}
                    sx={{
                      height: 'auto',       // Allow the height to adjust based on content
                      minWidth: '200px',    // Set a minimum width, but allow it to be responsive
                      width: '100%',        // Make the button take up full width of its container
                      maxWidth: '1200px',    // Set a max width for larger screens
                      padding: '12px 24px', // Add padding to ensure button content is spaced well
                    }}
                  >
                    Delete Service Plan
                  </Button>
                </div>
              </Tooltip>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </>
      <DeleteServicePlanDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        deleteServicePlan={deleteServicePlan}
      />
    </>
  );
};

export default Actions;
