import { fetchAuthSession } from 'aws-amplify/auth';
import {
  User,
  defaultGetUserReturnType,
} from "../../interfaces/users/user-details-interfaces";
import { buildBaseUrl } from "../build-base-url";
import { fetchLatestClientData } from "../clients/get-single-client";

export const getUsers = async (type: string, page: string | null) => {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {};
  // console.log("---JWTTOKEN---");
  // console.log(authData.signInUserSession.accessToken.jwtToken);
  const url = buildBaseUrl();
  let baseurl = `users?`;
  if (type !== null) {
    baseurl = baseurl + `type=${type}`;
  }
  if (page !== null) {
    baseurl = baseurl + `&page=${page}`;
  }
  const response = await fetch(`${url}${baseurl}`, {
    headers: {
      Authorization: `Bearer ${accessToken?.toString()}`,
    },
  });
  var data = await response.json();
  return formatgetUsersData(data);
};

//helps to format above, getUsers
export async function formatgetUsersData(data: defaultGetUserReturnType) {
  let next_page = data.next_page;

  // Fetch client data for unique client IDs
  const uniqueClientIds = Array.from(
    new Set(data.data.map((user: User) => user.client_id)) //Set allows you to store unique values.
  );

  // Initialize an array to store client data
  const clientDataArray: any[] = [];

  // // Loop over unique client IDs and fetch data one by one
  // for (const clientId of uniqueClientIds) {
  //   const clientData = await fetchLatestClientData(clientId);
  //   clientDataArray.push(clientData);
  // }

  // Loop over unique client IDs and fetch data one by one
  for (const clientId of uniqueClientIds) {
    if (clientId) {
      // Fetch client data if client ID is present
      try {
        const clientData = await fetchLatestClientData(clientId);
        clientDataArray.push(clientData);
      } catch (error) {
        // Handle error when fetching client data
        console.error(
          `Error fetching client data for a client ID`,
          error
        );
        // Push a placeholder object with 'unknown' client
        clientDataArray.push({ id: "unknown", client: "Unknown Client" });
      }
    } else {
      // If the client ID is missing, push a placeholder object
      clientDataArray.push({ id: "unknown", client: "Unknown Client" });
    }
  }

  // Convert the array of client data to a dictionary for easy lookup
  const clientDataMap = Object.fromEntries(
    // Object.fromEntries converts an iterable of key-value pairs into an object.
    clientDataArray.map((clientData) => [clientData.id, clientData])
    //The keys of the dictionary are client IDs, and the values are the corresponding client data.
  );

  // // Fetch client data for each user
  // const formattedData = await Promise.all(
  //   data.data.map(async (user: User) => {
  //     const clientData = clientDataMap[user.client_id]; // We use the extracted client_id as a key to look up a value in the clientDataMap object.

  //     // Return the formatted user data with client name
  //     return {
  //       Name: user.username,
  //       UserName: user.user_name,
  //       Email: user.user_email,
  //       UserType: user.user_type,
  //       ClientName: clientData ? clientData.client : "Unknown",
  //       ClientId: user.client_id,
  //     };
  //   })
  // );

  // Format user data with client name
  const formattedData = data.data.map((user: User) => {
    const clientData = clientDataMap[user.client_id] || {}; // Use empty object as fallback

    // Return the formatted user data with client name
    return {
      Name: user.username,
      UserName: user.user_name,
      Email: user.user_email,
      UserType: user.user_type,
      ClientName: clientData.client || "Unknown",
      ClientId: user.client_id,
    };
  });

  return [formattedData, next_page];
}
