import {
  Alert,
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { AppContext } from "../../../utils/themes/theme-context";
import { useContext, useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getDeviceRegistration } from "../../../utils/requests/devices/get-device-registration";
import { DevicesDetails } from "../../../utils/interfaces/vehicles/devices-details-interface";
import { RegistrationDeviceInterface } from "../../../utils/interfaces/devices/registration-device-interface";
import { getDevices } from "../../../utils/requests/devices/get-devices-details";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import { useNavigate } from "react-router";
import LinkIcon from "@mui/icons-material/Link";
import { fetchSingleProductData } from "../../../utils/requests/products/get-single-product";

export default function RegistrationDevice(props: { device: DevicesDetails, registeredclientName: string, unregisteredclientName: string }) {
  var { perms, theme } = useContext(AppContext);
  const [serialNumber, setserialNumber] = useState<string>();
  const [deviceRegistrationData, setDeviceRegistrationData] =
    useState<RegistrationDeviceInterface | null>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [registrationSupport, setRegistrationSupport] = useState(false);
  let Navigate = useNavigate();

  const deviceidget = props.device.id;
  const productID = props.device.product_id;

  useEffect(() => {
    // Fetch the serial number
    const fetchSerialNumber = async () => {
      try {
        const response = await getDevices(deviceidget);
        setserialNumber(response.serial);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSerialNumber(); // Fetch the serial number when the component mounts or when deviceidget changes
  }, [deviceidget]);

  useEffect(() => {
    const fetchProductRegistrationSupport = async () => {
      const respdata = await fetchSingleProductData(productID);
      setRegistrationSupport(respdata.registration_support);
    };

    fetchProductRegistrationSupport();
  }, [productID]);

  useEffect(() => {
    // Fetch device calibration data whenever the serial number changes
    const fetchDeviceRegistrationData = async () => {
      try {
        if (serialNumber) {
          const response = await getDeviceRegistration(serialNumber);

          setDeviceRegistrationData(response);
          setErrorMessage(null); // Clear any previous error message
        }
      } catch (err) {
        setDeviceRegistrationData(null);
      }
    };

    fetchDeviceRegistrationData(); // Fetch device registration data when the serial number changes
  }, [serialNumber, props.registeredclientName, props.unregisteredclientName]);

  function handleClientNameClick(clientId: string | undefined): void {
    setTimeout(() => Navigate(`/clients/${clientId}`), 400);
  }

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Registration"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          {registrationSupport ? (
            <Stack direction={"row"} spacing={3} marginTop={2}>
              <Stack direction={"column"} spacing={2} alignItems={"center"}>
                <Typography style={{ fontWeight: "bold" }}>
                  Registration Status
                </Typography>

                <Divider flexItem orientation="horizontal" />

                {/* Conditionally render CircularProgress while waiting for the response */}
                {deviceRegistrationData === null ? (
                  // If deviceRegistrationData is null, display "No registration data available"
                  <Typography>No registration status available</Typography>
                ) : !deviceRegistrationData ? (
                  <CircularProgress size={20} color="primary" />
                ) : deviceRegistrationData.registration_status ? ( // Conditionally render the icon based on registration_status
                  <CheckCircleIcon
                    fontSize="large"
                    style={{ color: "green" }}
                  /> // Green tick icon for true
                ) : (
                  <ErrorIcon fontSize="large" style={{ color: "red" }} /> // Red error icon for false
                )}
              </Stack>
              <Divider flexItem orientation="vertical" />
              <Stack direction={"column"} spacing={2} alignItems={"center"}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Client Name
                  </Typography>
                  <LinkIcon style={{ marginLeft: "5px" }} />
                </div>
                <Divider flexItem orientation="horizontal" />
                <Box
                  sx={{
                    // backgroundColor: theme.tertiary,
                    borderRadius: "10%",
                    padding: "10px",
                  }}
                >
                  {perms.aw_permissions.clients_read ? (
                    // If the user has permission to view client data
                    deviceRegistrationData && deviceRegistrationData.client_name ? (
                      <Link
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() =>
                          handleClientNameClick(deviceRegistrationData?.client_id)
                        }
                      >
                        <Typography>{deviceRegistrationData!.client_name}</Typography>
                      </Link>
                    ) : (
                      // If client name is not available
                      <Typography>No registered client available</Typography>
                    )
                  ) : (
                    // If the user does not have permission to view client data
                    <Box>
                      <Backdrop
                        open={true}
                        sx={{
                          position: "relative",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Alert
                          variant="filled"
                          severity="warning"
                          style={{ width: "100%" }}
                        >
                          No permission to view client data.
                        </Alert>
                      </Backdrop>
                    </Box>
                  )}
                </Box>

              </Stack>
              <Divider flexItem orientation="vertical" />
              <Stack direction={"column"} spacing={2} alignItems={"center"}>
                <Typography style={{ fontWeight: "bold" }}>
                  Registartion Date
                </Typography>
                <Divider flexItem orientation="horizontal" />
                {deviceRegistrationData?.registration_date ? (
                  <Box
                    sx={{
                      // backgroundColor: theme.tertiary,
                      borderRadius: "10%",
                      padding: "10px",
                    }}
                  >
                    <Typography>
                      {rfcToDateAndTime(
                        deviceRegistrationData.registration_date
                      )}
                    </Typography>
                  </Box>
                ) : (
                  <Typography>No registration date available</Typography>
                )}
              </Stack>
            </Stack>
          ) : (
            <Box>
              <Backdrop
                open={true}
                sx={{
                  position: "relative",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <Alert variant="filled" severity="warning">
                  <Typography>Unsupported Product Feature.</Typography>
                </Alert>
              </Backdrop>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
}
