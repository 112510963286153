//get /products from backend

import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getProducts = async (pageKey?: string) => {
	const url = buildBaseUrl()
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(`${url}products${pageKey ? `?page=${pageKey}` : ''}`, {
			headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
			},
		});
		const data  = await response.json(); //Extracting data array from response
		return data;
	} catch (err) {
		console.log(err);
	}
};