import DetailsTable from "../../../utils/generic-components/Details Table/details-table";
import { DetailTableData } from "../../../utils/interfaces/details-table/details-table-interfaces";
import { Vehicle } from "../../../utils/interfaces/vehicles/vehicles-interfaces";
import { rfcToDate } from "../../../utils/custom-functions/rfc3339-conversions";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { Alert } from "@mui/material";

export interface weirdfix {
  data: Vehicle;
}

export default function Details(props: { vehicle: Vehicle }) {
  var { perms, theme } = useContext(AppContext);
  const clientPageUrl = `/clients/${props.vehicle.data.client_id}`;
  const productPageUrl = `/products/${props.vehicle.data.product_id}`;
  const devicePageUrl = `/device/${props.vehicle.data.device_id}`;

  let DetailTableData: DetailTableData = {
    data: [
      {
        label: "Vehicle Name",
        content: props.vehicle.data.vehicle_name,
        editable: false,
        error: "",
      },
      {
        label: "Client",
        // content: props.vehicle.data.client,
        content: (
          perms.aw_permissions.clients_read ? (
            // Wrap the client name in a Link component to create a clickable link
            <Link to={clientPageUrl}>{props.vehicle.data.client}</Link>
          ) : (<Alert severity="error">No permission to view client data</Alert>)
        ),
        editable: false,
        error: "",
      },
      {
        label: "Vin",
        content: props.vehicle.data.vehicle_vin,
        editable: false,
        error: "",
      },
      {
        label: "Vehicle Registration Date",
        content: rfcToDate(props.vehicle.data.vehicle_registration_date),
        editable: false,
        error: "",
      },
      {
        label: "Product",
        // content: props.vehicle.data.product,
        content: (
          // Wrap the product name in a Link component to create a clickable link
          <Link to={productPageUrl}>{props.vehicle.data.product}</Link>
        ),
        editable: false,
        error: "",
      },
      {
        label: "Device Serial Number",
        content: (
          <Link to={devicePageUrl}>{props.vehicle.data.device_serial}</Link>
        ),
        editable: false,
        error: "",
      },
    ],
  };

  //extend functionality in the future
  const putCallback = (
    data: DetailTableData,
    setAlert: Function,
    setEditingMode: Function,
    setLoadingButton: Function
  ) => {
    console.log("putcallback");
  };

  return (
    <>
      <DetailsTable
        data={DetailTableData}
        putCallback={putCallback}
        editing={false}
        close={false}
      />
    </>
  );
}
