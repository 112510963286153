import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const deletePreconfig = async (clientId: string | undefined, preconfigurationId: string | undefined) => {
	const url = buildBaseUrl()
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        const response = await fetch(`${url}clients/${clientId}/preconfiguration/${preconfigurationId}`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${accessToken?.toString()}`,
				},
			}
		);
		if (response.status === 200) {
			return "success";
		} else {
			let data = await response.json();
			return data.errors;
		}
	} catch (err) {
		console.log(err);
	}
};