import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { VehicleData } from "../../interfaces/vehicles/vehicles-interfaces";

interface GetVehicles {
	Vehicles: VehicleData[];
	Status?: number;
}

export const getVehicles = async (
) : Promise<GetVehicles> => {
	const url = buildBaseUrl()
	let baseurl = `vehicles`;
	const { accessToken } = (await fetchAuthSession()).tokens ?? {};
	const response = await fetch(`${url}${baseurl}`, {
		headers: {
			Authorization: `Bearer ${accessToken?.toString()}`,
		},
	});
	var res = await response.json()
	const data = {
		Vehicles: res.data ? res.data as VehicleData[] : [],
		Status: res.status,
	};
	return data;
};