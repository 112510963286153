import DetailsTable from "../../../../utils/generic-components/Details Table/details-table";
import { DetailTableData } from "../../../../utils/interfaces/details-table/details-table-interfaces";
import { TestLogData } from "../../../../utils/interfaces/devices/test-logs-interface";

export default function TestLogsDetails(props: { testlogdata: TestLogData | null }) {
	let DetailTableData: DetailTableData = {
		data: [
			{
				label: "Test Name",
				content: props.testlogdata?.test_performed?.test_name,
				editable: false,
				error: "",
			},
            {
				label: "Test ID",
				content: props.testlogdata?.test_performed?.test_id,
				editable: false,
				error: "",
			},
            {
				label: "Test Description",
				content: props.testlogdata?.test_performed?.test_description,
				editable: false,
				error: "",
			},
		],
	};

	//extend functionality in the future
	const putCallback = async (
		data: DetailTableData,
		setAlert: Function,
		setEditingMode: Function,
		setLoadingButton: Function
	) => {
		setEditingMode(false);
		setLoadingButton(false);
	};

	return (
		<>
			<DetailsTable
				data={DetailTableData}
				putCallback={putCallback}
				editing={false}
				close={false}
			/>
		</>
	);
}
