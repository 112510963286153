import { useEffect, useState } from "react";
import {
    Divider,
    IconButton,
    LinearProgress,
    Paper,
    TextField,
    Box,
    Typography,
} from "@mui/material";
import { Clear, Schedule, Search } from "@mui/icons-material";
import { withIdentityPoolId } from '@aws/amazon-location-utilities-auth-helper';
import {
    LocationClient, SearchPlaceIndexForTextCommand,
    SearchPlaceIndexForTextCommandInput, SearchPlaceIndexForTextResponse,
    SearchForTextResult, Place
} from "@aws-sdk/client-location";
import { getCountries, getIdentityPoolId, getPlaceIndex, getRegion } from "./geo-config";

const LOCAL_STORAGE_KEY = "awGeoSearchHistory";
const indexName = getPlaceIndex()[0];

var client: LocationClient

const indexSetup = async () => {
    const authHelper = await withIdentityPoolId(getIdentityPoolId());

    client = new LocationClient({
        region: getRegion(),
        ...authHelper.getLocationClientConfig() // sets up the Location client to use the Cognito pool defined above
    });
}

export interface Address {
    title: string;
    address: string;
    coordinates: { lat: number; lng: number };
}

const filterAddresses = (addresses: Address[], searchText: string) => {
    const searchLower = searchText.toLowerCase();
    return addresses.filter(
        (address) =>
            address.title.toLowerCase().includes(searchLower) ||
            address.address.toLowerCase().includes(searchLower)
    );
};

const geoSearch = async (searchText: string): Promise<Address[]> => {
    if (!client)
        await indexSetup();

    var addresses: Address[] = [];

    const input: SearchPlaceIndexForTextCommandInput = {
        IndexName: indexName,
        Text: searchText,
        FilterCountries: getCountries(),
        MaxResults: 9
    };
    const command = new SearchPlaceIndexForTextCommand(input);
    await client.send(command).then((response: SearchPlaceIndexForTextResponse) => {
        if (response.Results && response.Results.length > 0) {
            addresses = response.Results.map((result: SearchForTextResult) => {
                if (!result.Place) {
                    return {
                        title: "",
                        address: "",
                        coordinates: { lat: 0, lng: 0 }
                    }
                }
                return {
                    title: formatTitle(result.Place),
                    address: formatAddress(result.Place),
                    coordinates: {
                        lat: result.Place.Geometry?.Point ? result.Place.Geometry.Point[1] : 0,
                        lng: result.Place.Geometry?.Point ? result.Place.Geometry.Point[0] : 0,
                    },
                };
            });
        }
    }
    ).catch(
        (error) => {
            console.error("error:", error);
            addresses = [];
        }
    );

    return addresses;
};

const formatTitle = (result: Place): string => {
    var { Label, AddressNumber, Street } = result;

    if (!Label) {
        Label = "";
    }

    if (!AddressNumber) {
        AddressNumber = "";
    }

    if (!Street) {
        Street = "";
    }

    // Find the position of the address number in the label
    const addressIndex = Label.indexOf(AddressNumber);

    // If the address number exists in the label, extract all text before the address number
    let formattedTitle = Label;
    if (addressIndex !== -1) {
        formattedTitle = Label.substring(0, addressIndex).trim();
    }

    // Remove any trailing commas and spaces from the formatted title
    formattedTitle = formattedTitle.replace(/,\s*$/, "");

    // If no name is detected, return the formatted address using addressNumber and street
    if (!formattedTitle) {
        return `${AddressNumber} ${Street}`.trim();
    }

    return formattedTitle;
};

const formatAddress = (result: Place): string => {
    var { Label, AddressNumber, Street, Municipality, SubRegion, Region, Country, PostalCode } = result;

    if (!Label) {
        Label = "";
    }

    if (!AddressNumber) {
        AddressNumber = "";
    }

    if (!Street) {
        Street = "";
    }

    if (!Municipality) {
        Municipality = "";
    }

    if (!SubRegion) {
        SubRegion = "";
    }

    if (!Region) {
        Region = "";
    }

    if (!Country) {
        Country = "";
    }

    if (!PostalCode) {
        PostalCode = "";
    }

    // Find the position of the address number in the label
    const addressIndex = Label.indexOf(AddressNumber);

    // If the address number exists in the label, start the string from the address number
    if (addressIndex !== -1) {
        Label = Label.substring(addressIndex);
    }

    // Abbreviate the state if it matches a full name in the map
    const stateAbbreviation = stateAbbreviations[Region] || Region;

    // Format the address components
    return `${AddressNumber} ${Street} ${Municipality}, ${stateAbbreviation} ${PostalCode}, ${Country}`;
};

const ResultItem = (props: {
    address: Address;
    leadingIcon?: React.ReactNode;
    trailingIcon?: React.ReactNode;
    onClose?: () => void;
    onClick: () => void;
}) => {
    const { address, onClick, onClose } = props;

    return (
        <Box
            className="result-item" // Add a class to help with event handling
            onMouseDown={() => {
                onClick();
            }}
            sx={{
                padding: 1,
                cursor: "pointer",
                "&:hover": {
                    backgroundColor: "#f0f0f0",
                },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                gap: 1,
            }}
        >
            {props.leadingIcon}
            <Typography variant="body2" fontWeight="bold">
                {address.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {address.address}
            </Typography>
            {props.trailingIcon}
            {onClose && (
                <ClearIcon
                    className="clear-hostory-item"
                    onClick={() => {
                        onClose();
                    }}
                />
            )}
        </Box>
    );
};

const SearchResults = (props: {
    searchText: string;
    results: Address[];
    history: Address[];
    onResultClick: (result: Address) => void;
    onClearHistoryItem: (item: Address) => void;
}) => {
    if (props.results.length === 0 && props.history.length === 0) {
        return (
            <>
                <Divider />
                <Box
                    sx={{
                        padding: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="body1" color="textSecondary">
                        No results found
                    </Typography>
                </Box>
            </>
        );
    }

    return (
        <>
            <Divider />
            {props.history?.map((item, index) => (
                <ResultItem
                    key={index}
                    address={item}
                    leadingIcon={
                        <Schedule sx={{ fontSize: "18px", color: "gray" }} />
                    }
                    onClick={() => {
                        props.onResultClick(item);
                    }}
                    onClose={() => {
                        props.onClearHistoryItem(item);
                    }}
                />
            ))}
            {props.results
                ?.filter(
                    (result) =>
                        !props.history.some(
                            (item) => item.address === result.address
                        )
                )
                .map((result, index) => (
                    <ResultItem
                        key={index}
                        address={result}
                        onClick={() => {
                            props.onResultClick(result);
                        }}
                    />
                ))}
        </>
    );
};

const ClearIcon = ({
    onClick,
    className,
}: {
    onClick: () => void;
    className: string;
}) => (
    <IconButton
        onMouseDown={(event) => {
            event.stopPropagation();
            onClick();
        }}
        size="small"
        className={className}
        sx={{ fontSize: "18px", marginLeft: "auto" }}
    >
        <Clear fontSize="inherit" />
    </IconButton>
);

const GeoSearchBox = ({
    onResultClickCallback,
}: {
    onResultClickCallback: (result: Address) => void;
}) => {
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState<string>("");
    const [searchResults, setSearchResults] = useState<Address[]>([]);
    const [history, setHistory] = useState<Address[]>([]); // State to track history
    const [showResults, setShowResults] = useState(false);

    const filteredHistory = filterAddresses(history, searchText);
    // const filteredResults = filterAddresses(searchResults, searchText);

    // Load history from localStorage on component mount
    useEffect(() => {
        const storedHistory = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storedHistory) {
            setHistory(JSON.parse(storedHistory));
        }
    }, []);

    // Save history to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(history));
    }, [history]);

    // Function to handle changes in the search text
    const handleSearch = async () => {
        if (searchText.trim() === "") {
            setSearchResults([]);
            return;
        }

        if (searchText.length < 5) {
            setShowResults(false);
            return;
        }

        setLoading(true);
        setSearchResults(await geoSearch(searchText));
        setShowResults(true);
        setLoading(false);
    };

    const handleResultClick = (result: Address) => {
        setHistory((prevHistory) => {
            return [
                result,
                ...prevHistory.filter(
                    (item) => item.address !== result.address
                ),
            ];
        });
        setSearchResults(
            searchResults.filter((item) => item.title !== result.title)
        );
        setShowResults(false);
        setSearchText(result.title);
        onResultClickCallback(result);
    };

    const clearHistoryItem = (item: Address) => {
        setHistory((prevHistory) => {
            return prevHistory.filter(
                (historyItem) => historyItem.title !== item.title
            );
        });
        setShowResults(true);
    };

    const handleBlur = (event: React.FocusEvent) => {
        // Check if the blur event is related to a click on the result item
        const relatedTarget = event.relatedTarget as HTMLElement;
        if (relatedTarget && relatedTarget.classList.contains("result-item")) {
            return;
        }
        // Don't hide results if it was a remove item from history click
        // if (relatedTarget && relatedTarget.classList.contains('clear-history-item')) {
        //   return;
        // }
        setShowResults(false);
    };

    const handleFocus = () => {
        // if (searchText.length > 4)
        setShowResults(true);
    };

    // Debounce the search function to avoid making too many requests
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSearch();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    return (
        <Paper
            square={false}
            id="geo-search-box"
            sx={{
                borderRadius: 6,
                overflow: "hidden",
            }}
        >
            <TextField
                fullWidth
                size="small"
                variant="standard"
                placeholder="Search for an address"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onBlur={handleBlur}
                onFocus={handleFocus}
                InputProps={{
                    startAdornment: <Search />,
                    endAdornment: searchText.length > 0 && (
                        <ClearIcon
                            onClick={() => setSearchText("")}
                            className="clear-search"
                        />
                    ),
                    disableUnderline: true,
                }}
                sx={{
                    marginX: 1,
                    marginY: 1,
                    paddingRight: 2,
                }}
            />
            {loading && <LinearProgress color="primary" />}
            {showResults && searchText.length === 0 && history.length > 0 && (
                <SearchResults
                    results={[]}
                    history={history}
                    onResultClick={handleResultClick}
                    onClearHistoryItem={clearHistoryItem}
                    searchText={searchText}
                />
            )}
            {showResults && searchText.length > 4 && (
                <SearchResults
                    results={searchResults}
                    history={filteredHistory}
                    onResultClick={handleResultClick}
                    onClearHistoryItem={clearHistoryItem}
                    searchText={searchText}
                />
            )}
        </Paper>
    );
};

const stateAbbreviations: { [key: string]: string } = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
};

export default GeoSearchBox;