import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const checkClientAvailability = async (client: string) => {
	const url = buildBaseUrl()
	if (!client) {
		console.log("no input");
		return;
	}
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(
			`${url}clients/available?client=${client}`,
			{
				headers: {
					Authorization: `Bearer ${accessToken?.toString()}`,
				},
			}
		);
		return response.json();
	} catch (err) {
		console.log(err);
	}
};
