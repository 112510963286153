import { SetStateAction, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { CircularProgress } from "@mui/material";
import {
	Authenticator,
	Heading,
	View,
	useAuthenticator,
	useTheme,
	Button,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { signIn, signOut, getCurrentUser, AuthUser } from "aws-amplify/auth";
import {
	Box,
	TextField,
	Stack,
	Card,
	Typography,
	InputAdornment,
	IconButton,
} from "@mui/material";
import CustomSignUp from "./signuppage";
import BackgroundWrapper from "./utils/generic-components/Background Wrapper/backgroundwrapper";
import QrScanPage from "./pages/qr-scan/main";
// import { UsernamePasswordOpts } from "@aws-amplify/auth/lib-esm/types";
import NewPasswordForm from "./new-password-submit";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { fetchClientLogin } from "./utils/requests/login/fetch-login";
import { fetchClientLoginChallenge } from "./utils/requests/login/fetch-login-challenge";
import ForgotPasswordForm from "./forgotpasswordpage";

//TODO: move this into a configuration page
const QrScanRoute = "qrscan";

const activeRegion = process.env.REACT_APP_DEFAULT_REGION || "us-east-2";
const stage = process.env.REACT_APP_USER_BRANCH || "dev";

if (activeRegion === "us-east-2") {
	if (stage === "prod" || stage === "uat") {
		Amplify.configure({
			Auth: {
				Cognito: {
					userPoolId: "us-east-2_m1UIkOXWm",
					userPoolClientId: "2k2674qn5obqg49vf374n9tmqd",
				},
			},
		});
	} else {
		Amplify.configure({
			Auth: {
				Cognito: {
					userPoolId: "us-east-2_m1UIkOXWm",
					userPoolClientId: "2k2674qn5obqg49vf374n9tmqd",
				},
			},
		});
	}
} else {
	if (stage === "prod" || stage === "uat") {
		// Default to 'us-west-2'
		Amplify.configure({
			Auth: {
				Cognito: {
					userPoolId: "us-west-2_jliSHBBmU",
					userPoolClientId: "3c8osis67b336as66dbihqd9vt",
					identityPoolId:
						"us-west-2:60fc4d97-01b3-437c-bd8a-e92314ecc13a",
				},
			},
		});
	} else {
		// Default to 'us-west-2'
		Amplify.configure({
			Auth: {
				Cognito: {
					// region: "us-west-2",
					userPoolId: "us-west-2_7JFcbR1Wn",
					userPoolClientId: "4hmj3q4tos2nu044af8uvcvlkk",
					identityPoolId:
						"us-west-2:fc1f6efb-7e85-401c-8650-3e74f453cb23",
				},
			},
		});
	}
}

function getCurrentRouteWithoutBaseAndQuery() {
	const currentPath = window.location.pathname;
	const parts = currentPath.split("/").filter((part) => part !== "");

	// The first element in the 'parts' array will be the route without the base
	const routeWithoutBase = `${parts.join("/")}`;

	// Remove any query strings from the route
	const routeWithoutQuery = routeWithoutBase.split("?")[0];

	return routeWithoutQuery.toLowerCase();
}

interface NewPasswordRequiredData {
	username: string;
	newPassword: string;
	challenge: string;
	token: string;
}

function AppRouter() {
	const [user, setUser] = useState<AuthUser | boolean>(false);
	const [userSigningIn, setUserSigningIn] = useState(false);

	// Add state for the current page
	// Current values include:
	// "login" - The login page
	// "customSignUp" - The custom sign up page
	// "qrscan" - The QR scan page
	const [currentPage, setCurrentPage] = useState("login");
	const [snackbarMessage, setSnackbarMessage] = useState("");

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [newPasswordRequiredData, setNewPasswordRequiredData] =
		useState<NewPasswordRequiredData>({
			username: "",
			newPassword: "",
			challenge: "",
			token: "",
		});
	const [showPassword, setShowPassword] = useState(false);
	const [newPassword, setNewPassword] = useState("");
	// const [usernamepasswordError, setUsernamePasswordError] = useState("");
	const [usernamepasswordError, setUsernamePasswordError] = useState<{
		username: string;
		password: string;
	}>({ username: "", password: "" });

	//run just once on app load
	useEffect(() => {
		// Get the current route without the base
		const currentRoute = getCurrentRouteWithoutBaseAndQuery();

		// If the current route is "login" or "customSignUp", set the current page to that route
		if (currentRoute === QrScanRoute) {
			setCurrentPage(currentRoute);
		}
	}, []);

	const handleSignOut = async () => {
		try {
			await signOut();
			setUser(false);
		} catch (error) {}
	};

	useEffect(() => {
		const checkUser = async () => {
			try {
				let user = await getCurrentUser();
				setUser(user);
			} catch (error) {
				setUser(false);
			}
		};

		checkUser();
	}, []);

	const handleGoToCustomSignUp = () => {
		setCurrentPage("customSignUp"); // Set the current page to "customSignUp"
	};

	const handleGoToLogin = () => {
		setCurrentPage("login"); // Set the current page to "login"
	};

	const handleForgotPasswordClick = () => {
		setCurrentPage("ForgotPassword"); // Set current page to "ForgotPassword"
	};

	const components = {
		Header() {
			const { tokens } = useTheme();
			return (
				<View textAlign="center" padding={tokens.space.large}>
					{/* <img alt="Air-Weigh logo" src={AirWeighLogo} width={450} height={100}/> */}
				</View>
			);
		},

		SignIn: {
			Header() {
				const { tokens } = useTheme();

				return (
					<Heading
						padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
						level={3}
						style={{ textAlign: "center" }}
					>
						<img
							alt="Air-Weigh logo"
							src="/images/color-Air-Weigh_sans_subtext-web.png"
							width={300}
							height={80}
						/>
						{/* do not use import for public folder images use the URL directly as above */}
					</Heading>
				);
			},
			Footer() {
				// const { toResetPassword } = useAuthenticator();

				// const handleForgotPasswordClick = () => {
				//   console.log("Forgot Password button clicked"); // Add console log
				//   toResetPassword(); // Call toResetPassword function
				// };

				return (
					<View textAlign="right">
						<Button
							fontWeight="normal"
							onClick={() => {
								setCurrentPage("ForgotPassword");
							}}
							size="small"
							variation="link"
							marginRight={20}
						>
							Forgot Password
						</Button>

						<div className="separator-container">
							<hr className="separator" />
							<div className="separator-text">OR</div>
							<hr className="separator" />
						</div>

						<View textAlign="center">
							<img
								alt="Air-Weigh logo"
								src="/images/loadinglogo.png"
								width={80}
								height={80}
							/>
						</View>

						<View textAlign="center">No Account?</View>
						<View textAlign="center">
							<Button
								fontWeight="normal"
								onClick={() => {
									setCurrentPage("customSignUp");
								}}
								size="small"
								variation="link"
							>
								Create an account
							</Button>
						</View>
					</View>
				);
			},
		},
	};

	const handleSignIn = async () => {
		try {
			setUsernamePasswordError({ username: "", password: "" }); // Reset error
			setUserSigningIn(true);

			// Check if username or password is empty
			let usernameError = !username ? "Username is required" : "";
			let passwordError = !password ? "Password is required" : "";

			// Set individual error messages for each field
			setUsernamePasswordError({
				username: usernameError,
				password: passwordError,
			});

			// If either field is empty, exit the function
			if (usernameError || passwordError) {
				return;
			}

			// Call the API to check user status
			const data = await fetchClientLogin(username, password);

			if (data.status === "CONFIRMED") {
				// User is confirmed and enabled, sign them in
				await signIn({ username: username, password: password });
				setUser(true);
				// Auto-refresh the page
				window.location.reload();
			} else if (data.status === "NEW_PASSWORD_REQUIRED") {
				// User needs to set a new password
				setNewPasswordRequiredData({
					username,
					newPassword,
					challenge: data.status,
					token: data.tokens.token,
				});
				setCurrentPage("newPasswordForm");
			} else if (data.enabled === false) {
				// User account is disabled
				setCurrentPage("accountDisabled");
			} else {
				// Handle other cases (incorrect username or password)
				setUsernamePasswordError({
					username: "Incorrect username or password",
					password: "Incorrect username or password",
				});
			}
			setUserSigningIn(false);
		} catch (error) {
			console.error("Error signing in:", error);
			// Set error state for unexpected errors
			setUsernamePasswordError({
				username: "An unexpected error occurred",
				password: "An unexpected error occurred",
			});
			setUserSigningIn(false);
		}
	};

	const handleTogglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const handleNewPasswordSubmit = async (newPassword: string) => {
		try {
			// Use the data stored from the previous response
			const { username, challenge, token } = newPasswordRequiredData;

			// Call the API to submit new password
			const data = await fetchClientLoginChallenge(
				username,
				newPassword,
				challenge,
				token
			);

			if (data.status === "CONFIRMED") {
				// New password confirmed, sign in
				await signIn({ username: username, password: password });
				setUser(true);
			}

			// Update snackbar message based on API response
			setSnackbarMessage(data.message || "Password updated successfully");
		} catch (error) {
			console.error("Error submitting new password:", error);
			setSnackbarMessage("An error occurred while updating the password");
		}
	};

	if (user) {
		// User is signed in
		return <App signOut={handleSignOut} user={user} />;
	} else if (currentPage === "login") {
		return (
			<Box>
				<BackgroundWrapper>
					<div>
						<div className="card-container">
							<Card
								className="sign-in-card"
								style={{
									maxWidth: "500px",
									width: "90%",
									margin: "0px auto",
								}}
							>
								<div className="sign-in-card-inner">
									<Stack spacing={3}>
										<View textAlign="center">
											<Heading
												level={3}
												style={{ textAlign: "center" }}
											>
												<img
													alt="Air-Weigh logo"
													src="/images/color-Air-Weigh_sans_subtext-web.png"
													width={300}
													height={80}
												/>
											</Heading>
										</View>

										<form
											onSubmit={(e) => {
												e.preventDefault();
												handleSignIn();
											}}
										>
											{/* Input fields for username and password */}
											<Stack
												direction="column"
												spacing={1}
												justifyContent="center"
											>
												<Typography
													variant="subtitle1"
													gutterBottom
												>
													Username
												</Typography>
												<TextField
													type="text"
													placeholder="Enter your username"
													value={username}
													onChange={(e) =>
														setUsername(
															e.target.value
														)
													}
													fullWidth // Make the text field full width
													error={
														!!usernamepasswordError.username
													} // Set error state for the username field
													helperText={
														usernamepasswordError.username ||
														" "
													} // Display the error message for the username field
												/>

												<Typography
													variant="subtitle1"
													gutterBottom
												>
													Password
												</Typography>
												<TextField
													type={
														showPassword
															? "text"
															: "password"
													}
													placeholder="Enter your password"
													value={password}
													onChange={(e) =>
														setPassword(
															e.target.value
														)
													}
													fullWidth // Make the text field full width
													error={
														!!usernamepasswordError.password
													} // Set error state for the username field
													helperText={
														usernamepasswordError.password ||
														" "
													} // Display the error message for the username field
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	onClick={
																		handleTogglePasswordVisibility
																	}
																	edge="end"
																>
																	{showPassword ? (
																		<Visibility />
																	) : (
																		<VisibilityOff />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}}
												/>

												<View
													textAlign="center"
													width="100%"
													marginTop={20}
												>
													{userSigningIn ? (
														<CircularProgress
															size={24}
														/>
													) : (
														<Button
															className="navigation-button"
															type="submit"
															isFullWidth
															disabled={
																userSigningIn
															}
														>
															Sign In
														</Button>
													)}
												</View>
											</Stack>

											{/* Footer */}
											<View
												textAlign="right"
												marginTop={20}
											>
												<Button
													fontWeight="normal"
													onClick={
														handleForgotPasswordClick
													}
													size="small"
													variation="link"
													marginRight={20}
												>
													Forgot Password
												</Button>

												<div className="separator-container">
													<hr className="separator" />
													<div className="separator-text">
														OR
													</div>
													<hr className="separator" />
												</div>

												<View textAlign="center">
													<img
														alt="Air-Weigh logo"
														src="/images/loadinglogo.png"
														width={80}
														height={80}
													/>
												</View>

												<View textAlign="center">
													No Account?
												</View>
												<View textAlign="center">
													<Button
														fontWeight="normal"
														onClick={
															handleGoToCustomSignUp
														}
														size="small"
														variation="link"
													>
														Create an account
													</Button>
												</View>
											</View>
										</form>
									</Stack>
								</div>
							</Card>
						</div>
					</div>
				</BackgroundWrapper>
			</Box>
		);
	} else if (currentPage === "customSignUp") {
		// User is not signed in
		return (
			<BackgroundWrapper>
				<Box>
					<CustomSignUp
						goToLogin={handleGoToLogin}
						setCurrentPage={setCurrentPage}
					/>
				</Box>
			</BackgroundWrapper>
		);
	} else if (currentPage === "ForgotPassword") {
		return (
			<BackgroundWrapper>
				<Box>
					<ForgotPasswordForm setCurrentPage={setCurrentPage} />
				</Box>
			</BackgroundWrapper>
		);
	} else if (currentPage === QrScanRoute) {
		return (
			// <BackgroundWrapper>
			<QrScanPage />
			// </BackgroundWrapper>
		);
	} else if (currentPage === "newPasswordForm") {
		// Render the new password form
		return (
			<BackgroundWrapper>
				<Box>
					<NewPasswordForm
						onSubmit={(newPassword) =>
							handleNewPasswordSubmit(newPassword)
						}
					/>
				</Box>
			</BackgroundWrapper>
		);
	} else if (currentPage === "accountDisabled") {
		// Render the account disabled message
		return (
			<BackgroundWrapper>
				<Box>
					<div>
						Your account has been disabled. Please contact support.
					</div>
				</Box>
			</BackgroundWrapper>
		);
	} else {
		return null;
	}
}

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(<AppRouter />);

reportWebVitals();
