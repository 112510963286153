import ReactDOM from 'react-dom/client'; // Import createRoot from React 18
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import { RoundedIconButton } from '../../themes/rounded-icon-button';

export class FullscreenControl implements maplibregl.IControl {
    private container: HTMLElement;
    private root: ReactDOM.Root | null = null; // Store a reference to the React root
    private onFullscreenToggle: (value: boolean) => void;
    private isFullscreen: boolean;

    constructor(options: FullscreenControlOptions) {
        this.container = document.createElement('div');
        this.container.className = 'maplibregl-ctrl';
        this.onFullscreenToggle = options.onFullscreenToggle;
        this.isFullscreen = options.startFullScreen ?? false;
    }

    onAdd(map: maplibregl.Map): HTMLElement {
        // Create a React root and render the button component
        this.root = ReactDOM.createRoot(this.container);
        this._setIcon();

        return this.container;
    }

    onRemove() {
        setTimeout(() => { }, 1000); // This is a workaround to avoid an unmount race condition
        if (this.root) {
            setTimeout(() => {
                this.root!.unmount();
                if (this.container.parentNode) {
                    this.container.parentNode.removeChild(this.container);
                }
            }, 0);
        } else {
            if (this.container.parentNode) {
                this.container.parentNode.removeChild(this.container);
            }
        }
    }

    isFullscreenEnabled = () => {
        return this.isFullscreen;
    }

    private _toggleFullscreen = () => {
        this.isFullscreen = !this.isFullscreen;
        this._setIcon();
        this.onFullscreenToggle(this.isFullscreen);
    };

    private _setIcon = () => {
        if (!this.root) return;

        if (this.isFullscreen) {
            this.root.render(
                <RoundedIconButton
                    onClick={this._toggleFullscreen}
                    variant="contained"
                    color="error"
                    // Override default Maplibre control button style
                    className='custom-map-control-button-back'
                    id="close-modal-control"
                >
                    <FullscreenExit />
                </RoundedIconButton>
            );
        } else {
            this.root.render(
            <RoundedIconButton
                onClick={this._toggleFullscreen}
                variant="contained"
                color="primary"
                // Override default Maplibre control button style
                className='custom-map-control-button'
                id="open-modal-control"
            >
                <Fullscreen />
            </RoundedIconButton>)
        }
    }
}

export type FullscreenControlOptions = {
    /**
     * Callback function to toggle fullscreen mode.
     */
    onFullscreenToggle: (value: boolean) => void;

    /**
     * If `true` the map will start in fullscreen mode.
     */
    startFullScreen?: boolean;
};