import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AppContext } from "../../../utils/themes/theme-context";
import { useContext, useEffect, useRef, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SingleProductFirmwareGET } from "../../../utils/interfaces/products/single-product-details-interface";
import { fetchSingleProductFirmwareData } from "../../../utils/requests/products/get-singleproduct-firmware-data";
import { useNavigate, useParams } from "react-router-dom";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { fetchSingleFirmwareData } from "../../../utils/requests/products/get-single-firmware-data";
import { ProductListData } from "../../../utils/interfaces/products/products-list-interface";
import CloseIcon from "@mui/icons-material/Close";
import { fetchLatestClientData } from "../../../utils/requests/clients/get-single-client";
import { Client } from "../../../utils/interfaces/clients/clients-list-interface";
import { getSingleServicePlans } from "../../../utils/requests/products/get-single-service-plan";
import { getClientDetails } from "../../../utils/requests/clients/get-clients-list";
import { ServicePlan } from "../../../utils/interfaces/products/service-plan-inetrface";
import { getServicePlans } from "../../../utils/requests/products/get-service-plans";
import { putUpdatedFirmwareEdits } from "../../../utils/requests/products/put-edited-client-serviceplan";
import { PutEditsClientsServicePlans } from "../../../utils/interfaces/products/put-clients-serviceplan-edits-interface";

let columns: Columns = {
  Version: {
    type: "text",
    display: "Version",
    data: "firmware_version",
    sort: "Version",
  },
  Date: {
    type: "time",
    display: "Date",
    data: "date_time",
    sort: "Date",
  },
  Link: {
    type: "link",
    display: "Link",
    sort: "Version",
  },
};

export default function RecentFirmwareVersions(props: {
  products: ProductListData;
  isFirmareUpdated: boolean;
}) {
  var { perms, theme } = useContext(AppContext);
  const { id, version } = useParams<{ id?: string; version?: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [singleProductFirmwareData, setsingleProductFirmwareData] = useState<
    SingleProductFirmwareGET[]
  >([]);
  const [selectedFirmware, setSelectedFirmware] =
    useState<SingleProductFirmwareGET | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const [clientsData, setClientsData] = useState<Client[]>([]);
  let userNavigate = useNavigate();
  const [activeTab, setActiveTab] = useState("firmwareDetailsTable"); // Default active tab is firmwareDetailsTable
  const [servicePlanNames, setServicePlanNames] = useState<ServicePlan[]>([]);
  const [allserviceplans, setAllServicePlans] = useState<ServicePlan[]>([]);
  const [selectedServicePlans, setSelectedServicePlans] = useState<ServicePlan[]>(servicePlanNames); // Start with the initially selected clients
  const [hasFirmwarePermission, setHasFirmwarePermission] = useState<boolean>(true);
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClients, setSelectedClients] = useState<Client[]>(clientsData); // Start with the initially selected clients
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [editing, setEditing] = useState(false); // To toggle between normal table and edit mode
  const [allcllientsloading, setAllClientsLoading] = useState(true);
  const [editingServicePlan, setEditingServicePlan] = useState(false); // To toggle between normal table and edit mode
  const [allserviceplansloading, setAllServicePlansLoading] = useState(true);
  const [editingFirmware, setEditingFirmware] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState(selectedFirmware?.release_notes || "");
  const [comments, setComments] = useState(selectedFirmware?.comments || "");
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [successColor, setSuccessColor] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);  // New state for tracking save operation
  const [isDone, setIsDone] = useState(false);

  // Fetch data from the API on component mount
  useEffect(() => {
    // Check permissions when the dialog opens
    if (perms.aw_permissions.firmware_read) {
      setHasFirmwarePermission(true);
    } else {
      setHasFirmwarePermission(false);
      return; // Stop further execution if no permission
    }
    if (id || props.isFirmareUpdated) {
      setLoading(true);
      fetchSingleProductFirmwareData(id)
        .then((data) => {
          setsingleProductFirmwareData(data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
          setLoading(false);
        });
    }
  }, [id, props.isFirmareUpdated]); //Refresh the table when new firmware is added

  const fetchClientData = async (clientIds: string[]) => {
    try {
      // Map over the IDs and fetch each service plan, expecting it to return a ServicePlan object
      const clientDetailsPromise = clientIds.map(async (id) => {
        try {
          const response: Client = await fetchLatestClientData(id); // Type the response directly as ServicePlan
          return response; // Return the response as is
        } catch (error) {
          console.error(`Error fetching service plan for ID ${id}:`, error);
        }
      });

      // Wait for all promises to resolve and set the state
      const clientdata = await Promise.all(clientDetailsPromise);
      // Filter out undefined values (in case some fetches failed)
      const validClients = clientdata.filter((client): client is Client => client !== undefined);

      // Set the valid clients to state
      setClientsData(validClients);
      setSelectedClients(validClients); // <-- Update with valid clients
    } catch (error) {
      console.error("Error fetching service plans:", error);
    }
  };

  const fetchServicePlanNames = async (servicePlanIds: string[]) => {
    try {
      // Map over the IDs and fetch each service plan, expecting it to return a ServicePlan object
      const planDetailsPromise = servicePlanIds.map(async (id) => {
        try {
          const response: ServicePlan = await getSingleServicePlans(id); // Type the response directly as ServicePlan
          return response; // Return the response as is
        } catch (error) {
          console.error(`Error fetching service plan for ID ${id}:`, error);
          // Return a placeholder ServicePlan object in case of error
          return {
            id: id,
            name: "N/A",
            fault_alerts: false,
            firmware_updates: false,
            heartbeat_alerts: false,
            comments: "Error fetching data",
            creation_date: "",
            updated_at: "",
          } as ServicePlan;
        }
      });

      // Wait for all promises to resolve and set the state
      const servicePlans = await Promise.all(planDetailsPromise);
      setServicePlanNames(servicePlans);
      setSelectedServicePlans(servicePlans); // <-- Update selectedServicePlans
    } catch (error) {
      console.error("Error fetching service plans:", error);
    }
  };

  // useEffect(() => {
  //   fetchClientData();
  // }, [selectedFirmware]);

  useEffect(() => {
    setAllClientsLoading(true);
    //Fetch Client list data and update the state
    getClientDetails()
      .then((response: { data: Client[] }) => {
        //the response object data has a property type Client[]
        //Successful response
        const data = response.data; //data property is assigened to variable data
        setClients(data);
        setAllClientsLoading(false);
      })
      .catch((error) => {
        setAllClientsLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          if (error.response.status === 401) {
            // 401 Unauthorized error
            setErrorMessage("Parameter mismatch");
          } else if (error.response.status === 500) {
            // 500 Internal Server Error
            setErrorMessage("Internal Server Error");
          } else {
            // other errors
            setErrorMessage(`Error: ${error.message}`);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setErrorMessage("No response received");
        } else {
          // Something happened in setting up the request that triggered an error
          setErrorMessage(`Error: ${error.message}`);
        }
      });
  }, []);

  useEffect(() => {
    setAllServicePlansLoading(true);
    //Fetch service plan data and update the state
    getServicePlans()
      .then((response: { data: ServicePlan[] }) => {
        //the response object data has a property type ServicePlan[]
        //Successful response
        const data = response.data; //data property is assigened to variable data
        setAllServicePlans(data);
        setAllServicePlansLoading(false);
      })
      .catch((error) => {
        setAllServicePlansLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          if (error.response.status === 401) {
            setErrorMessage("Parameter mismatch");
          } else if (error.response.status === 500) {
            setErrorMessage("Internal Server Error");
          } else {
            setErrorMessage(`Error: ${error.message}`);
          }
        } else if (error.request) {
          setErrorMessage("No response received");
        } else {
          setErrorMessage(`Error: ${error.message}`);
        }
      });
  }, []);

  const handleRowClick = (selectedClient: Client) => {
    setTimeout(() => userNavigate(`/clients/${selectedClient}`), 400);
  };

  const handleServicePlanRowClick = () => {
  };

  const handleDialogOpen = async (product_id: any, firmware_version: any) => {
    try {
      setDialogLoading(true); // Set the loading state when opening the dialog
      const data = await fetchSingleFirmwareData(product_id, firmware_version);
      setSelectedFirmware(data.firmware);
      // fetchClientData();
      fetchClientData(data.firmware.client_restrictions || []);
      // Fetch and set service plan names
      const clientIds = data.firmware.client_restrictions || [];
      if (clientIds.length > 0) {
        fetchClientData(clientIds);
      } else {
        setClientsData([]); // Clear if no service plans
        setSelectedClients([]);
      }

      fetchServicePlanNames(data.firmware.service_plan_restrictions || []);
      // Fetch and set service plan names
      const servicePlanIds = data.firmware.service_plan_restrictions || [];
      if (servicePlanIds.length > 0) {
        fetchServicePlanNames(servicePlanIds);
      } else {
        setServicePlanNames([]); // Clear if no service plans
        setSelectedServicePlans([]);
      }


      setDialogLoading(false); // Clear the loading state when data is fetched
      setDialogOpen(true);
    } catch (error) {
      console.error("Error fetching firmware data:", error);
    }
  };

  const handleDialogClose = () => {
    setSuccessMessage("");   // Clear the success message
    setErrorAlert("");      // Clear the error alert
    setIsSaving(false);     // Reset the loading state
    setIsDone(false);       // Reset the 'done' button state
    setSelectedFirmware(null);
    setDialogOpen(false);
    setErrorAlert(null);
    setActiveTab("firmwareDetailsTable")
    setEditing(false)
    setEditingServicePlan(false)
  };

  // Calculate the SHA-256 hash of the file content using window.crypto.subtle
  async function calculateSHA256Hash(buffer: ArrayBuffer): Promise<string> {
    try {
      const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
      const hashArray = new Uint8Array(hashBuffer);
      const hashHex = Array.from(hashArray)
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");

      return hashHex;
    } catch (error) {
      console.error("Error calculating SHA-256 hash:", error);
      throw error;
    }
  }

  const handleDownloadFile = async () => {
    if (selectedFirmware) {
      try {
        // Convert Base64 encoded string to ArrayBuffer
        const binaryString = window.atob(selectedFirmware.file); //converts the Base64 string into a binary string
        const arrayBuffer = new ArrayBuffer(binaryString.length); //An ArrayBuffer is created with a length equal to the length of the decoded binary string.
        const uint8Array = new Uint8Array(arrayBuffer); //Uint8Array is created from the ArrayBuffer

        // iterates over each character in the binary string and converts its Unicode value to a byte value, storing it in the Uint8Array.
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }

        // Calculate the SHA-256 hash of the downloading content
        const downloadedHash = await calculateSHA256Hash(arrayBuffer);

        if (downloadedHash === selectedFirmware.file_hash) {
          // Create a Blob with the ArrayBuffer data
          const blob = new Blob([arrayBuffer], {
            type: "application/octet-stream",
          }); //The type is set to 'application/octet-stream' to indicate that this is a generic binary data.

          // Create a download URL for the Blob
          const blobUrl = URL.createObjectURL(blob); //. This URL points to the data in the Blob.

          // Create a temporary link element
          const link = document.createElement("a"); //An anchor (<a>) element is created dynamically.
          link.href = blobUrl; //Its href attribute is set to the URL of the Blob
          link.download = `${props.products.name}_${selectedFirmware.firmware_version}.gbl`;

          // Programmatically click the link to initiate the download
          link.click();

          // Clean up
          URL.revokeObjectURL(blobUrl);
          setErrorAlert(null); // Clear error alert on successful download
        } else {
          setErrorAlert("File corruption detected, Please download again");
        }
      } catch (error) {
        setErrorAlert("Error handling file download");
      }
    }
  };

  let columns2: Columns = {
    Name: {
      type: "link",
      display: "Client Name",
      data: "name",
      sort: "Name",
    },
  };

  let columns3: Columns = {
    Name: {
      type: "link",
      display: "Service Plan",
      data: "name",
      sort: "Name",
    },
  };

  const linkContent = dialogLoading ? (
    <CircularProgress size={24} color="primary" />
  ) : (
    "GO"
  );

  const loadingDisplay = () => {
    return (
      <>
        <LinearProgress />
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
        </Stack>
      </>
    );
    return null;
  };

  const transformedData = singleProductFirmwareData.map((productFirmware) => ({
    Version: productFirmware.firmware_version,
    Date: productFirmware.date_time,
    Link: linkContent,
    Product_Id: productFirmware.product_id,
    Release_Notes: productFirmware.release_notes,
    Comments: productFirmware.comments,
    FileData: productFirmware.file,
    File_Hash: productFirmware.file_hash,
  }));

  const handleFirmwareEditClick = () => {
    setReleaseNotes(selectedFirmware?.release_notes || "");
    setComments(selectedFirmware?.comments || "");
    setEditingFirmware(true);
  }

  // Handle changes to Release Notes field
  const handleReleaseNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReleaseNotes(event.target.value);
  };

  // Handle changes to Comments field
  const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };

  const handleDoneClickFirmware = () => {
    if (selectedFirmware) {
      // Only update if selectedFirmware is not null
      setSelectedFirmware({
        ...selectedFirmware,
        release_notes: releaseNotes,
        comments: comments,
      });
    }

    // Exit edit mode
    setEditingFirmware(false);
  };

  // Cancel edit
  const handleCancelEditFirmware = () => {
    // Reset to original data if canceling
    setReleaseNotes(selectedFirmware?.release_notes || "");
    setComments(selectedFirmware?.comments || "");
    setEditingFirmware(false);
  };

  const handleEditClick = () => {
    setEditing(true); // Enable editing mode
  };

  const handleCancelClientChanges = () => {
    setSelectedClients(clientsData); // Reset to original selection
    setEditing(false);
  };

  // Handle "Done" button click to save changes
  const handleDoneClientChanges = () => {
    // Update the clientsData with the selected clients (simple replacement)
    setClientsData(selectedClients); // Replace entire clientsData state with selectedClients

    // Exit edit mode
    setEditing(false);
  };

  const handleEditServicePlanClick = () => {
    setEditingServicePlan(true); // Enable editing mode
  };

  const handleCancelServicePlanChanges = () => {
    setServicePlanNames(servicePlanNames); // Reset to original selection
    setEditingServicePlan(false);
  };

  const handleDoneServicePlanChanges = () => {
    setServicePlanNames(selectedServicePlans);
    setEditingServicePlan(false);
    // Save selected service plans here
  };

  // Handling the multiselect logic
  const handleMultiSelectCallback = (selectedRows: string[]) => {
    //Function takes an array of selectedRows, which contains the IDs of the selected rows.
    const selectedClients = clients.filter((client) =>
      selectedRows.includes(client.id)
    ); //The function uses the filter method to create a new array selectedClients by filtering the clients array based on the selected row IDs
    setSelectedClients(selectedClients); // Store selected clients' data
  };

  const handleMultiSelectServicePlanCallback = (selectedServicePlanRows: string[]) => {
    const selectedServicePlans = allserviceplans.filter((service) =>
      selectedServicePlanRows.includes(service.id)
    );

    setSelectedServicePlans(selectedServicePlans);
  };

  // Handle the saving of all changes
  const handleSaveAllEdits = async () => {
    setIsSaving(true);
    setErrorAlert('');  // Reset any previous error alert
    setSuccessMessage('');  // Reset any previous success message
    setSuccessColor('');  // Reset success color
    setIsDone(false); // Hide "Done" button initially
    try {
      const body: PutEditsClientsServicePlans = {
        client_restrictions: selectedClients.map(client => client.id),
        service_plan_restrictions: selectedServicePlans.map(plan => plan.id),
        release_notes: releaseNotes || "",
        comments: comments || "",
        updated_at: selectedFirmware?.updated_at || "",
      };

      const response = await putUpdatedFirmwareEdits(selectedFirmware?.product_id!, selectedFirmware?.firmware_version!, body);

      // Check if the response indicates success
      if (response) {
        console.log("success: ", response)
        setSuccessMessage('Firmware details successfully edited');
        setSuccessColor('green');
        setErrorAlert('');  // Clear any error alert if present
      } else {
        console.log("Failed")
        setErrorAlert('Failed to save changes');
        setSuccessMessage('');  // Clear any success message
        setSuccessColor('');  // Reset success color
      }
    } catch (error) {
      console.log("Failed")
      setErrorAlert('An error occurred while saving.');
      setSuccessMessage('');
      setSuccessColor('');
    } finally {
      setIsSaving(false);
      setIsDone(true);
    }
  };

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Recent Firmware Versions"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          {hasFirmwarePermission ? (
            <>
              {loading ? ( // Show skeleton when loading is true
                loadingDisplay()
              ) : singleProductFirmwareData.length === 0 ? (
                <Alert variant="filled" severity="warning">
                  <AlertTitle>No Recent Firmware Versions to fetch</AlertTitle>
                </Alert>
              ) : (
                <GeneralTable
                  data={transformedData}
                  columns={columns}
                  loading={loading}
                  noDataMessage={"No Recent Firmware Versions to fetch "}
                  rowClickCallback={(rowData: any) =>
                    handleDialogOpen(rowData.Product_Id, rowData.Version)
                  }
                  rows={10}
                  rowsoptions={[10, 25, 50, 100]}
                />
              )}

              <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle variant="h4" align="center">
                  Firmware Details
                  <IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    sx={{
                      position: "absolute",
                      right: 2,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ margin: "16px" }}>
                  {/* Show Loading or Success/Error Message */}
                  {isSaving ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {successMessage && (
                        <Alert severity="success" style={{ width: "100%", textAlign: "center" }}>
                          {successMessage}
                        </Alert>
                      )}
                      {errorAlert && (
                        <Alert severity="error" style={{ width: "100%", textAlign: "center" }}>
                          {errorAlert}
                        </Alert>
                      )}
                    </div>
                  )}

                  {/* The message has been shown, so we hide the rest of the content */}
                  {!successMessage && !errorAlert && (
                    <>
                      <Box display="flex" justifyContent="space-between">
                        <Button
                          variant="contained"
                          style={{
                            marginRight: "10px",
                            background:
                              activeTab === "firmwareDetailsTable"
                                ? theme.selectedbuttoncolor
                                : theme.nonactivebuttoncolor,
                          }}
                          onClick={() => setActiveTab("firmwareDetailsTable")}
                        >
                          Firmware Details Table
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            marginRight: "10px",
                            background:
                              activeTab === "clientRestrictions"
                                ? theme.selectedbuttoncolor
                                : theme.nonactivebuttoncolor,
                          }}
                          onClick={() => setActiveTab("clientRestrictions")}
                        >
                          Client Restrictions
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            marginRight: "10px",
                            background:
                              activeTab === "servicePlanRestrictions"
                                ? theme.selectedbuttoncolor
                                : theme.nonactivebuttoncolor,
                          }}
                          onClick={() => setActiveTab("servicePlanRestrictions")}
                        >
                          Service Plan Restrictions
                        </Button>
                      </Box>
                      <br />
                      <Divider />
                      {dialogLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100px",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      ) : (
                        selectedFirmware && (
                          <>
                            {activeTab === "firmwareDetailsTable" && (
                              <>
                                <Typography variant="h6">Firmware Details</Typography>
                                {!editingFirmware ? (
                                  <>
                                    <Table>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>Version:</TableCell>
                                          <TableCell>
                                            {selectedFirmware.firmware_version}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Date:</TableCell>
                                          <TableCell>
                                            {" "}
                                            {new Date(
                                              selectedFirmware.date_time
                                            ).toLocaleString()}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Release Notes:</TableCell>
                                          <TableCell>
                                            {selectedFirmware.release_notes}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Comments:</TableCell>
                                          <TableCell>
                                            {selectedFirmware.comments}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                    <Box textAlign="center" marginTop={4}>
                                      {errorAlert && (
                                        <Alert severity="error">{errorAlert}</Alert>
                                      )}
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" marginTop={2}>
                                      <Button
                                        variant="contained"
                                        onClick={handleDownloadFile}
                                      >
                                        Download File
                                      </Button>
                                      <Button variant="contained" onClick={handleFirmwareEditClick}>Edit Firmware Details</Button>
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Table>
                                      <TableBody>
                                        {/* Non-editable fields (Version and Date) as TextField with disabled */}
                                        <TableRow>
                                          <TableCell>Version:</TableCell>
                                          <TableCell>
                                            <TextField
                                              value={selectedFirmware.firmware_version}
                                              disabled
                                              fullWidth
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Date:</TableCell>
                                          <TableCell>
                                            <TextField
                                              value={new Date(selectedFirmware.date_time).toLocaleString()}
                                              disabled
                                              fullWidth
                                            />
                                          </TableCell>
                                        </TableRow>
                                        {/* Editable fields (Release Notes and Comments) */}
                                        <TableRow>
                                          <TableCell>Release Notes:</TableCell>
                                          <TableCell>
                                            <TextField
                                              label="Release Notes"
                                              fullWidth
                                              value={releaseNotes}
                                              onChange={handleReleaseNotesChange}
                                              style={{ marginBottom: "5px" }}
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Comments:</TableCell>
                                          <TableCell>
                                            <TextField
                                              label="Comments"
                                              fullWidth
                                              value={comments}
                                              onChange={handleCommentsChange}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                    <Box display="flex" justifyContent="space-between" marginTop={2}>
                                      <Button onClick={handleCancelEditFirmware} variant="contained" color={theme.backbuttoncolor}>
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={handleDoneClickFirmware}
                                        variant="contained"
                                        color={theme.nextbuttoncolor}
                                      >
                                        Done
                                      </Button>
                                    </Box>
                                  </>
                                )}
                              </>
                            )}
                            {activeTab === "clientRestrictions" && (
                              perms.aw_permissions.clients_read ? (
                                <>
                                  {!editing ? (
                                    // Normal Table View
                                    <>
                                      <Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
                                        Client List
                                      </Typography>
                                      <GeneralTable
                                        data={clientsData.map((clientData) => ({
                                          Id: clientData.id,
                                          Name: clientData.client,
                                        }))}
                                        columns={columns2}
                                        noDataMessage={"Unable to fetch Clients"}
                                        rowClickCallback={(rowData: any) => handleRowClick(rowData.Id)}
                                        rows={10}
                                        rowsoptions={[10, 25, 50, 100]}
                                      />

                                      <Box textAlign="center" marginTop={2}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={handleEditClick}
                                          disabled={allcllientsloading} // Disable button while loading
                                          startIcon={allcllientsloading && <CircularProgress size={20} />} // Show spinner when loading
                                        >
                                          Edit Client Data
                                        </Button>
                                      </Box>
                                    </>
                                  ) : (
                                    // Multiselect Table View (when in edit mode)
                                    <>
                                      <Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
                                        Select Clients
                                      </Typography>

                                      <GeneralTable
                                        data={[
                                          // Add previously selected clients at the top
                                          ...clientsData.map((client) => ({
                                            id: client.id,
                                            Name: client.client,
                                            isSelected: true, // Mark as selected
                                          })),
                                          // Add available clients (those not yet selected)
                                          ...clients
                                            .filter((client) => !clientsData.some((selectedClient) => selectedClient.id === client.id))
                                            .map((client) => ({
                                              id: client.id,
                                              Name: client.client,
                                              isSelected: false, // Mark as unselected
                                            })),
                                        ]}
                                        columns={columns2}
                                        multiselect={true}
                                        multiselectCallback={handleMultiSelectCallback}
                                        selectedRows={selectedClients.map((client) => client.id)}
                                        noDataMessage={"Unable to fetch Clients"}
                                        rows={10}
                                        rowsoptions={[10, 25, 50, 100]}
                                      />

                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        marginTop={2}
                                      >
                                        <Button
                                          variant="contained"
                                          color={theme.backbuttoncolor}
                                          onClick={handleCancelClientChanges} // Cancel and return to normal view
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="contained"
                                          color={theme.nextbuttoncolor}
                                          onClick={handleDoneClientChanges} // Save selected clients
                                        >
                                          Done
                                        </Button>
                                      </Box>
                                    </>
                                  )}
                                </>

                              ) : (
                                // If permission to read client data is denied, show a permission error
                                <Alert variant="filled" severity="warning" style={{ width: "100%" }}>
                                  No permission to view client data.
                                </Alert>
                              )
                            )}

                            {activeTab === "servicePlanRestrictions" && (
                              <>
                                {!editingServicePlan ? (
                                  // Normal Table View
                                  <>
                                    <Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
                                      Service Plan List
                                    </Typography>
                                    <GeneralTable
                                      data={servicePlanNames.map((serviceplan) => ({
                                        id: serviceplan.id,
                                        Name: serviceplan.name,
                                      }))}
                                      columns={columns3}
                                      noDataMessage={"No Service Plans Available"}
                                      rowClickCallback={(rowData: any) =>
                                        handleServicePlanRowClick()
                                      }
                                      rows={10}
                                      rowsoptions={[10, 25, 50, 100]}
                                    />
                                    <Box textAlign="center" marginTop={2}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleEditServicePlanClick}
                                        disabled={allserviceplansloading} // Disable button while loading
                                        startIcon={allserviceplansloading && <CircularProgress size={20} />} // Show spinner when loading
                                      >
                                        Edit Service Plan
                                      </Button>
                                    </Box>
                                  </>
                                ) : (
                                  // Multiselect Table View (when in edit mode)
                                  <>
                                    <Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
                                      Select Service Plans
                                    </Typography>

                                    <GeneralTable
                                      data={[
                                        // Add previously selected service plans at the top
                                        ...servicePlanNames.map((serviceplan) => ({
                                          id: serviceplan.id,
                                          Name: serviceplan.name,
                                          isSelected: true, // Mark as selected
                                        })),
                                        // Add available clients (those not yet selected)
                                        ...allserviceplans
                                          .filter((service) => !servicePlanNames.some((selectedServicePlans) => selectedServicePlans.id === service.id))
                                          .map((service) => ({
                                            id: service.id,
                                            Name: service.name,
                                            isSelected: false, // Mark as unselected
                                          })),
                                      ]}
                                      columns={columns3}
                                      multiselect={true}
                                      multiselectCallback={handleMultiSelectServicePlanCallback}
                                      selectedRows={selectedServicePlans.map((service) => service.id)}
                                      noDataMessage={"Unable to fetch Service Plans"}
                                      rows={10}
                                      rowsoptions={[10, 25, 50, 100]}
                                    />

                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      marginTop={2}
                                    >
                                      <Button
                                        variant="contained"
                                        color={theme.backbuttoncolor}
                                        onClick={handleCancelServicePlanChanges} // Cancel and return to normal view
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color={theme.nextbuttoncolor}
                                        onClick={handleDoneServicePlanChanges} // Save selected clients
                                      >
                                        Done
                                      </Button>
                                    </Box>
                                  </>
                                )}
                              </>
                            )}
                            <Divider style={{ marginTop: "20px" }} />
                            <Card elevation={8} sx={{ borderRadius: 3, marginTop: "10px" }}>
                              <Box textAlign="center" marginTop={4} marginBottom={4}>
                                <Typography style={{ marginBottom: "10px" }} >All changes from the tabs will be saved once you click 'Save All Edits'.</Typography>
                                <Button
                                  variant="contained"
                                  color={theme.nextbuttoncolor}
                                  onClick={handleSaveAllEdits}
                                >
                                  Save All Edits
                                </Button>
                              </Box>
                            </Card>
                          </>
                        )
                      )}
                    </>
                  )}

                  {/* Done Button */}
                  {isDone && (
                    <Box textAlign="center" marginTop={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setIsDone(false); // Hide the Done button
                          handleDialogClose(); // Close the dialog
                        }}
                      >
                        Done
                      </Button>
                    </Box>
                  )}

                </DialogContent>
              </Dialog>
            </>
          ) : (<>
            <Box>
              <Backdrop
                open={true}
                sx={{
                  position: "relative",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <Alert
                  variant="filled"
                  severity="warning"
                  style={{ width: "100%" }}
                >
                  No permission to view firmware data.
                </Alert>
              </Backdrop>
            </Box>
          </>)}
        </CardContent>
      </Card>
    </>
  );
}
