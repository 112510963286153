import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getAllDeviceDiagnostics = async (deviceid: string | undefined) => {
    const url = buildBaseUrl()
    if (!deviceid) {
        return;
    }
    try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        const response = await fetch(`${url}device/${deviceid}/diagnostics`, {
            headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
            },
        });

        const data = await response.json();
        return data;
       
    } catch (err) {
        console.log("error from getAllDeviceDiagnostics error", err);
    }
};