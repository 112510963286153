import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Skeleton,
  Slide,
  TextField,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Columns } from "../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../utils/generic-components/table/table";
import { useNavigate } from "react-router-dom";
import { getDevicesDetails } from "../../utils/requests/devices/get-devices";
import { Device, DeviceData } from "../../utils/interfaces/devices/devices-interface";
import {
  ProductListData,
  getEmptyProduct,
} from "../../utils/interfaces/products/products-list-interface";
import { fetchSingleProductData } from "../../utils/requests/products/get-single-product";
import {
  VehicleData,
  getEmptyVehicle,
} from "../../utils/interfaces/vehicles/vehicles-interfaces";
import { getOneVehicleByVehicleID } from "../../utils/requests/vehicles-routes/get-one-vehicle";
import {
  Client,
  getEmptyClient,
} from "../../utils/interfaces/clients/clients-list-interface";
import {
  fetchLatestClientData,
} from "../../utils/requests/clients/get-single-client";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DeviceFirmwareVersion,
  getEmptyFirmwareVersion,
} from "../../utils/interfaces/devices/device-firmware-version";
import { getDeviceFirmwareVersion } from "../../utils/requests/devices/get-firmware-version";
import { getDeviceSearch } from "../../utils/requests/devices/get-device-search";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from '@mui/material/Autocomplete';
import { getClientSearch } from "../../utils/requests/clients/get-clients-search";
import { getClientDetails } from "../../utils/requests/clients/get-clients-list";
import { getProductSearch } from "../../utils/requests/products/get-product-search";
import { getProducts } from "../../utils/requests/products/get-products";
import React from "react";
import NewDevice from "./new-device/new-device";
import { AppContext } from "../../utils/themes/theme-context";
import LoadMoreButton from "../../utils/generic-components/Load More Button/loadmore";

export default function DevicesList() {
  var { perms, theme } = useContext(AppContext);
  const [devices, setDevices] = useState<Device[]>([]);
  const [products, setProducts] = useState<Record<string, ProductListData>>({});
  const [clients, setClients] = useState<Record<string, Client>>({});
  const [vehicles, setVehicles] = useState<Record<string, VehicleData>>({});
  const [firmwareVersion, setFirmwareVersion] = useState<
    Record<string, DeviceFirmwareVersion>
  >({});
  const [loading, setLoading] = useState<boolean>(true); //initial stage of loading is true
  const [Clientloading, setClientLoading] = useState<boolean>(false);
  const [selectSuggestions, setSelectSuggestions] = useState<boolean>(false);
  const [transitionout, setTransitionOut] = useState<boolean>(true);
  let userNavigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string | null>(""); // State for search term
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]); // State for filtered clients
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [additionalDataPromises, setAdditionalDataPromises] = useState<
    Promise<any>[]
  >([]); // Track promises for additional data
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [isLoadingVehicles, setIsLoadingVehicles] = useState(true);
  const [isLoadingFirmwareVersion, setIsLoadingFirmwareVersion] =
    useState(true);
  const [searchCriterion, setSearchCriterion] = useState(perms.aw_permissions.clients_read ? "client_id" : "product_id"); // Set default based on permission
  const [resetVisible, setResetVisible] = useState(false); // Define resetVisible state
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [AllClientNames, setAllClientNames] = useState<string[]>([]);
  const [AllProductNames, setAllProductNames] = useState<string[]>([]);
  const [searchResponse, setSearchResponse] = useState<any[]>([]);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(false); //state that indicates whether there are additional pages of data available to fetch.
  const [hasMoreSearch, setHasMoreSearch] = useState<boolean>(false); //state that indicates whether there are additional pages of data available to fetch.
  const [searching, setSearching] = useState<boolean>(false); // To track if a search is active
  const nextPageKeyRef = useRef<string | null>(null); //holds the token for the next page of data.
  const nextPageKeySearchRef = useRef<string | null>(null); //holds the token for the next page of data.

  let columns: Columns = {
    Serial: {
      type: "link",
      display: "Serial Number",
      data: "serial",
      sort: "Serial",
    },
    ClientID: {
      type: "custom",
      display: "Client Name",
      data: "client_id",
      sort: "ClientID",
      customSort: true,  // Custom sort logic indicator
    },
    ProductID: {
      type: "custom",
      display: "Product Name",
      data: "product_id",
      sort: "ProductID",
    },
    VehicleID: {
      type: "custom",
      display: "Vehicle Name",
      data: "vehicle_id",
      sort: "VehicleID",
    },
    FirmwareID: {
      type: "text",
      display: "Firmware Version",
      data: "firmware_id",
      sort: "FirmwareID",
    },
    InstallationDate: {
      type: "time",
      display: "Installation Date",
      data: "installation_date",
      sort: "InstallationDate",
    },
    RegistrationDate: {
      type: "time",
      display: "Registration Date",
      data: "registration_date",
      sort: "RegistrationDate",
    },
    UpdatedAt: {
      type: "time",
      display: "Updated At",
      data: "updated_at",
      sort: "UpdatedAt",
    },
  };

  //----------------------Fetch Devices ----------------------------------------------

  // Function to fetch devices with optional pageKey for pagination
  const fetchDevices = async (pageKey?: string) => {
    try {
      setLoading(true);
      const response = await getDevicesDetails(pageKey);
      const newDevices = response.data as Device[];

      // Update state with new devices
      setDevices((prevDevices) => [...prevDevices, ...newDevices]);
      setFilteredDevices((prevDevices) => [...prevDevices, ...newDevices]);

      //If the response contains a next_page field, hasMore is set to true and nextPageKey is updated with the new page token.
      //If next_page is null, hasMore is set to false.
      // Update pagination state
      nextPageKeyRef.current = response.next_page || null;
      setHasMore(!!response.next_page);
      setErrorMessage('');
    } catch (error: any) {
      setErrorMessage(error.response?.status === 401 ? "Parameter mismatch" :
        error.response?.status === 500 ? "Internal Server Error" :
          `Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch on component mount
  useEffect(() => {
    fetchDevices();
  }, []);

  const handleLoadMore = () => {

    if (searching) {
      if (nextPageKeySearchRef.current) {
        handleSearch(nextPageKeySearchRef.current); // Load more search results
      }
    } else {
      if (nextPageKeyRef.current) {
        fetchDevices(nextPageKeyRef.current); // Load more general devices
      }
    }
  };

  //------------------------------------ Fetch client data----------------------------------------
  useEffect(() => {
    if (devices.length === 0) return;

    setIsLoadingClients(true);
    const clientIDs: Record<string, Client> = {};

    devices.forEach(device => {
      if (device.client_id) {
        clientIDs[device.client_id] = getEmptyClient();
      }
    });

    const promises = Object.keys(clientIDs).map(id => fetchLatestClientData(id));

    Promise.allSettled(promises)
      .then(results => {
        const updatedClients: Record<string, Client> = { ...clients };
        results.forEach(result => {
          if (result.status === "fulfilled") {
            updatedClients[result.value.id] = result.value;
          }
        });
        setClients(updatedClients);
      })
      .catch(error => console.error("Client fetch error:", error))
      .finally(() => setIsLoadingClients(false));
  }, [devices]);

  // Fetch vehicle data
  useEffect(() => {
    if (devices.length === 0) return;

    setIsLoadingVehicles(true);
    const vehicleIDs: Record<string, VehicleData> = {};

    devices.forEach(device => {
      if (device.vehicle_id) {
        vehicleIDs[device.vehicle_id] = getEmptyVehicle();
      }
    });

    const promises = Object.keys(vehicleIDs).map(id => getOneVehicleByVehicleID(id));

    Promise.allSettled(promises)
      .then(results => {
        const updatedVehicles: Record<string, VehicleData> = { ...vehicles };
        results.forEach(result => {
          if (result.status === "fulfilled") {
            updatedVehicles[result.value.vehicle_id] = result.value;
          }
        });
        setVehicles(updatedVehicles);
      })
      .catch(error => console.error("Vehicle fetch error:", error))
      .finally(() => setIsLoadingVehicles(false));
  }, [devices]);

  //--------------------------------------- Fetch product data--------------------------------------
  useEffect(() => {
    if (devices.length === 0) return;

    setIsLoadingProducts(true);
    const productIDs: Record<string, ProductListData> = {};

    devices.forEach(device => {
      if (device.product_id) {
        productIDs[device.product_id] = getEmptyProduct();
      }
    });

    const promises = Object.keys(productIDs).map(id => fetchSingleProductData(id));

    Promise.allSettled(promises)
      .then(results => {
        const updatedProducts: Record<string, ProductListData> = { ...products };
        results.forEach(result => {
          if (result.status === "fulfilled") {
            updatedProducts[result.value.id] = result.value;
          }
        });
        setProducts(updatedProducts);
      })
      .catch(error => console.error("Product fetch error:", error))
      .finally(() => setIsLoadingProducts(false));
  }, [devices]);

  //-------------------------- Fetch firmware version data----------------------------
  useEffect(() => {
    if (devices.length === 0) return;

    setIsLoadingFirmwareVersion(true);
    const firmwareIDs: Record<string, DeviceFirmwareVersion> = {};

    devices.forEach(device => {
      if (device.firmware_id) {
        firmwareIDs[device.firmware_id] = getEmptyFirmwareVersion();
      }
    });

    const promises = Object.keys(firmwareIDs).map(id => getDeviceFirmwareVersion(id));

    Promise.allSettled(promises)
      .then(results => {
        const updatedFirmware: Record<string, DeviceFirmwareVersion> = { ...firmwareVersion };
        results.forEach(result => {
          if (result.status === "fulfilled") {
            updatedFirmware[result.value.id] = result.value;
          }
        });
        setFirmwareVersion(updatedFirmware);
      })
      .catch(error => console.error("Firmware fetch error:", error))
      .finally(() => setIsLoadingFirmwareVersion(false));
  }, [devices]);

  //----------------------SEARCH----------------------------------------------

  const handleSearchCriterionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const newCriterion = event.target.value as string;
    setSearchCriterion(newCriterion);
    setSuggestions([]);
    setSearchTerm("");
  };

  const fetchSuggestions = async (searchCriterion: string, query: string) => {
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(async () => {
      try {
        setClientLoading(true); // Set loading true when fetching suggestions
        let searchResponse;
        if (searchCriterion === "client_id") {
          // If there's a query, fetch suggestions based on the query
          searchResponse = await getClientSearch("client", query);
          setSearchResponse(searchResponse.data);
          const clientNames = searchResponse.data.map((client: { client: string }) => client.client);
          setSuggestions(clientNames);
        }
        else if (searchCriterion === "product_id") {
          setClientLoading(true);
          searchResponse = await getProductSearch("name", query);
          setSearchResponse(searchResponse.data);
          const productNames = searchResponse.data.map((product: { name: string; }) => product.name);
          setSuggestions(productNames);
        }
        else {
          setSuggestions([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      } finally {
        setClientLoading(false); // Set loading false when done
      }
    }, 1000);
  };

  useEffect(() => {
    if (searchCriterion === "client_id") {
      if (!searchTerm || searchTerm.trim() === "") {
        setClientLoading(true);
        getClientDetails()
          .then((allClientsResponse) => {
            const allClientNames = allClientsResponse.data.map((client: { client: string }) => client.client);
            setAllClientNames(allClientNames);
            setSuggestions(allClientNames);
            setClientLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              if (error.response.status === 401) {
                // 401 Unauthorized error
                setErrorMessage("Parameter mismatch");
              } else if (error.response.status === 500) {
                // 500 Internal Server Error
                setErrorMessage("Internal Server Error");
              } else {
                // other errors
                setErrorMessage(`Error: ${error.message}`);
              }
            } else if (error.request) {
              // The request was made but no response was received
              setErrorMessage("No response received");
            } else {
              // Something happened in setting up the request that triggered an error
              setErrorMessage(`Error: ${error.message}`);
            }
          });
      }
    } else if (searchCriterion === "product_id") {
      if (!searchTerm || searchTerm.trim() === "") {
        setClientLoading(true);
        getProducts()
          .then((allProductsResponse) => {
            const allProductNames = allProductsResponse.data.map((product: { name: string }) => product.name);
            setAllProductNames(allProductNames);
            setSuggestions(allProductNames);
            setClientLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              if (error.response.status === 401) {
                // 401 Unauthorized error
                setErrorMessage("Parameter mismatch");
              } else if (error.response.status === 500) {
                // 500 Internal Server Error
                setErrorMessage("Internal Server Error");
              } else {
                // other errors
                setErrorMessage(`Error: ${error.message}`);
              }
            } else if (error.request) {
              // The request was made but no response was received
              setErrorMessage("No response received");
            } else {
              // Something happened in setting up the request that triggered an error
              setErrorMessage(`Error: ${error.message}`);
            }
          });
      }
    } else {
      setSuggestions([]); // Clear suggestions when criterion is not `client_id` or search term is not empty
    }
  }, [searchCriterion, searchTerm, devices]);

  const handleSearchTermChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value.trim();

    // setSearchTerm(newSearchTerm); // Update search term state
    setResetVisible(newSearchTerm !== ""); // Show reset button if there's a search term

    if (searchCriterion === "client_id") {
      if (newSearchTerm === "") {
        // it means the user is actively typing 

        setSearchTerm("");
        setSelectSuggestions(false);
        setResetVisible(false);
      } else {
        // it indicates that the user has deleted the search term or hasn't typed anything yet
        // we don't want to immediately trigger a search request because the user may continue typing shortly. 
        // Instead, we want to wait for a brief pause in typing before initiating the search.
        // If search term is empty, reset suggestions based on current criterion
        setSearchTerm(newSearchTerm);
        setResetVisible(true);
        fetchSuggestions(searchCriterion, newSearchTerm);
        if (searchCriterion === "client_id") {
          setSuggestions(AllClientNames); // Set suggestions to all client names
        } else {
          setSuggestions([]); // Clear suggestions for other criteria
        }


        if (selectSuggestions && suggestions.includes(newSearchTerm)) {
          setSelectSuggestions(true); // Set selectSuggestions to true if a suggestion is selected
        } else {
          setSelectSuggestions(false); // Set selectSuggestions to false when search term changes
        }
      }

      // Update the Autocomplete value to reflect the current search term
      setSearchTerm(newSearchTerm);
    } else if (searchCriterion === "product_id") {
      if (newSearchTerm === "") {
        setSearchTerm("");
        setSelectSuggestions(false);
        setResetVisible(false);
      } else {
        setSuggestions([])
        setSearchTerm(newSearchTerm);
        setResetVisible(true);
        fetchSuggestions(searchCriterion, newSearchTerm);
        if (searchCriterion === "product_id") {
          setSuggestions(AllProductNames); // Set suggestions to all product names
        } else {
          setSuggestions([]); // Clear suggestions for other criteria
        }
        if (selectSuggestions && suggestions.includes(newSearchTerm)) {
          setSelectSuggestions(true); // Set selectSuggestions to true if a suggestion is selected
        } else {
          setSelectSuggestions(false); // Set selectSuggestions to false when search term changes
        }
      }

      // Update the Autocomplete value to reflect the current search term
      setSearchTerm(newSearchTerm);
    } else {
      setSearchTerm(newSearchTerm);

      if (newSearchTerm.trim() === "") {
        // Reset button is hidden when the search term is empty
        setResetVisible(false);
      } else {
        // Show the Reset button when there is something typed
        setResetVisible(true);
      }
    }
  };

  const handleSuggestionSelect = async (selectedItem: string | null) => {
    setSearchTerm(selectedItem);
    setSelectSuggestions(true);
    setResetVisible(true); // Show the reset button when a suggestion is selected

    if (selectedItem) {
      try {
        setLoading(true);

        if (searchCriterion === "client_id") {
          const ClientsearchResponse = await getClientSearch("client", selectedItem);

          if (ClientsearchResponse) {
            const clientId = ClientsearchResponse.data[0].id; // Extract the client ID
            const deviceclientsearchResponse = await getDeviceSearch(searchCriterion, clientId);
            setFilteredDevices(deviceclientsearchResponse.data);
          } else {
            console.error("Selected client not found in search response.");
          }
        } else if (searchCriterion === "product_id") {
          const ProductsearchResponse = await getProductSearch("name", selectedItem);

          if (ProductsearchResponse) {
            const productId = ProductsearchResponse.data[0].id; // Extract the product ID
            const deviceproductsearchResponse = await getDeviceSearch(searchCriterion, productId);
            setFilteredDevices(deviceproductsearchResponse.data);
          } else {
            console.error("Selected product not found in search response.");
          }
        } else {
          console.error("Invalid search criterion:", searchCriterion);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching devices:", error);
        setFilteredDevices(devices);
        setLoading(false);
      }
    }
  };

  const handleReset = () => {
    // Clear the search term and restore the original table data
    setSearchTerm("");
    setSuggestions(AllClientNames);
    setDevices(devices); // Restore the original data
    setResetVisible(false); // Hide the Reset button
    setFilteredDevices(devices); // Reset filtered devices to all devices
  };

  // Client name link clicked handle click to navigate to /clients/id page
  function handleRowClick(selectedDevice: Device) {
    setTransitionOut(false);
    setTimeout(() => userNavigate(`/device/${selectedDevice.id}`), 400);
  }

  const handleSearchKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
  }, [searching]);


  const handleSearch = async (nextPageKeySearch?: string) => {
    try {
      setLoading(true);

      const searchResponse = await getDeviceSearch(searchCriterion, searchTerm, nextPageKeySearch);
      setSelectSuggestions(true);
      // Update state based on whether this is an initial search or a subsequent page load
      if (nextPageKeySearch) {
        setFilteredDevices((prevDevices) => [...prevDevices, ...searchResponse.data]);
      } else {
        setFilteredDevices(searchResponse.data);
      }
      setHasMoreSearch(!!searchResponse.next_page);
      nextPageKeySearchRef.current = searchResponse.next_page || null;

      setSearching(!!searchResponse.next_page);
      setErrorMessage('');
      // if (searchResponse.next_page) {
      //   setSearching(true)
      // } else {
      //   setSearching(false)
      // }
    } catch (error: any) {
      setErrorMessage(`Error while searching: ${error.message}`);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const performSearch = () => {
    handleSearch(); // Perform an initial search without pagination
  };

  //--------------------------------New Device----------------------------------

  function handleNewDevice(): void {
    setIsDialogOpen(true);
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  }

  function handleNewTabRowClick(selectedDevice: Device) {
    // setTransitionOut(false);
    window.open(`/device/${selectedDevice.id}`, "_blank");
  }

  const handleClientIdClick = (client_id: string) => {
    // Navigate to ProductPrecalsCard page with the selected precalibration ID as a parameter
    setTimeout(() => userNavigate(`/clients/${client_id}`), 400);
  };

  return (
    <>
      <Slide direction="right" in={transitionout} timeout={400}>
        <Box
          flexGrow={1}
          marginLeft={"5%"}
          marginRight={"5%"}
          marginTop={10}
          marginBottom={10}
        >
          <h2>Devices Dashboard</h2>
          <Divider />

          {/* ----------------------------TODO---------------------------- */}
          {/* <DeviceSearchBar devices={devices} onUpdateData={handleUpdateData} onError={handleError} originalDevices={originalDevices} /> */}

          {/* Search box */}

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
            marginTop={2}
            flexWrap="wrap" // Ensure items wrap on smaller screens
          >
            {/* Wrapper Box to align FormControl and TextField/Autocomplete side by side */}
            <Box display="flex" alignItems="center">
              <FormControl
                style={{
                  minWidth: 120,
                  flexGrow: 0, // Prevents stretching
                }}
                variant="outlined"
              >
                <Select value={searchCriterion} onChange={handleSearchCriterionChange}>
                  <MenuItem value="client_id" disabled={!perms.aw_permissions.clients_read}>
                    Client
                  </MenuItem>
                  <MenuItem value="product_id">Product</MenuItem>
                  <MenuItem value="serial">Serial Number</MenuItem>
                </Select>
              </FormControl>

              {/* Render Autocomplete when criterion is not "serial" */}
              {searchCriterion !== "serial" ? (
                <Autocomplete
                  options={suggestions}
                  loading={Clientloading && suggestions.length === 0} // Only show loading spinner when suggestions are empty
                  value={searchTerm}
                  onChange={(event, value) => handleSuggestionSelect(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search"
                      variant="outlined"
                      fullWidth
                      onChange={handleSearchTermChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            {Clientloading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              resetVisible && (
                                <IconButton onClick={handleReset} size="small">
                                  <ClearIcon />
                                </IconButton>
                              )
                            )}
                          </InputAdornment>
                        ),
                      }}
                      style={{ maxWidth: "300px" }}
                    />
                  )}
                  style={{ flexGrow: 2, minWidth: 200 }}
                />
              ) : (
                <><TextField
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  fullWidth
                  onChange={handleSearchTermChange}
                  onKeyDown={handleSearchKeyDown}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {Clientloading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          resetVisible && (
                            <IconButton onClick={handleReset} size="small">
                              <ClearIcon />
                            </IconButton>
                          )
                        )}
                      </InputAdornment>
                    ),
                  }}
                  style={{ maxWidth: "300px", flexGrow: 0 }} // Prevents stretching
                /><Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ height: 55 }}
                  onClick={performSearch}
                >
                    Search
                  </Button></>
              )}
            </Box>

            {/* New Device Button */}
            <Tooltip
              title="No permission to create a new device"
              arrow
              disableInteractive
              disableHoverListener={perms.aw_permissions.device_create} // Disable tooltip when button is enabled
            >
              <div style={{ display: 'inline-block' }}> {/* Make the span inline-block to avoid layout issues */}
                <Button
                  variant="contained"
                  color={theme.nextbuttoncolor}
                  onClick={() => handleNewDevice()}
                  style={{ height: 55, minWidth: 150 }}
                  disabled={!perms.aw_permissions.device_create}
                >
                  New Device
                </Button>
              </div>
            </Tooltip>
          </Box>

          {/* New Device Dialog */}
          <NewDevice isDialogOpen={isDialogOpen} onClose={handleCloseDialog} devices={devices} />

          {errorMessage && (
            <Box paddingTop={2} color="red">
              <Alert severity="error">Invalid Device</Alert>
            </Box>
          )}

          <GeneralTable
            data={selectSuggestions ? filteredDevices.map((device) => {

              return {
                // Render the filtered devices if there's a search term
                ClientID: perms.aw_permissions.clients_read ? (
                  isLoadingClients ? (
                    <Skeleton variant="rectangular" width={80} height={30} />
                  ) :
                    (device.client_id ? (
                      <Button onClick={() => userNavigate(`/clients/${device.client_id}`)}>
                        {clients[device.client_id]?.client}
                      </Button>) : (<i>Client not Registered</i>
                    ))
                ) : (
                  <Alert severity="error">No permission to view client data</Alert>
                ),
                FirmwareID: perms.aw_permissions.firmware_read ? (
                  isLoadingFirmwareVersion ? (
                    <Skeleton variant="rectangular" width={80} height={30} />
                  ) : (
                    firmwareVersion[device.firmware_id]?.firmware_version ||
                    ""
                  )) : (
                  <Alert severity="error">No permission to view Firmware data</Alert>
                ),
                id: device.id,
                InstallationDate: device.installation_date,
                RegistrationDate: device.registration_date,
                ProductID: isLoadingProducts ? (
                  <Skeleton variant="rectangular" width={80} height={30} />
                ) : (device.product_id ? (<Button onClick={() => userNavigate(`/products/${device.product_id}`)}>
                  {products[device.product_id]?.name}
                </Button>) : (<i>Product not Registered</i>)
                ),
                Serial: device.serial.length > 8 ? device.serial.substring(0, 8) : device.serial,
                VehicleID: perms.aw_permissions.vehicle_read ? (
                  isLoadingVehicles ? (
                    <Skeleton variant="rectangular" width={80} height={30} />
                  ) : (device.vehicle_id ? (<Button onClick={() => userNavigate(`/vehicles/${device.vehicle_id}`)}>
                    {vehicles[device.vehicle_id]?.vehicle_name}
                  </Button>) : (<i>Vehicle not Registered</i>)
                  )) : (
                  <Alert severity="error">No permission to view Vehicle data</Alert>
                ),
                UpdatedAt: device.updated_at,
              }
            }) : devices.map((device) => {

              return {
                // Render the original devices if there's no search term
                ClientID: perms.aw_permissions.clients_read ? (
                  isLoadingClients ? (
                    <Skeleton variant="rectangular" width={80} height={30} />
                  ) :
                    (device.client_id ? (
                      <Button onClick={() => userNavigate(`/clients/${device.client_id}`)}>
                        {clients[device.client_id]?.client}
                      </Button>) : (<i>Client not Registered</i>
                    ))
                ) : (
                  <Alert severity="error">No permission to view client data</Alert>
                ),
                FirmwareID: perms.aw_permissions.firmware_read ? (
                  isLoadingFirmwareVersion ? (
                    <Skeleton variant="rectangular" width={80} height={30} />
                  ) : (
                    firmwareVersion[device.firmware_id]?.firmware_version ||
                    ""
                  )) : (
                  <Alert severity="error">No permission to view Firmware data</Alert>
                ),
                id: device.id,
                InstallationDate: device.installation_date,
                RegistrationDate: device.registration_date,
                ProductID: isLoadingProducts ? (
                  <Skeleton variant="rectangular" width={80} height={30} />
                ) : (device.product_id ? (<Button onClick={() => userNavigate(`/products/${device.product_id}`)}>
                  {products[device.product_id]?.name}
                </Button>) : (<i>Product not Registered</i>)
                ),
                Serial: device.serial.length > 8 ? device.serial.substring(0, 8) : device.serial,
                VehicleID: perms.aw_permissions.vehicle_read ? (
                  isLoadingVehicles ? (
                    <Skeleton variant="rectangular" width={80} height={30} />
                  ) : (device.vehicle_id ? (<Button onClick={() => userNavigate(`/vehicles/${device.vehicle_id}`)}>
                    {vehicles[device.vehicle_id]?.vehicle_name}
                  </Button>) : (<i>Vehicle not Registered</i>)
                  )) : (
                  <Alert severity="error">No permission to view Vehicle data</Alert>
                ),
                UpdatedAt: device.updated_at,
              }
            })}
            columns={columns}
            loading={loading} //passing the loading state
            noDataMessage={"Unable To Fetch Devices List"}
            rowClickCallback={handleRowClick}
            rows={10}
            rowsoptions={[10, 25, 50, 100]}
            rowNewTabClickCallback={handleNewTabRowClick}
          />
          <LoadMoreButton
            hasMore={searching ? hasMoreSearch : hasMore}
            loading={loading}
            onLoadMore={handleLoadMore}
            errorMessage={errorMessage}
          />
        </Box>
      </Slide>
    </>
  );
}
