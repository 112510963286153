import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getDeviceConfiguration = async (serialnumber: string | undefined) => {
    const url = buildBaseUrl()
    if (!serialnumber) {
        return;
    }
    try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        const response = await fetch(`${url}device/${serialnumber}/configuration/latest`, {
            headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
            },
        });

        const data = await response.json();
        return data;
       
    } catch (err) {
        console.log("ERROR from getDeviceConfiguration error", err);
    }
};