import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { formatgetUsersData } from "../users/get-users";

export const getUsersForClients = async () => {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {};
  const url = buildBaseUrl();
    let baseurl = `users?`;
  
    const response = await fetch(`${url}${baseurl}`, {
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
      },
    });
    
    var data = await response.json();
    return data.data;
  };
  