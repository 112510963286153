import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Slide,
  TextField,
  Tooltip,
} from "@mui/material";
import GeneralTable from "../../utils/generic-components/table/table";
import { useContext, useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Columns } from "../../utils/interfaces/table/table-interfaces";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { ServicePlansInterface } from "../../utils/interfaces/service/service-list-interface";
import NestedTable from "../../utils/generic-components/table/nested-table";
import NewServicePlanDialog from "./service-elements/new-service-plan-dialog";
import { AppContext } from "../../utils/themes/theme-context";
import EditServicePlanDialog from "./service-elements/edit-service-plan";
import { getServicePlans } from "../../utils/requests/serviceplans/get-serviceplans";
import DeleteServicePlanDialog from "./service-elements/delete-service-plan";

export default function ServiceList() {
  var { perms, theme } = useContext(AppContext);
  const [transitionout, setTransitionOut] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>(""); // State for search term
  const [anchorEls, setAnchorEls] = useState<Array<null | HTMLElement>>([]); //controls the position of the actions menu
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [servicePlans, setServicePlans] = useState<ServicePlansInterface[]>([]);
  const [editedServicePlan, setEditedServicePlan] =
    useState<ServicePlansInterface | null>(null);
  const [deleteServicePlan, setDeleteServicePlan] =
    useState<ServicePlansInterface | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const editDialogRef = useRef<HTMLDivElement | null>(null); //editDialogRef is used to access the content of the edit dialog. This allows the edited values to be retrieved and used when saving changes to the product data.
  // The editDialogRef itself is not used directly in this function, but it's part of the context that allows the dialog's content to be manipulated.
  const [loading, setLoading] = useState<boolean>(true); //initial stage of loading is true
  let userNavigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState<boolean>(false); // State to trigger service plans update
  var { theme } = useContext(AppContext);

  useEffect(() => {
    setLoading(true);
    //Fetch Service Plan list data and update the state
    getServicePlans()
      .then((response: { data: ServicePlansInterface[] }) => {
        const data = response.data; //data property is assigened to variable data
        setLoading(false);
        setServicePlans(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setErrorMessage("Parameter mismatch");
          } else if (error.response.status === 500) {
            setErrorMessage("Internal Server Error");
          } else {
            // other errors
            setErrorMessage(`Error: ${error.message}`);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setErrorMessage("No response received");
        } else {
          // Something happened in setting up the request that triggered an error
          setErrorMessage(`Error: ${error.message}`);
        }
        setLoading(false);
      });
  }, [updateTrigger]); //[] ->  that this effect runs only once after the initial render.

  useEffect(() => {
    setAnchorEls(servicePlans.map(() => null));
  }, [servicePlans]); // It sets initial values for the anchor elements based on the number of products, and it re-runs whenever the products state changes.

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    // index of the product for which the actions menu is being opened.
    const updatedAnchorEls = [...anchorEls]; //... spread operator creates a shallow copy of the anchorEls array. So that the state is not mutated directly, which is important in React to maintain proper state management
    updatedAnchorEls[index] = event.currentTarget; //updates the copied array to set the element at the specified index to the currentTarget of the event -> button that was clicked.
    setAnchorEls(updatedAnchorEls);
  };

  const handleMenuClose = (index: number) => {
    const updatedAnchorEls = [...anchorEls];
    updatedAnchorEls[index] = null; //the actions menu for that product should be closed
    setAnchorEls(updatedAnchorEls);
  };

  const handleEdit = (rowData: ServicePlansInterface) => {
    // editedProductNameRef.current = rowData.name;
    setEditedServicePlan(rowData);
    setEditDialogOpen(true);
    handleMenuClose(
      servicePlans.findIndex((servicePlan) => servicePlan.id === rowData.id)
    );
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setEditedServicePlan(null);
    // After delete action, trigger update of service plans
    setUpdateTrigger((prev) => !prev);
    // setEditedProductNameError("");
    // editedProductNameRef.current = ""; // Reset the stored name
  };

  const handleDelete = (rowData: ServicePlansInterface) => {
    setDeleteServicePlan(rowData);
    setDeleteDialogOpen(true); // Open the delete dialog
    handleMenuClose(
      servicePlans.findIndex((servicePlan) => servicePlan.id === rowData.id)
    );
    //  It finds the index of the product in the products array based on its id property and then passes
    // this index to handleMenuClose to close the actions menu associated with that product.
    //show a dialog you cannot delete
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    // After delete action, trigger update of service plans
    setUpdateTrigger((prev) => !prev);
  };

  //Action cell Icon and options
  const renderActionsCell = (rowData: ServicePlansInterface, index: number) => {
    return (
      <>
        <IconButton
          aria-label="actions"
          aria-controls={`actions-menu-${rowData.id}`}
          aria-haspopup="true"
          onClick={(event) => {
            handleMenuOpen(event, index);
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id={`actions-menu-${rowData.id}`}
          anchorEl={anchorEls[index]}
          open={Boolean(anchorEls[index])}
          onClose={() => handleMenuClose(index)}
        >
          {/* Edit MenuItem */}
          <Tooltip
            title="No permission to edit service plan"
            arrow
            disableInteractive
            disableHoverListener={perms.aw_permissions.service_plan_edit} // Only show tooltip when disabled
          >
            <div> {/* Wrapping in a div to maintain tooltip on disabled MenuItem */}
              <MenuItem onClick={() => handleEdit(rowData)} disabled={!perms.aw_permissions.service_plan_edit}>
                <EditIcon /> Edit
              </MenuItem>
            </div>
          </Tooltip>

          {/* Delete MenuItem */}
          <Tooltip
            title="No permission to delete service plan"
            arrow
            disableInteractive
            disableHoverListener={perms.aw_permissions.service_plan_delete} // Only show tooltip when disabled
          >
            <div> {/* Wrapping in a div to maintain tooltip on disabled MenuItem */}
              <MenuItem onClick={() => handleDelete(rowData)} disabled={!perms.aw_permissions.service_plan_delete}>
                <DeleteIcon /> Delete
              </MenuItem>
            </div>
          </Tooltip>
        </Menu>
      </>
    );
  };

  let columns: Columns = {
    Plan: {
      type: "link",
      display: "Plan",
      data: "name",
      sort: "Plan",
    },
    CreationDate: {
      type: "time",
      display: "Creation Date",
      data: "creation_date",
      sort: "CreationDate",
    },
    Comments: {
      type: "text",
      display: "Comments",
      data: "comments",
      sort: "Comments",
    },
    ServicePlanFeatures: {
      type: "subhead",
      display: "Service Plan Features",
      data: "service_plan_features",
      subColumns: {
        FaultAlerts: {
          type: "text",
          display: "Fault Alerts",
          data: "fault_alerts",
        },
        FirmwareUpdates: {
          type: "text",
          display: "Firmware Updates",
          data: "firmware_updates",
        },
        HeartBeatAlerts: {
          type: "text",
          display: "HeartBeat Alerts",
          data: "heartbeat_alerts",
        },
      },
    },

    UpdatedAt: {
      type: "time",
      display: "Updated At",
      data: "updated_at",
      sort: "UpdatedAt",
    },
    Actions: {
      type: "actions",
      display: "Actions",
      render: renderActionsCell,
    },
  };

  // Function to handle search
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  function handleNewServicePlan(): void {
    setIsDialogOpen(true);
  }

  // Service Plan link clicked handle click to navigate to /clients/id page
  function handleRowClick(selectedService: ServicePlansInterface) {
    setTransitionOut(false);
    setTimeout(() => userNavigate(`/service/${selectedService.id}`), 400);
  }

  function handleNewTabRowClick(selectedService: ServicePlansInterface) {
    // setTransitionOut(false);
    window.open(`/service/${selectedService.id}`, "_blank");
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    // After delete action, trigger update of service plans
    setUpdateTrigger((prev) => !prev);
  };

  return (
    <>
      <Slide direction="right" in={transitionout} timeout={400}>
        <Box
          flexGrow={1}
          marginLeft={"5%"}
          marginRight={"5%"}
          marginTop={10}
          marginBottom={10}
        >
          <h2>Service Plan Dashboard</h2>
          <Divider />

          {/* Search box and button container */}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
            marginTop={2}
          >
            <TextField
              type="text"
              placeholder="Search by any field"
              value={searchTerm}
              onChange={handleSearch}
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ maxWidth: "300px" }} // Optional: control the max width of the search box
            />
            <Tooltip
              title="No permission to create new service plan"
              arrow
              disableInteractive
              disableHoverListener={perms.aw_permissions.service_plan_create} // Disable tooltip when button is enabled
            >
              <div style={{ display: 'inline-block' }}> {/* Make the span inline-block to avoid layout issues */}
                <Button
                  variant="contained"
                  color={theme.nextbuttoncolor}
                  onClick={() => handleNewServicePlan()}
                  disabled={!perms.aw_permissions.service_plan_create}
                  style={{ height: 55, minWidth: 150 }}
                >
                  New Service Plan
                </Button>
              </div>
            </Tooltip>
          </Box>
          {/* {errorMessage && (
              <Box paddingTop={2} color="red">
                <Alert severity="error">Invalid Client</Alert>
              </Box>
            )} */}

          {/* {loading ? (
                  // Render the loading indicator when loading is true
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="300px"
                  >
                    <CircularProgress />
                  </Box>
                ) : ( */}
          {/* // Render the table when loading is false */}
          <GeneralTable
            data={servicePlans.map((servicePlan, index) => ({
              id: servicePlan.id,
              Plan: servicePlan.name,
              CreationDate: servicePlan.creation_date,
              Comments: servicePlan.comments,
              ServicePlanFeatures: (
                <NestedTable
                  data={servicePlan.service_plan_features}
                  columns={columns.ServicePlanFeatures.subColumns}
                />
              ),
              UpdatedAt: servicePlan.updated_at,
              // Tickets Future use
              // OpenSupportTickets: item.OpenSupportTickets,
              Actions: renderActionsCell(servicePlan, index),
            }))}
            columns={columns}
            loading={loading} //passing the loading state
            noDataMessage={"Unable To Fetch Service List"}
            rowClickCallback={handleRowClick}
            rows={10}
            rowsoptions={[10, 25, 50, 100]}
            rowNewTabClickCallback={handleNewTabRowClick}
          />
          {/* )} */}
        </Box>
      </Slide>

      {/* Render the EditServicePlanDialog component */}
      <EditServicePlanDialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        servicePlan={editedServicePlan}
        servicePlanList={servicePlans}
      />

      <DeleteServicePlanDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        deleteServicePlan={deleteServicePlan}
      />

      <NewServicePlanDialog
        open={isDialogOpen}
        handleClose={handleCloseDialog}
        servicePlanList={servicePlans}
      />
    </>
  );
}
