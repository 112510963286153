import ReactDOM from 'react-dom/client'; // Import createRoot from React 18

import GeoSearchBox, { Address } from "./search-box";

class GeoSearchControl implements maplibregl.IControl {
    private container: HTMLElement;
    private root: ReactDOM.Root | null = null; // Store a reference to the React root
    private onResultClickCallback: (result: Address) => void;
    private disabled: boolean = false;

    constructor(options: GeoSearchControlOptions) {
        this.container = document.createElement('div');
        this.container.className = 'maplibregl-ctrl';
        this.onResultClickCallback = options.onResultClickCallback;
        this.disabled = options.disabled ?? false;
    }

    onAdd(map: maplibregl.Map): HTMLElement {
        // Create a React root and render the GeoSearchBox component
        this.root = ReactDOM.createRoot(this.container); // Initialize the root
        this._updateVisibility();
        return this.container;
    }

    onRemove() {
        if (this.root) {
            // Use a setTimeout to delay unmounting until after the current render cycle
            setTimeout(() => {
                this.root!.unmount();
                if (this.container.parentNode) {
                    this.container.parentNode.removeChild(this.container);
                }
            }, 0);
        } else {
            if (this.container.parentNode) {
                this.container.parentNode.removeChild(this.container);
            }
        }
    }

    private _updateVisibility = () => {
        if (this.disabled) {
            this.root?.render(<></>);
        } else {
            this.root?.render(
                <GeoSearchBox onResultClickCallback={this.onResultClickCallback} />
            );
        }
    }

    disableControl = () => {
        this.disabled = true;
        this._updateVisibility();
    }

    enableControl = () => {
        this.disabled = false;
        this._updateVisibility();
    }
}

export type GeoSearchControlOptions = {
    /**
     * Callback function that is called when a search result is clicked
     * @param result The address object that was clicked
     */
    onResultClickCallback: (result: Address) => void;

    /**
     * Whether the control starts disabled
     */
    disabled?: boolean;
}

export default GeoSearchControl;