import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
	aw_permissions,
	corporate_permissions,
	iweigh_permissions,
} from "../../../utils/interfaces/new-user/new-user-interface";
import {
	PermsType,
	aw_perms_descriptions,
	aw_perms_name,
	corp_perms_descriptions,
	corp_perms_name,
	iweigh_perms_name,
	iweigh_perms_descriptions,
} from "../../../utils/interfaces/new-user/permissions";
import { Check } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../utils/themes/theme-context";
import { getUser } from "../../../utils/interfaces/users/user-details-interfaces";
import { LoadingButton } from "@mui/lab";
import { putUserPermissions } from "../../../utils/requests/user-details/put-user-permissions";
import { errorResponse } from "../../../utils/interfaces/error-interface";
import { green, grey } from "@mui/material/colors";

export default function Permissions(props: {
	user: getUser;
	updatedat: string;
}) {
	var { perms, theme } = useContext(AppContext);
	const [editingmode, setEditingMode] = useState<boolean>(false);
	const [savedpermissions, setSavedPermissions] = useState<
		aw_permissions | corporate_permissions | iweigh_permissions
	>(
		props.user.data.user_type === "aw"
			// ? props.user.data.aw_permissions
			? { ...props.user.data.aw_permissions, ...props.user.data.iweigh_permissions }
			// : props.user.data.corporate_permissions
			: { ...props.user.data.corporate_permissions, ...props.user.data.iweigh_permissions }
	);
	const [currentpermissions, setCurrentPermissions] = useState<
		aw_permissions | corporate_permissions | iweigh_permissions
	>(
		props.user.data.user_type === "aw"
			// ? props.user.data.aw_permissions
			? { ...props.user.data.aw_permissions, ...props.user.data.iweigh_permissions }
			// : props.user.data.corporate_permissions
			: { ...props.user.data.corporate_permissions, ...props.user.data.iweigh_permissions }
	);
	let permissionsList: PermsType;
	let permissionsDescriptions: PermsType;
	if (props.user.data.user_type === "aw") {
		permissionsList = aw_perms_name;
		permissionsDescriptions = aw_perms_descriptions;
	} else {
		permissionsList = corp_perms_name;
		permissionsDescriptions = corp_perms_descriptions;
	}
	const [rowsperpage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);
	const [iweighPage, setIweighPage] = useState(0);
	const [iweighRowsPerPage, setIweighRowsPerPage] = useState(5);

	const [loadingbutton, setLoadingButton] = useState<boolean>(false);
	const [changeduser, setChangedUser] = useState<any>(props.user.data); //TODO: this type giving me trouble
	const [saving, setSaving] = useState<boolean>(false);
	const [alert, setAlert] = useState<string>("");

	//turns off and on editing mode on click of edit/save button
	const editClickHandler = () => {
		if (editingmode === true) {
			//"pressing save"
			setSaving(true);
			setSavedPermissions(currentpermissions);
			if (props.user.data.user_type === "aw") {
				setChangedUser({ ...changeduser, aw_permissions: currentpermissions, iweigh_permissions: currentpermissions });
			} else {
				setChangedUser({
					...changeduser,
					corporate_permissions: currentpermissions,
					iweigh_permissions: currentpermissions,

				});

			}
		} else {
			//pressing cancel, reset changeduser to original user passed via props
			setChangedUser(props.user.data);
			setEditingMode(!editingmode);
		}
	};

	useEffect(() => {
		if (saving === true) {
			putNewUser()
				.then((response: getUser) => {
					setAlert("");
					setEditingMode(!editingmode);
				})
				.catch((err: errorResponse) => {
					setAlert("Unable To Save, Try Again");
					console.log(err);
				});
		}
		setSaving(false);
	}, [changeduser]);

	const putNewUser = async () => {
		try {
			//add new perms to user...
			setLoadingButton(true);
			const response = await putUserPermissions(
				props.user.data.username,
				changeduser,
				props.updatedat
			);
			setLoadingButton(false);
			return response;
		} catch (err) {
			console.log(err);
		}
	};

	const cancelClickHandler = () => {
		setAlert("");
		setEditingMode(false);
		setCurrentPermissions(savedpermissions);
	};

	const setPermission = (choice: boolean, label: string) => {
		setCurrentPermissions({ ...currentpermissions, [label]: choice });

	};

	/* changes page when pagination clicked, conditionally calls additionalpageresultscallback */
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};

	/* handles pagination dropdown click */
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};



	const paginationDisplay = () => {
		return (
			<><TablePagination
				component="div"
				count={Object.keys(permissionsList).length}
				page={page}
				onPageChange={handleChangePage}
				rowsPerPage={rowsperpage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				rowsPerPageOptions={[5, 10, 15, 30]} />
				<TablePagination
					component="div"
					count={Object.keys(iweighPermissionsName).length}
					page={iweighPage}
					onPageChange={handleIweighChangePage}
					rowsPerPage={iweighRowsPerPage}
					onRowsPerPageChange={handleIweighChangeRowsPerPage}
					rowsPerPageOptions={[5, 10, 15, 30]} /></>
		);
	};

	const handleIweighChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setIweighPage(newPage);
	};

	const handleIweighChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setIweighRowsPerPage(parseInt(event.target.value, 10));
		setIweighPage(0);
	};



	const readOnlyList = () => {
		return (
			<TableBody>
				{Object.keys(permissionsList)
					.slice(page * rowsperpage, (page + 1) * rowsperpage)
					.map((text: string) => (
						<TableRow key={text}>
							<TableCell>
								<Typography fontWeight={"bold"}>
									{permissionsList[text]}
								</Typography>
								<Typography color={theme.subtitle}>
									{permissionsDescriptions[text]}
								</Typography>
							</TableCell>
							<TableCell align="right">
								{currentpermissions[text] === true ? (
									<Chip icon={<Check />} label="True" color="success" />
								) : (
									<Chip icon={<CloseIcon />} label="False" color="error" />
								)}
							</TableCell>
						</TableRow>
					))}
				<TableCell colSpan={2} style={{ width: '100%', textAlign: 'left', backgroundColor: grey[200] }}>
					<TablePagination
						component="div"
						count={Object.keys(permissionsList).length}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15, 30]}
					/>
				</TableCell>
				<TableRow ><TableCell style={{ fontSize: "24px" }}>iWeigh Permissions</TableCell></TableRow>
				{
					// Render iweigh permissions for "aw" users
					Object.keys(iweighPermissionsName).slice(iweighPage * iweighRowsPerPage, (iweighPage + 1) * iweighRowsPerPage).map((text: string) => (
						<TableRow key={text}>
							<TableCell>
								<Typography fontWeight={"bold"}>
									{iweighPermissionsName[text]}
								</Typography>
								<Typography color={theme.subtitle}>
									{aiweighPermissionsDescriptions[text]}
								</Typography>
							</TableCell>
							<TableCell align="right">
								{currentpermissions[text] === true ? (
									<Chip icon={<Check />} label="True" color="success" />
								) : (
									<Chip icon={<CloseIcon />} label="False" color="error" />
								)}
							</TableCell>
						</TableRow>
					))
				}
				<TableCell colSpan={2} style={{ width: '100%', textAlign: 'left', backgroundColor: grey[200] }}>
					<TablePagination
						component="div"
						count={Object.keys(iweighPermissionsName).length}
						page={iweighPage}
						onPageChange={handleIweighChangePage}
						rowsPerPage={iweighRowsPerPage}
						onRowsPerPageChange={handleIweighChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15, 30]} />
				</TableCell>
			</TableBody>
		);
	};

	const writeList = () => {
		return (
			<TableBody>
				{Object.keys(permissionsList)
					.slice(page * rowsperpage, (page + 1) * rowsperpage)
					.map((text: string) => (
						<TableRow key={text}>
							<TableCell>
								<Typography fontWeight={"bold"}>
									{permissionsList[text]}
								</Typography>
								<Typography color={theme.subtitle}>
									{permissionsDescriptions[text]}
								</Typography>
							</TableCell>
							<TableCell>
								<RadioGroup row>
									<FormControlLabel
										onChange={() => setPermission(true, text)}
										value={true}
										control={<Radio />}
										label="True"
										checked={currentpermissions[text] === true}
									/>
									<FormControlLabel
										onChange={() => setPermission(false, text)}
										value={false}
										control={<Radio />}
										label="False"
										checked={currentpermissions[text] === false}
									/>
								</RadioGroup>
							</TableCell>
						</TableRow>
					))}

				<TableCell colSpan={2} style={{ width: '100%', textAlign: 'left', backgroundColor: grey[200] }}>
					<TablePagination
						component="div"
						count={Object.keys(permissionsList).length}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15, 30]} />
				</TableCell>

				<TableRow>
					<TableCell style={{ fontSize: "24px" }}>iWeigh Permissions</TableCell>
				</TableRow>
				{
					// Render iweigh permissions for "aw" users
					Object.keys(iweighPermissionsName).slice(iweighPage * iweighRowsPerPage, (iweighPage + 1) * iweighRowsPerPage).map((text: string) => (
						<TableRow key={text}>
							<TableCell>
								<Typography fontWeight={"bold"}>
									{iweighPermissionsName[text]}
								</Typography>
								<Typography color={theme.subtitle}>
									{aiweighPermissionsDescriptions[text]}
								</Typography>
							</TableCell>
							<TableCell>
								<RadioGroup row>
									<FormControlLabel
										onChange={() => setPermission(true, text)}
										value={true}
										control={<Radio />}
										label="True"
										checked={currentpermissions[text] === true}
									/>
									<FormControlLabel
										onChange={() => setPermission(false, text)}
										value={false}
										control={<Radio />}
										label="False"
										checked={currentpermissions[text] === false}
									/>
								</RadioGroup>
							</TableCell>
						</TableRow>
					))
				}
				<TableCell colSpan={2} style={{ width: '100%', textAlign: 'left', backgroundColor: grey[200] }}>
					<TablePagination
						component="div"
						count={Object.keys(iweighPermissionsName).length}
						page={iweighPage}
						onPageChange={handleIweighChangePage}
						rowsPerPage={iweighRowsPerPage}
						onRowsPerPageChange={handleIweighChangeRowsPerPage}
						rowsPerPageOptions={[5, 10, 15, 30]} />
				</TableCell>
			</TableBody>
		);
	};



	// Define additional permissions for iweigh
	const iweighPermissionsName: PermsType = iweigh_perms_name;
	const aiweighPermissionsDescriptions: PermsType = iweigh_perms_descriptions;

	return (
		<>
			<Box marginBottom={"50%"} flexGrow={1}>
				<Card elevation={8} sx={{ borderRadius: 3 }}>
					<CardHeader title="Permissions" />
					<CardContent>
						<Divider />
						{alert ? <Alert severity="error">{alert}</Alert> : null}
						<Table>{editingmode ? writeList() : readOnlyList()}</Table>
						{/* {props.user.data.user_type === "aw" ? paginationDisplay() : null} */}
					</CardContent>
					<Divider />
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Box margin={2}>
								{editingmode ? (
									<Button
										color={theme.backbuttoncolor}
										variant="contained"
										onClick={cancelClickHandler}
									>
										Cancel
									</Button>
								) : null}
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box display="flex" justifyContent="flex-end" margin={2}>
								{editingmode ? (
									<LoadingButton
										color={theme.nextbuttoncolor}
										variant="contained"
										onClick={editClickHandler}
										loading={loadingbutton}
									>
										Save
									</LoadingButton>
								) : (


									<Tooltip
										title="No permission to edit user"
										arrow
										disableInteractive
										disableHoverListener={perms.aw_permissions.user_edit} // Disable tooltip when button is enabled
									>
										<span>
											<LoadingButton
												color={theme.basicbuttoncolor}
												variant="contained"
												onClick={editClickHandler}
												disabled={!perms.aw_permissions.user_edit}
											>
												Edit
											</LoadingButton>
										</span>
									</Tooltip>
								)}
							</Box>
						</Grid>
					</Grid>
					<Divider />
				</Card>
			</Box>
		</>
	);
}
