import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
} from "@mui/material";
import { Columns } from "../../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../../utils/generic-components/table/table";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TestLogData } from "../../../../utils/interfaces/devices/test-logs-interface";

let columns: Columns = {
    ErrorCode: {
        type: "text",
        display: "Error Code",
        data: "ErrorCode", 
        sort: "ErrorCode",
    },
    ErrorMessages: {
        type: "text",
        display: "Error Messages",
        data: "ErrorMessages", 
        sort: "ErrorMessages",
    },
};

export default function TestLogsErrorMessages(props: {
    testlogdata: TestLogData | null;
}) {

    const { test_result } = props.testlogdata || {};  // Destructure test_result from props.testlogdata

    // Prepare data for the table only if test_result is defined
    const errorMessagesData = test_result
        ? (test_result.test_error_code_list || []).map((code, index) => ({
            ErrorCode: code,  // Map the code as 'ErrorCode'
            ErrorMessages: test_result.test_error_message_list ? test_result.test_error_message_list[index] : null,  // Map the corresponding message as 'ErrorMessages'
        }))
            .filter(error => error.ErrorMessages) // Filter out any entries without messages
        : []; // Fallback to an empty array if test_result is undefined

    return (
        <Card elevation={8} sx={{ borderRadius: 3 }}>
            <CardHeader
                title="Error Messages"
                action={
                    <IconButton aria-label="info">
                        <InfoOutlinedIcon />
                    </IconButton>
                }
            />
            <CardContent>
                <Divider />
                <GeneralTable
                    data={errorMessagesData}  // Directly pass the mapped data
                    columns={columns}  // Use the updated columns configuration
                    loading={false}
                    customTableStyle={{ width: '100%' }}
                    noDataMessage="No Error Messages"
                />
            </CardContent>
        </Card>
    );
}
