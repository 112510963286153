import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from '../build-base-url';

export async function getClientVehicles(clientId: string) {
  const url = buildBaseUrl()
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}clients/${clientId}/vehicles`, {
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch client vehicles');
    }

    const data = await response.json();
    return data.data;
    
  } catch (err) {
    console.log(err);
    throw err;
  }
}