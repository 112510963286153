import { Amplify } from "aws-amplify";

export const getMapNames = () => {
    return ["Aw-Cloud-Portal-Map-Road", "Aw-Cloud-Portal-Map-Satellite"];
}

export const getPlaceIndex = () => {
    return ["AW-Cloud-Portal-Search"];
}

export const getRegion = () => {
    return process.env.REACT_APP_DEFAULT_REGION || "us-east-2";
}

export const getIdentityPoolId = () => {
    return Amplify.getConfig().Auth?.Cognito.identityPoolId || "";
}

export const getMapStyles = () => {
    return {
        road: `https://maps.geo.${getRegion()}.amazonaws.com/maps/v0/maps/${getMapNames()[0]}/style-descriptor`,
        satellite: `https://maps.geo.${getRegion()}.amazonaws.com/maps/v0/maps/${getMapNames()[1]}/style-descriptor`
    }
}

export const getPlaceIndices = () => {
    return {
        searchBaseUrl: `/places/v0/indexes/${getPlaceIndex()[0]}/places`,
    }
}

export const getStates = () => {
    return ['AL','AK','AZ','AR','CA','CO','CT','DE','DC','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA',
    'ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR',
    'PA','PR','RI','SC','SD','TN','TX','UT','VT','VA','VI','WA','WV','WI','WY']
}

export const getCountries = () => {
    return ['USA','GBR','CAN','MEX','AUS','FRA']
}
