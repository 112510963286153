import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getDeviceFirmwareVersion = async (firmwareid: string | undefined) => {
  const url = buildBaseUrl();
  if (!firmwareid) {
    return;
  }
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}firmware/${firmwareid}`, {
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
      },
    });

    // Check the response status code
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
      throw new Error("Unauthorized access to device firmware.");
    } else if (response.status === 500) {
      throw new Error("Server error while fetching device firmware.");
    } else {
      throw new Error(`Unexpected response status code: ${response.status}`);
    }
  } catch (err) {
    console.error("Error from getDeviceFirmware:", err);
    throw err; // Rethrow the error to handle it in the caller function
  }
};
