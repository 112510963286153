import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { AppContext } from "../../utils/themes/theme-context";
import { getSingleIntegration } from "../../utils/requests/integraton/get-single-integrator";
import { Integration } from "../../utils/interfaces/integration/integration-interface";
import Details from "./integration-elements/details";
import Actions from "./integration-elements/actions";
import IntegrationIntervals from "./integration-elements/integration-intervals";
import { getIntegration } from "../../utils/requests/integraton/get-integration";
import IntegratorStats from "./integration-elements/stats";
import IntegratorProductStats from "./integration-elements/integratorproductstats";

export function SingleIntegrationPage() {
  var { theme } = useContext(AppContext);
  const { id } = useParams<{ id?: string }>();
  let userNavigate = useNavigate();
  const [transition, setTransition] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string>("Home");
  const [displayDetailsTable, setDisplayDetailsTable] = useState<boolean>(true);
  const [displayDevices, setDisplayDevices] = useState<boolean>(false);
  const [displayLogs, setDisplayLogs] = useState<boolean>(false);
  const [displayVehicles, setDisplayVehicles] = useState<boolean>(false);
  const [displaysetDisplayFirmwareVersions, setDisplayFirmwareVersions] =
    useState<boolean>(false);
  const [integratorDetailsData, setIntegratorDetailsData] =
    useState<Integration | null>(null);
  const [data, setData] = useState<Integration[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isValidId, setIsValidId] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
     getIntegration().then((response: {data: Integration[]}) => {
      setData(response.data);
      setLoading(false);
     })
        
      .catch ((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  //   Fetch data from the API on component mount
  useEffect(() => {
    if (id) {
      // Check if the id is valid (you can customize this validation)
      const isValid = data.some((d) => d.id === id);
      setIsValidId(isValid);

      if (isValid) {
        getSingleIntegration(id)
          .then((data: Integration) => {
            setIntegratorDetailsData(data);
          })
          .catch((error) => {
            console.error("Error fetching integrator data:", error);
            setErrorMessage(true);
          });
      }
    }
  }, [id, data]);

  function handleBack(): void {
    setTransition(false);
    setTimeout(() => userNavigate(`/integrators`), 400);
  }

  const loadingDisplay = () => {
    if (!integratorDetailsData) {
      return (
        <>
          <LinearProgress />
          <Stack direction="row" spacing={3} marginTop={5}>
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={275} height={500} />
              <Skeleton variant="rectangular" width={275} height={200} />
            </Stack>
            <Divider flexItem orientation="vertical" />
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={700} height={200} />
              <Skeleton variant="rectangular" width={700} height={400} />
            </Stack>
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={275} height={150} />
              <Skeleton variant="rectangular" width={275} height={100} />
            </Stack>
          </Stack>
        </>
      );
    }
    return null;
  };

  const handleButtonClick = async (buttonName: string) => {
    setActiveButton(buttonName);
    setDisplayDetailsTable(buttonName === "Home");
    //Future Use
    // setDisplayDevices(buttonName === "Devices");
    // setDisplayLogs(buttonName === "Logs");
    // setDisplayVehicles(buttonName === "Vehicles");
  };

  return (
    <>
      <Slide direction="left" in={transition} timeout={400}>
        <Box
          flexGrow={1}
          marginLeft={"2%"}
          marginRight={"2%"}
          marginTop={8}
          marginBottom={10}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h2>Integrator Details</h2>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="flex-end"
                marginTop={3}
                marginRight={2}
              >
                <Button
                  color={theme.backbuttoncolor}
                  onClick={handleBack}
                  variant={"contained"}
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Grid>
            <Box
              display="flex"
              justifyContent="flex-start"
              marginTop={1}
              marginBottom={1}
            >
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Home"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Home")}
              >
                Home
              </Button>
              {/* Future Use 
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Devices"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Devices")}
              >
                Devices
              </Button>
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Logs"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Logs")}
              >
                Logs
              </Button>
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Vehicles"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Vehicles")}
              >
                Vehicles
              </Button> */}
            </Box>
          </Grid>
          <Divider />
          <>
            {loading ? (
              <>
                <LinearProgress />
                <Stack direction="row" spacing={3} marginTop={5}>
                  <Stack direction="column" spacing={3}>
                    <Skeleton variant="rectangular" width={275} height={500} />
                    <Skeleton variant="rectangular" width={275} height={200} />
                  </Stack>
                  <Divider flexItem orientation="vertical" />
                  <Stack direction="column" spacing={3}>
                    <Skeleton variant="rectangular" width={700} height={200} />
                    <Skeleton variant="rectangular" width={700} height={400} />
                  </Stack>
                  <Stack direction="column" spacing={3}>
                    <Skeleton variant="rectangular" width={275} height={150} />
                    <Skeleton variant="rectangular" width={275} height={100} />
                  </Stack>
                </Stack>
              </>
            ) : isValidId ? (
              <>
                {displayDevices ? (
                  <Box padding={2}>
                    {/* <DevicesTablList /> */}
                    <Typography variant="h4">Devices Data Display</Typography>
                  </Box>
                ) : displayLogs ? (
                  <Box padding={2}>
                    <Typography variant="h4">Logs Data Display</Typography>
                  </Box>
                ) : displayVehicles ? (
                  <Box padding={2}>
                    <Typography variant="h4">Vehicles Data Display</Typography>
                  </Box>
                ) : errorMessage ? (
                  <Box paddingTop={2} color="red">
                    <Alert severity="error">Invalid Integrator</Alert>
                  </Box>
                ) : displayDetailsTable && integratorDetailsData ? (
                  <>
                    <Stack
                      direction="row"
                      spacing={5}
                      marginTop={5}
                      marginBottom={10}
                    >
                      <Stack direction="column" spacing={2} flex={1}>
                        <Stack direction="column" spacing={2}>
                          <Details integrator={integratorDetailsData} />
                          <IntegratorStats intergratorId={integratorDetailsData.id} />
                          <IntegratorProductStats intergratorId={integratorDetailsData.id} />
                          <Actions />
                        </Stack>
                      </Stack>

                      <Divider flexItem orientation="vertical" />

                      <Stack direction="column" spacing={2} flex={3}>
                        <Stack direction="column" spacing={2}>
                          <IntegrationIntervals
                            intervals={integratorDetailsData.intervals}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </>
                ) : (
                  loadingDisplay()
                )}
              </>
            ) : (
              <Box paddingTop={2} color="red">
                <Alert severity="error">Invalid Integration iD</Alert>
              </Box>
            )}
          </>
        </Box>
      </Slide>
    </>
  );
}
