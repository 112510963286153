const friendlyNameMap: Record<string, string> = {
    action_logs_get_logs: "Action Logs: Get Logs",
    action_logs_get_action_logs_for_user: "Action Logs: Get Logs For User",
    action_logs_get_action_logs_for_device: "Action Logs: Get Logs For Device",
    action_logs_get_action_logs_for_vehicle: "Action Logs: Get Logs For Vehicle",
    clients_get_search: "Clients: Get Search",
    clients_get: "Clients: Get",
    clients_get_client: "Clients: Get Client",
    clients_put_client: "Clients: Update Client",
    clients_get_available: "Clients: Get Available",
    clients_get_vehicles: "Clients: Get Vehicles",
    clients_get_faults: "Clients: Get Faults",
    clients_get_preconfiguration: "Clients: Get Preconfiguration",
    clients_post_preconfiguration: "Clients: Create Preconfiguration",
    clients_delete_preconfiguration: "Clients: Delete Preconfiguration",
    clients_put_preconfiguration: "Clients: Update Preconfiguration",
    clients_post_client: "Clients: Create Client",
    device_get: "Device: Get",
    device_get_device: "Device: Get Device",
    device_delete: "Device: Delete",
    device_access: "Device: Access",
    device_post_calibration: "Device: Post Calibration",
    device_get_calibration: "Device: Get Calibration",
    device_get_calibration_latest: "Device: Get Latest Calibration",
    device_post_configuration: "Device: Post Configuration",
    device_get_configuration: "Device: Get Configuration",
    device_get_configuration_latest: "Device: Get Latest Configuration",
    device_get_register: "Device: Get Register",
    device_post_register: "Device: Post Register",
    device_delete_register: "Device: Delete Register",
    device_post_faults: "Device: Post Faults",
    device_get_faults: "Device: Get Faults",
    device_post_diagnostics: "Device: Post Diagnostics",
    device_get_diagnostics: "Device: Get Diagnostics",
    device_post_weights: "Device: Post Weights",
    device_get_weights: "Device: Get Weights",
    device_get_firmware: "Device: Get Firmware",
    device_post_firmware: "Device: Post Firmware",
    device_post_firmware_confirm: "Device: Confirm Firmware Post",
    device_recent_activity: "Device: Recent Activity",
    device_get_preconfiguration: "Device: Get Preconfiguration",
    device_get_ports: "Device: Get Ports",
    device_get_search: "Device: Get Search",
    device_get_manufacture_logs: "Device: Get Manufacture Logs",
    faults_get_fault: "Faults: Get Fault",
    firmware_get_firmware: "Firmware: Get Firmware",
    generic_post_preconfiguration: "Generic: Post Preconfiguration",
    generic_get_preconfiguration: "Generic: Get Preconfiguration",
    generic_delete_preconfiguration: "Generic: Delete Preconfiguration",
    generic_put_preconfiguration: "Generic: Update Preconfiguration",
    generic_post_products_precal: "Generic: Post Products Precal",
    generic_get_products_precal: "Generic: Get Products Precal",
    generic_get_precals: "Generic: Get Precals",
    generic_delete_precals: "Generic: Delete Precals",
    generic_post_heartbeat: "Generic: Post Heartbeat",
    integrations_post_access_token_refresh: "Integrations: Post Access Token Refresh",
    integrator_get_integrators: "Integrator: Get Integrators",
    integrator_get_integrator: "Integrator: Get Integrator",
    iweigh_device_post_register: "IWeigh Device: Post Register",
    iweigh_device_delete_register: "IWeigh Device: Delete Register",
    iweigh_device_get_precal: "IWeigh Device: Get Precal",
    iweigh_device_post_precal: "IWeigh Device: Post Precal",
    iweigh_device_get_precal_options: "IWeigh Device: Get Precal Options",
    iweigh_device_post_pair: "IWeigh Device: Post Pair",
    iweigh_device_post_preconfiguration: "IWeigh Device: Post Preconfiguration",
    iweigh_get_axle_groups: "IWeigh: Get Axle Groups",
    manufacture_get_firmware: "Manufacture: Get Firmware",
    manufacture_post_register: "Manufacture: Post Register",
    manufacture_post_logs: "Manufacture: Post Logs",
    manufacture_get_logs: "Manufacture: Get Logs",
    manufacture_get_register: "Manufacture: Get Register",
    manufacture_delete_register: "Manufacture: Delete Register",
    products_get: "Products: Get",
    products_get_product: "Products: Get Product",
    products_post_product: "Products: Post Product",
    products_put_product: "Products: Update Product",
    products_post_firmware: "Products: Post Firmware",
    products_get_product_firmware: "Products: Get Product Firmware",
    products_get_product_firmware_version: "Products: Get Product Firmware Version",
    products_search: "Products: Search",
    service_plans_get: "Service Plans: Get",
    service_plans_get_plan: "Service Plans: Get Plan",
    service_plans_put_plan: "Service Plans: Update Plan",
    service_plans_delete_plan: "Service Plans: Delete Plan",
    service_plans_post: "Service Plans: Post",
    service_plans_get_plan_clients: "Service Plans: Get Plan Clients",
    integrator_get_dashboard_stats: "Integrator: Get Dashboard Stats",
    integrator_get_client_stats: "Integrator: Get Client Stats",
    integrator_get_product_stats: "Integrator: Get Product Stats",
    integrator_get_integrator_stats: "Integrator: Get Integrator Stats",
    integrator_get_opensearch_stats: "Integrator: Get OpenSearch Stats",
    users_post_login: "Users: Post Login",
    users_post_login_challenge: "Users: Post Login Challenge",
    users_post_login_refresh: "Users: Post Login Refresh",
    users_get: "Users: Get",
    users_get_user: "Users: Get User",
    users_put_user: "Users: Update User",
    users_put_user_suspend: "Users: Suspend User",
    users_put_user_enable: "Users: Enable User",
    users_post_new: "Users: Post New",
    users_post_new_bulk: "Users: Post New Bulk",
    users_get_available: "Users: Get Available",
    users_post_change_password: "Users: Post Change Password",
    users_post_forgot_password: "Users: Post Forgot Password",
    users_patch_forgot_password: "Users: Patch Forgot Password",
    users_delete: "Users: Delete",
    users_search: "Users: Search",
    vehicles_get: "Vehicles: Get",
    vehicles_get_vehicle_by_id: "Vehicles: Get Vehicle By ID",
    vehicles_get_vehicle: "Vehicles: Get Vehicle",
    vehicles_get_vehicle_weights: "Vehicles: Get Vehicle Weights",
    vehicles_get_faults: "Vehicles: Get Faults",
    vehicles_get_search: "Vehicles: Get Search",
    vehicle_get_vehicle_calibrations: "Vehicle: Get Vehicle Calibrations",
    vehicle_get_vehicle_calibrations_latest: "Vehicle: Get Latest Vehicle Calibrations",
    opensearch_get_health: "OpenSearch: Get Health",
    s3_bucket_get_buckets: "S3 Bucket: Get Buckets",
    s3_bucket_get_bucket_object_heads: "S3 Bucket: Get Bucket Object Heads",
    s3_object_get_object: "S3 Object: Get Object",
    s3_object_put_object: "S3 Object: Put Object",
    s3_object_delete_object: "S3 Object: Delete Object",
    s3_object_generate_presigned_url: "S3 Object: Generate Presigned URL",
    s3_tagging_get_object_tags: "S3 Tagging: Get Object Tags",
    s3_tagging_update_object_tags: "S3 Tagging: Update Object Tags",
    s3_tagging_delete_object_tags: "S3 Tagging: Delete Object Tags",
    ses_send_confirmation_email: "SES: Send Confirmation Email",
    ses_send_report_email: "SES: Send Report Email",
  };
  
  export default friendlyNameMap;  