import { fetchAuthSession, getCurrentUser  } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getActiveUserDetails = async () => {
	const url = buildBaseUrl()
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const authData = await getCurrentUser();
		const response = await fetch(
			`${url}users/${authData.username}`,
			{
				headers: {
					Authorization: `Bearer ${accessToken?.toString()}`,
				},
			}
		);
		const data = await response.json();
		return data;
	} catch (err) {
		console.log(err);
	}
};

//purposely gives error for testing
// export const getActiveUserDetails = async () => {
// 		const authData = "oooo";
// 		const response = await fetch(
// 			`http://localhost:8080/users/${authData}`,
// 			{
// 				headers: {
// 					Authorization: `Bearer ${authData}`,
// 				},
// 			}
// 		);
// 		const data = await response.json();
// 		console.log(data)
// 		return data;
// };
