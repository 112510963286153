import { buildBaseUrl } from "../build-base-url";

export const fetchClientLoginChallenge = async (
  username: string,
  newPassword: string,
  challenge: string,
  token: string,
) => {
  const url = buildBaseUrl();

  try {
    const response = await fetch(`${url}login/challenge`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password: newPassword,
        challenge,
        token,
      }),
    });
    return response.json();
  } catch (error) {
    console.error("Error submitting new password:", error);
    throw error;
  }
};
