//A successful response indicates a list of clients the user is authorized to see has been returned.

import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const getAvailableClients = async (clientName: string) => {
	const url = buildBaseUrl()
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		const response = await fetch(`${url}clients/available?client=${clientName}`, {
			headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
			},
		});
		const data  = await response.json(); //Extracting data array from response
		return data.client_name_available; // true or false based on availability
	} catch (err) {
		console.log(err);
	}
};