import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from '../build-base-url';
import { ServicePlansInterface } from "../../interfaces/service/service-list-interface";

export const putUpdatedServicePlans = async (editedServicePlan: ServicePlansInterface ) => {
    const url = buildBaseUrl()
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};

    const response = await fetch(`${url}service_plans/${editedServicePlan.id}`, {
      method: 'PUT',
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editedServicePlan),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    console.error('Error updating PUT service plan API:', err);
    return false;
  }
};
