import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { PostNewClientData } from "../../interfaces/clients/post-new-client-interface";

export const postNewClient = async (data: PostNewClientData) => {
	const url = buildBaseUrl()
	const { accessToken } = (await fetchAuthSession()).tokens ?? {};
	
	const response = await fetch(`${url}clients`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken?.toString()}`,
		},
		body: JSON.stringify(data),
	});

	return response;
};
