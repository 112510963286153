import { useContext, useEffect, useState } from "react"; import {
    Card,
    CardContent,
    CardHeader,
  } from "@mui/material";
  import GeneralTable from "../../../utils/generic-components/table/table";
  import {
    Columns,
  } from "../../../utils/interfaces/table/table-interfaces";
  import { AppContext } from "../../../utils/themes/theme-context";
  import { GenericGetLogsResponse } from "../../../utils/interfaces/action-logs/generic";
  import FilterBox from "../../../utils/generic-components/filter-opensearch/filter-box";
  import { LocalizationProvider } from "@mui/x-date-pickers";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import friendlyNameMap from "../../../utils/generic-components/filter-opensearch/action-logs-friendlyname-mapping";
  
  const columns: Columns = {
    date_time: {
      type: "time",
      display: "Date & Time",
      data: "",
      sort: "date_time",
    },
    type: {
      type: "text",
      display: "Type",
      sort: "type",
    },
    username: {
      type: "text",
      display: "Username",
      sort: "username",
    }
  }
  
  export default function UserLogsTab(props: { fetchLogs: Function }) {
    const { theme } = useContext(AppContext);
    const [logs, setLogs] = useState<GenericGetLogsResponse | null>();
    const [loading, setLoading] = useState<boolean>(true);
  
    const fetchUserLogs = async (types: string[] | undefined, start_date: string | undefined, end_date: string | undefined) => {
      setLoading(true);
      await props.fetchLogs(types, start_date, end_date).then((logs: GenericGetLogsResponse) => {
        if (!logs) {
          setLoading(false);
          setLogs(null);
          return;
        } else {
          setLogs(logs);
          setLoading(false);
        }
      }).catch((err: string[]) => {
        console.log(err);
      });
    }
  
    useEffect(() => {
      fetchUserLogs(undefined, undefined, undefined);
    }, []);
  
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FilterBox
          options={
            logs ? logs.types.map((type) => {
              return {
                value: type,
                label: friendlyNameMap[type] || type, // Use friendly name if available, fallback to original
              };
            }) : []
          }
          fetchLogs={fetchUserLogs}
        />
        <Card elevation={8} sx={{ borderRadius: 3 }}>
          <CardHeader title="Logs" />
          <CardContent>
            <GeneralTable
              data={
                logs ? logs.data.data.documents.map((log) => {
                  return {
                    date_time: log.date_time,
                    type: friendlyNameMap[log.type] || log.type, // Use friendly name if available, fallback to original
                    username: log.username,
                    data: log.data,
                  };
                }) : {}
              }
              columns={columns}
              noDataMessage="No logs found"
              loading={loading}
            />
          </CardContent>
        </Card>
      </LocalizationProvider>
    )
  
  }
  