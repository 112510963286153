import { Box, CardMedia, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function QrScanPage() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	return (
		<>
			{/* Logo Section */}
			<Grid container sx={{ width: "100%", maxWidth: "1200px", marginTop: 4, marginBottom: 4, marginLeft: 4 }}>
				<Grid item xs={12} md={2} sx={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
				}}>
					<CardMedia
						component="img"
						image="/images/airweighlogo.png"
						alt="Air-Weigh logo"
						sx={{ width: { xs: "120px", sm: "200px", md: "320px" } }}
					/>
				</Grid>
			</Grid>

			{/* Banner Section */}
			<Box
				sx={{
					width: "100%",
					mt: 0,
					backgroundImage: `url(/images/qrbackgroundimage.png)`,
					backgroundSize: "cover",
					backgroundPosition: "top",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: { xs: "250px", sm: "350px", md: "600px" },
					textAlign: "center",
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
					<Typography
						variant="h1"
						sx={{
							fontWeight: "900",
							color: "white",
							textAlign: "center",
							fontSize: { xs: "4rem", sm: "6rem", md: "12rem" },
						}}
					>
						<i>iWeigh</i>
					</Typography>
					<Typography
						variant="body2"
						sx={{
							color: "white",
							fontSize: { xs: "1.5rem", sm: "2rem", md: "3.5rem" }, // Adjusted size based on screen
							position: "absolute",
							top: { xs: "5px", sm: "5px", md: "5px" }, // Dynamic positioning
							right: { xs: "-25px", sm: "-40px", md: "-60px" },
						}}
					>
						®
					</Typography>
				</Box>
				<Typography variant="h6" sx={{ color: "white", fontSize: { xs: "1.5rem", md: "3rem" }, mt: 1 }}>
					<b>ON-BOARD SCALE SYSTEM</b>
				</Typography>
			</Box>

			{/* Content Section */}
			<Grid container sx={{ mt: 3, px: 2, paddingLeft: { xs: 2, sm: 3, md: 5, lg: 40 }, width: "100%" }}>

				{/* Layout for Mobile */}
				{isMobile && (
					<>
						<Grid item xs={12} sx={{ textAlign: "center" }}>
							<Typography
								variant="subtitle1"
								sx={{
									fontWeight: "bold",
									color: "#0D47A1",
									mb: 1,
									fontSize: { xs: "2rem", sm: "2rem", md: "3rem", lg: "3.5rem", xl: "4rem" },
									lineHeight: { xs: 1.5, sm: 1.7, md: 3 },
								}}
							>
								Get Started:
							</Typography>
							<Typography variant="body1" sx={{
								mb: 2,
								fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.5rem", lg: "2rem", xl: "2.5rem" },
								lineHeight: { xs: 1.5, md: 1.8 },
							}}>
								Thank you for choosing iWeigh®, the industry-leading integrated on-board scale system.
							</Typography>
							<Typography variant="body1" sx={{
								mb: 3,
								fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.5rem", lg: "2rem", xl: "2.5rem" },
								lineHeight: { xs: 1.5, md: 1.8 },
							}}>
								Download the app to register and set up your scale.
							</Typography>

							{/* Google Play Button */}
							<Box sx={{
								display: 'flex',
								justifyContent: 'center',
								width: { xs: '100%', sm: '250px', md: '350px' },
								mb: { xs: 2, sm: 0 }
							}}>
								<img
									width="100%"
									src="/images/google-play-badge.png"
									alt="Google Play badge"
									onClick={() => window.open("https://play.google.com/store/apps/", "_blank")}
									style={{ cursor: "pointer", maxWidth: '100%', height: 'auto' }}
								/>
							</Box>
						</Grid>

						{/* Mobile Image (No image on Mobile) */}
						{/* You can hide this section completely for mobile */}
						<Grid item xs={12} sx={{ display: "none" }}>
							{/* No content or image */}
						</Grid>
					</>
				)}

				{/* Layout for Tablet */}
				{isTablet && (
					<>
						{/* Content + Image in Row */}
						<Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
							{/* "Get Started" Section */}
							<Grid item xs={12} sm={6} sx={{
								textAlign: "left",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}>
								<Typography
									variant="subtitle1"
									sx={{
										fontWeight: "bold",
										color: "#0D47A1",
										mb: 1,
										fontSize: { xs: "2rem", sm: "2rem", md: "3rem", lg: "3.5rem", xl: "4rem" },
										lineHeight: { xs: 1.5, sm: 1.7, md: 3 },
									}}
								>
									Get Started:
								</Typography>
								<Typography variant="body1" sx={{
									mb: 2,
									fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.5rem", lg: "2rem", xl: "2.5rem" },
									lineHeight: { xs: 1.5, md: 1.8 },
								}}>
									Thank you for choosing iWeigh®, the industry-leading integrated on-board scale system.
								</Typography>
								<Typography variant="body1" sx={{
									mb: 3,
									fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.5rem", lg: "2rem", xl: "2.5rem" },
									lineHeight: { xs: 1.5, md: 1.8 },
								}}>
									Download the app to register and set up your scale.
								</Typography>
							</Grid>

							{/* "iWeigh Render" Image Section */}
							<Grid item xs={12} sm={6} sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
								<Box
									component="img"
									src="/images/iweigh-render2.png"
									alt="iWeigh device"
									sx={{
										width: "100%",
										maxWidth: { xs: 100, sm: 200, md: 300, lg: 400 },
										maxHeight: { xs: 200, sm: 300, md: 400, lg: 500 },
									}}
								/>
							</Grid>
						</Grid>

						{/* Google Play Button in Column */}
						<Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
							<Grid item xs={12} sx={{
								display: 'flex',
								justifyContent: 'center',
							}}>
								<img
									src="/images/google-play-badge.png"
									alt="Google Play badge"
									onClick={() => window.open("https://play.google.com/store/apps/", "_blank")}
									style={{ cursor: "pointer", width: '50%', height: 'auto' }}
								/>
							</Grid>
						</Grid>
					</>
				)}


				{/* Layout for Desktop (and Tablet if needed) */}
				{isDesktop && (
					<>
						<Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
							<Typography
								variant="subtitle1"
								sx={{
									fontWeight: "bold",
									color: "#0D47A1",
									mb: 1,
									fontSize: { xs: "2rem", sm: "2rem", md: "2.5rem", lg: "3rem", xl: "3rem" },
									lineHeight: { xs: 1.5, sm: 1.7, md: 3 },
								}}
							>
								Get Started:
							</Typography>
							<Typography variant="body1" sx={{
								mb: 2,
								fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.5rem", lg: "2rem", xl: "2.5rem" },
								lineHeight: { xs: 1.5, md: 1.8 },
							}}>
								Thank you for choosing iWeigh®, the industry-leading integrated on-board scale system.
							</Typography>
							<Typography variant="body1" sx={{
								mb: 3,
								fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.5rem", lg: "2rem", xl: "2.5rem" },
								lineHeight: { xs: 1.5, md: 1.8 },
							}}>
								Download the app to register and set up your scale.
							</Typography>

							<Box sx={{
								display: 'flex',
								justifyContent: 'left',
								width: { xs: '100%', sm: '250px', md: '350px' },
								mb: { xs: 2, sm: 0 }
							}}>
								<img
									width="100%"
									src="/images/google-play-badge.png"
									alt="Google Play badge"
									onClick={() => window.open("https://play.google.com/store/apps/", "_blank")}
									style={{ cursor: "pointer", maxWidth: '100%', height: 'auto' }}
								/>
							</Box>
						</Grid>

						<Grid item xs={12} md={6} sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}>
							<Box
								component="img"
								src="/images/iweigh-render2.png"
								alt="iWeigh device"
								sx={{
									width: "100%",
									maxWidth: { xs: 200, sm: 300, md: 400, lg: 500 },
									maxHeight: { xs: 300, sm: 400, md: 500, lg: 600 },
								}}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</>
	);
}