import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { FirmwareApiResponse, SingleProductFirmwareGET } from "../../interfaces/products/single-product-details-interface";

export async function fetchSingleProductFirmwareData(productId: string|undefined): Promise<FirmwareApiResponse> {
  const url = buildBaseUrl()
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}products/${productId}/firmware`, {
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
