import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { getAbout } from "../../utils/requests/about-page/about-page-request";

function AboutPage() {
	const [version, setVersion] = useState(null);

	useEffect(() => {
		async function fetchVersion() {
			try {
				const data = await getAbout(); // Call getAbout function to fetch version
				setVersion(data.version); // Set the version state
			} catch (error) {
				console.error("Error fetching version:", error);
			}
		}

		fetchVersion();
	}, []);

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			minHeight="100vh"
			marginX="auto"
		>
			<Box
				maxWidth="600px"
				paddingX={2}
				paddingBottom={10}
				textAlign="center"
			>
				<img
					src="/images/loadinglogo.png"
					alt="Logo"
					style={{ width: "150px", marginBottom: "1rem" }}
				/>
				<Typography variant="h6" gutterBottom>
					API Version: {version}
				</Typography>
				<Typography variant="h6" gutterBottom>
					Portal Version: 0.2.2
				</Typography>
			</Box>
		</Box>
	);
}

export default AboutPage;
