import ReactDOM from 'react-dom/client';
import LayersIcon from "@mui/icons-material/Layers";
import { RoundedIconButton } from '../../themes/rounded-icon-button';
import { getMapStyles } from "./geo-config"

class SwitchMapStyleControl implements maplibregl.IControl {
    private container: HTMLElement;
    private map: maplibregl.Map | null = null; // Store reference to the map
    private root: ReactDOM.Root | null = null;
    private mapStyleSatellite: boolean = false; // State to track current map style

    constructor() {
        this.container = document.createElement('div');
        this.container.className = 'maplibregl-ctrl';
    }

    onAdd(map: maplibregl.Map): HTMLElement {
        this.map = map; // Save the map reference
        this.root = ReactDOM.createRoot(this.container);

        // Render the button
        this.root.render(
            <RoundedIconButton
                onClick={this.switchMapStyle} // Attach switch style handler
                variant="contained"
                color="primary"
                // Override default Maplibre control button style
                className='custom-map-control-button'
            >
                <LayersIcon />
            </RoundedIconButton>
        );

        return this.container;
    }

    switchMapStyle = async () => {
        if (this.map) {
            const tempStyle = this.mapStyleSatellite ? getMapStyles().road : getMapStyles().satellite;
            this.map.setStyle(tempStyle);
            this.map.resize();
            this.map.redraw();
            this.mapStyleSatellite = !this.mapStyleSatellite; // Update state to track the current style
        }
    };

    onRemove() {
        if (this.root) {
            setTimeout(() => {
                this.root!.unmount();
                if (this.container.parentNode) {
                    this.container.parentNode.removeChild(this.container);
                }
            }, 0);
        } else {
            if (this.container.parentNode) {
                this.container.parentNode.removeChild(this.container);
            }
        }
    }
}

export default SwitchMapStyleControl;