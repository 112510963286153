import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../../utils/themes/theme-context";
import { TestLogData } from "../../../../utils/interfaces/devices/test-logs-interface";
import { putTestPassed } from "../../../../utils/requests/devices/put-test-passed-logs";

interface TestLogsActionsProps {
    testlogdata: TestLogData | null
    onTestPassed: (passed: boolean) => void; // Type for the callback
}

const TestLogsActions: React.FC<TestLogsActionsProps> = ({ testlogdata, onTestPassed }) => {
    const [openRawJsonDialog, setOpenRawJsonDialog] = useState(false);
    const [openMarkErrorResolvedDialog, setOpenMarkErrorResolvedDialog] = useState(false);
    const [testPassed, setTestPassed] = useState<boolean>(testlogdata?.test_result?.test_passed ?? false);
    const [isProcessing, setIsProcessing] = useState(false); // To show loading state for Step 2
    const [resolveStatus, setResolveStatus] = useState<string>(""); // To show success or failure message after resolution
    const [currentStep, setCurrentStep] = useState(1); // Manage dialog step (1: confirmation, 2: result)
    var { theme } = useContext(AppContext);
    // State for tag information
    const [tagDetails, setTagDetails] = useState({
        tagNumber: "",
        lotNumber: "",
        pcbManufacturerNumber: ""
    });

    const handleOpenRawJson = () => {
        setOpenRawJsonDialog(true);
    };

    const handleCloseRawJson = () => {
        setOpenRawJsonDialog(false);
    };

    const handleOpenMarkErrorResolved = () => {
        setOpenMarkErrorResolvedDialog(true);
    };

    const handleCloseMarkErrorResolved = () => {
        setOpenMarkErrorResolvedDialog(false);
        setCurrentStep(1); // Reset to step 1 when the dialog is closed
        setResolveStatus(""); // Clear any result message
    };


    const handleResolveError = async () => {
        setIsProcessing(true); // Start processing the update
        setResolveStatus(""); // Clear previous status message

        // Make PUT request to update test result
        const deviceId = testlogdata?.device_id;
        const logId = testlogdata?.id;

        if (!deviceId || !logId) {
            console.error("Error: device_id or logId is undefined");
            return;
        }

        const data = {
            test_passed: true, // Assuming the error is resolved
            updated_at: testlogdata?.updated_at || "",  // Use the updated_at prop passed from the parent
        };

        const response = await putTestPassed(deviceId, logId, data);

        if (response.ok) {
            setTestPassed(true);
            onTestPassed(true); // Pass the updated test result to the parent
            setResolveStatus("Resolution Successful!");
            setCurrentStep(2); // Move to step 2
        } else {
            setResolveStatus("Resolution Failed. Please try again.");
            setCurrentStep(2); // Move to step 2
        }

        setIsProcessing(false); // End processing
    };

    return (
        <>
            <Card elevation={8} sx={{ borderRadius: 3 }}>
                <CardHeader
                    title="Actions"
                    action={
                        <IconButton aria-label="info">
                            <InfoOutlinedIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Divider />
                    <Box paddingTop={2}>
                        <Stack direction={"column"} spacing={2}>
                            <Button variant="contained" fullWidth onClick={handleOpenRawJson}>Review RAW JSON</Button>
                            <Button variant="contained" fullWidth onClick={handleOpenMarkErrorResolved} disabled={testlogdata?.test_result?.test_passed}>Mark Error Resolved</Button>
                        </Stack>
                    </Box>
                </CardContent>
            </Card>
            {/* Dialog for Reviewing RAW JSON */}
            <Dialog open={openRawJsonDialog} onClose={handleCloseRawJson} PaperProps={{
                style: {
                    minWidth: '20%',
                    minHeight: '20%',
                },
            }}>
                <DialogTitle variant="h5" align="center"><b>Review RAW JSON</b><IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleCloseRawJson}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 20, top: 5 }}
                >
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box
                        sx={{
                            backgroundColor: 'lightgrey', // Optional background color
                            padding: 2,
                            borderRadius: 1,
                        }}
                    >
                        <Typography variant="body1" component="pre" sx={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            {JSON.stringify(testlogdata, null, 2)} {/* Displaying raw JSON properly */}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseRawJson} color={theme.backbuttoncolor}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Marking Error Resolved */}
            < Dialog open={openMarkErrorResolvedDialog} onClose={handleCloseMarkErrorResolved} PaperProps={{
                style: {
                    minWidth: '50%',
                    minHeight: '30%',
                },
            }
            }>
                <DialogTitle variant="h5" align="center"><b>Mark Error Resolved</b>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseMarkErrorResolved}
                        aria-label="close"
                        sx={{ position: 'absolute', right: 20, top: 5 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ padding: 2 }}>
                    {/* Step 1: Confirmation */}
                    {currentStep === 1 && (
                        <>
                            {/* Tag details */}
                            <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '1.1rem' }}> {/* Centered text with larger font */}
                                Tag Number: {tagDetails.tagNumber}
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '1.1rem' }}>
                                Lot Number: {tagDetails.lotNumber}
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '1.1rem' }}>
                                PCB Manufacturer's Production Number: {tagDetails.pcbManufacturerNumber}
                            </Typography>

                            {/* Question */}
                            <Typography variant="body1" sx={{ marginTop: 5, textAlign: 'center', fontSize: '1.2rem' }}>
                                <b>Have all errors been resolved?</b>
                            </Typography>
                            <DialogActions>
                                <Box display="flex" justifyContent="space-between" width="100%" paddingBottom={3} paddingLeft={2} paddingRight={2}>
                                    <Button onClick={handleCloseMarkErrorResolved} variant="contained" color={theme.backbuttoncolor}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleResolveError} variant="contained" color={theme.nextbuttoncolor}>
                                        Resolved
                                    </Button>
                                </Box>
                            </DialogActions>
                        </>
                    )}
                    {/* Step 2: Show Result (Success or Failure) */}
                    {currentStep === 2 && (
                        <>
                            <Typography
                                variant="body1"
                                sx={{
                                    textAlign: 'center',
                                    fontSize: '1.2rem',
                                    color: resolveStatus === "Resolution Successful!" ? 'green' : 'red',
                                }}
                            >
                                {resolveStatus}
                            </Typography>
                            <DialogActions>
                                <Button onClick={handleCloseMarkErrorResolved} variant="contained" color={theme.backbuttoncolor}>
                                    Close
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </DialogContent>
            </Dialog >
        </>
    );
}

export default TestLogsActions;