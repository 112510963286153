// SearchBar.js
import React, { useState, useEffect, useRef, useContext } from "react";
import { Autocomplete, Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { getClientSearch } from "../../../utils/requests/clients/get-clients-search";
import { getClientDetails } from "../../../utils/requests/clients/get-clients-list";
import { getVehicleSearch } from "../../../utils/requests/vehicles-routes/get-vehicle-search";
import { VehicleData } from "../../../utils/interfaces/vehicles/vehicles-interfaces";
import { AppContext } from "../../../utils/themes/theme-context";

interface SearchProps {
    onUpdateData: (updatedData: VehicleData[]) => void;
    onError: (errorMessage: string) => void;
    originalVehicles: VehicleData[];
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBar: React.FC<SearchProps> = ({ onUpdateData, onError, originalVehicles, setLoading  }) => {
    var { perms, theme } = useContext(AppContext);
    const [data, setData] = useState<VehicleData[]>([]);
    // const [loading, setLoading] = useState<boolean>(true);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [searchCriterion, setSearchCriterion] = useState("vehicle_name");
    const [searchTerm, setSearchTerm] = useState<string | null>("");
    const [resetVisible, setResetVisible] = useState(false); // Define resetVisible state
    const [Clientloading, setClientLoading] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [searchResponse, setSearchResponse] = useState<any[]>([]);
    const [selectSuggestions, setSelectSuggestions] = useState<boolean>(false);
    const [AllClientNames, setAllClientNames] = useState<string[]>([]);
    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

    const fetchSuggestions = async (searchCriterion: string, query: string) => {
        if (timeoutIdRef.current !== null) {
            clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = setTimeout(async () => {
            try {
                setClientLoading(true);

                // If there's a query, fetch suggestions based on the query
                const searchResponse = await getClientSearch(searchCriterion, query);
                setSearchResponse(searchResponse.data);
                const clientNames = searchResponse.data.map((client: { client: string }) => client.client);
                setSuggestions(clientNames);
            } catch (error) {
                console.error("Error fetching suggestions:", error);
            } finally {
                setClientLoading(false); // Set loading false when done
            }
        }, 1000);
    };

    useEffect(() => {
        if (searchCriterion === "client") {
            if (!searchTerm || searchTerm.trim() === "") {
                setClientLoading(true);
                getClientDetails()
                    .then((allClientsResponse) => {
                        const allClientNames = allClientsResponse.data.map((client: { client: string }) => client.client);
                        setAllClientNames(allClientNames);
                        setSuggestions(allClientNames);
                        setClientLoading(false);
                    })
                    .catch((error) => {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            if (error.response.status === 401) {
                                // 401 Unauthorized error
                                onError("Parameter mismatch");
                            } else if (error.response.status === 500) {
                                // 500 Internal Server Error
                                onError("Internal Server Error");
                            } else {
                                // other errors
                                onError(`Error: ${error.message}`);
                            }
                        } else if (error.request) {
                            // The request was made but no response was received
                            onError("No response received");
                        } else {
                            // Something happened in setting up the request that triggered an error
                            onError(`Error: ${error.message}`);
                        }
                    });
            }
        } else {
            setSuggestions([]); // Clear suggestions when criterion is not `client_id` or search term is not empty
        }
    }, [searchCriterion, searchTerm, data]);

    const handleSearchCriterionChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSearchCriterion(event.target.value);
        setSuggestions([]);
        setSearchTerm("");
        setResetVisible(false);
        setData(originalVehicles);
        onUpdateData(originalVehicles);
    };

    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = event.target.value.trim();
        // setSearchTerm(newSearchTerm);
        setResetVisible(newSearchTerm !== ""); // Show reset button if there's a search term

        if (searchCriterion === "client") {
            if (newSearchTerm === "") {
                // it means the user is actively typing 
                setSearchTerm("");
                setSelectSuggestions(false);
                setResetVisible(false);
            } else {
                // it indicates that the user has deleted the search term or hasn't typed anything yet
                // we don't want to immediately trigger a search request because the user may continue typing shortly. 
                // Instead, we want to wait for a brief pause in typing before initiating the search.
                setSuggestions([])
                setSearchTerm(newSearchTerm);
                setResetVisible(true);
                fetchSuggestions(searchCriterion, newSearchTerm);

                if (selectSuggestions && suggestions.includes(newSearchTerm)) {
                    setSelectSuggestions(true); // Set selectSuggestions to true if a suggestion is selected
                } else {
                    setSelectSuggestions(false); // Set selectSuggestions to false when search term changes
                }
            }
            // Update the Autocomplete value to reflect the current search term
            setSearchTerm(newSearchTerm);
        } else {
            setSearchTerm(newSearchTerm);

            if (newSearchTerm.trim() === "") {
                // Reset button is hidden when the search term is empty
                setResetVisible(false);
            } else {
                // Show the Reset button when there is something typed
                setResetVisible(true);
            }
        }

    };

    const handleReset = () => {
        // Clear the search term and restore the original table data
        setSearchTerm("");
        setData(originalVehicles); // Restore the original data
        onUpdateData(originalVehicles);
        setResetVisible(false); // Hide the Reset button
        if (searchCriterion === "client") {
            setSuggestions(AllClientNames);
        }
    };

    const handleSearchKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    const handleSearch = async () => {
        try {
            setSearchLoading(true);
            const searchResponse = await getVehicleSearch(searchCriterion, searchTerm);
            setData(searchResponse.data); // Update the state with the mapped search results
            onUpdateData(searchResponse.data);
        } catch (error) {
            console.error("Error while searching:", error);
            setSearchLoading(false);
            // Handle errors as needed
        } finally {
            setSearchLoading(false);
        }
    };

    // const handleSuggestionSelect = async (selectedItem: string | null) => {
    //     setSearchTerm(selectedItem);
    //     setSelectSuggestions(true);
    //     setResetVisible(true); // Show the reset button when a suggestion is selected

    //     if (selectedItem) {
    //         try {
    //             setLoading(true);
    //             if (searchCriterion === "client") {
    //                 console.log("searchResponse: ", searchResponse)
    //                 const selectedItemData = searchResponse.find(client => client.client === selectedItem);
    //                 console.log("selectedItemData: ", selectedItemData)
    //                 if (selectedItemData) {
    //                     const clientId = selectedItemData.id; // Extract the client ID
    //                     console.log("client id sent to the query of vehicle sewarch : ", clientId)
    //                     const searchResponse = await getVehicleSearch("client_id", clientId);
    //                     setData(searchResponse.data);
    //                     onUpdateData(searchResponse.data);
    //                 } else {
    //                     console.error("Selected client not found in search response.");
    //                 }
    //             }
    //             else {
    //                 console.error("Invalid search criterion:", searchCriterion);
    //             }

    //             setLoading(false);
    //         } catch (error) {
    //             console.error("Error fetching devices:", error);
    //             setData(originalVehicles);
    //             onUpdateData(originalVehicles);
    //             setLoading(false);
    //         }
    //     }
    // };

    const handleSuggestionSelect = async (selectedItem: string | null) => {
        setSearchTerm(selectedItem);
        setSelectSuggestions(true);
        setResetVisible(true);

        if (selectedItem) {
            try {
                setLoading(true);
                const searchResponse = await getClientSearch("client", selectedItem);

                if (searchResponse) {
                    const clientId = searchResponse.data[0].id; // Extract the client ID
                    console.log("")
                    const VsearchResponse = await getVehicleSearch("client_id", clientId);
                    setData(VsearchResponse.data);
                    onUpdateData(VsearchResponse.data);
                } else {
                    console.error("Selected client not found in search response.");
                }
            } catch (error) {
                setData(originalVehicles);
                onUpdateData(originalVehicles);
            } finally {
                setLoading(false);
            }
        }
    };

    return (

        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={6}>
                <Box paddingTop={2} display="flex">
                    <FormControl style={{ minWidth: "200px" }} variant="outlined">
                        <Select
                            value={searchCriterion}
                            onChange={handleSearchCriterionChange}
                        >
                            <MenuItem value="vehicle_name">Vehicle Name</MenuItem>
                            <MenuItem value="vehicle_vin">Vehicle Vin</MenuItem>
                            <MenuItem value="client" disabled={!perms.aw_permissions.clients_read}>Client</MenuItem>
                        </Select>
                    </FormControl>

                    {searchCriterion == "client" && (
                        <Autocomplete
                            // fullWidth
                            style={{ width: "100%" }}
                            options={suggestions}
                            loading={Clientloading}
                            value={searchTerm}
                            onChange={(event, value) => handleSuggestionSelect(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search"
                                    variant="outlined"
                                    // fullWidth
                                    onChange={handleSearchTermChange}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {Clientloading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : (
                                                    resetVisible && (
                                                        <IconButton onClick={handleReset} size="small">
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    )}
                    {searchCriterion !== "client" && (
                        <><Box>
                            <TextField
                                label="Search"
                                variant="outlined"
                                fullWidth
                                style={{ width: "100%", maxWidth: "200px" }}
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                                onKeyDown={handleSearchKeyDown}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchLoading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : (
                                                resetVisible && (
                                                    <IconButton onClick={handleReset} size="small">
                                                        <ClearIcon />
                                                    </IconButton>
                                                )
                                            )}
                                        </InputAdornment>
                                    ),
                                }} />
                        </Box><Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{ width: "30%" }}
                            onClick={handleSearch}
                        >
                                Search
                            </Button></>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default SearchBar;
