import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";

export const deleteClient = async (clientId: string | undefined) => {
    const url = buildBaseUrl()
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}clients/${clientId}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${accessToken?.toString()}`,
        },
    }
    );
    return response;
};