import { fetchAuthSession } from 'aws-amplify/auth';
import { buildBaseUrl } from "../build-base-url";
import { ServicePlansInterface } from "../../interfaces/service/service-list-interface";

export async function fetchSingleServicePlan(id: string | undefined): Promise<ServicePlansInterface> {
  const url = buildBaseUrl()
  try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    const response = await fetch(`${url}service_plans/${id}`, {
      headers: {
				Authorization: `Bearer ${accessToken?.toString()}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
